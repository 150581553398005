import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProjectMilestoneAssignment, Survey } from "@inthraction/data-models";
import { ProjectService, SurveyService } from "@inthraction/services";
import { MultiSeries, Series } from "@swimlane/ngx-charts";
import * as moment from "moment";
import * as shape from "d3-shape";

@Component({
  selector: "inthraction-milestone-score-chart",
  templateUrl: "./milestone-score-chart.component.html",
  styleUrls: ["./milestone-score-chart.component.scss"]
})
export class MilestoneScoreChartComponent implements OnInit {

  @Input() milestone: ProjectMilestoneAssignment;
  score: number;

  @Output() selectEvent = new EventEmitter<MilestoneScoreChartSelection>();

  chartData: MultiSeries = [];

  selectedColorScheme: string;
  view: any[];
  colorScheme: any;
  // colorSets: any;
  schemeType = "ordinal";
  // range: boolean = true;
  animations = true;
  showLegend = false;
  legendTitle = "Legend";
  legendPosition = "right";
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = false;
  showYAxisLabel = false;
  xAxisLabel = "Country";
  yAxisLabel = "GDP Per Capita";
  autoScale = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  timeline = true;
  showGridLines = true;
  curves = {
    "Basis": shape.curveBasis,
    "Basis Closed": shape.curveBasisClosed,
    "Bundle": shape.curveBundle.beta(1),
    "Cardinal": shape.curveCardinal,
    "Cardinal Closed": shape.curveCardinalClosed,
    "Catmull Rom": shape.curveCatmullRom,
    "Catmull Rom Closed": shape.curveCatmullRomClosed,
    "Linear": shape.curveLinear,
    "Linear Closed": shape.curveLinearClosed,
    "Monotone X": shape.curveMonotoneX,
    "Monotone Y": shape.curveMonotoneY,
    "Natural": shape.curveNatural,
    "Step": shape.curveStep,
    "Step After": shape.curveStepAfter,
    "Step Before": shape.curveStepBefore,
    "default": shape.curveLinear
  };
  curveType = "Linear";
  curve: any = this.curves[this.curveType];
  rangeFillOpacity = 0.15;
  roundDomains = false;
  tooltipDisabled = false;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;

  width = 700;
  height = 250;
  fitContainer = true;

  constructor(
    private projectService: ProjectService,
    private surveyService: SurveyService
  ) {
    this.setColorScheme("fire");
  }

  async ngOnInit() {
    const surveys = await this.surveyService.getSurveysByObjectIDMemoize(this.milestone.id);
    const processedSurveys = this.processSurveys(surveys);
    this.chartData = await this.generateChartData(processedSurveys);
    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }

  applyDimensions(): void {
    this.view = [this.width, this.height];
  }

  setColorScheme(name: string): void {
    this.selectedColorScheme = name;
    this.colorScheme = {
      name: "fire",
      selectable: true,
      group: "Ordinal",
      domain: [
        "#ff3d00", "#bf360c", "#ff8f00", "#ff6f00", "#ff5722", "#e65100", "#ffca28", "#ffab00"
      ]
    };
  }

  private processSurveys(surveys: Survey[]): Map<string, number[]> {
    const employeeSurveyScoreMap = new Map<string, number[]>();
    for (const survey of surveys) {
      const surveyDate = moment(survey.surveyDate).format("YYYY-MM-DD");
      if (survey.responseReceived) {
        if (!employeeSurveyScoreMap.has(surveyDate)) {
          employeeSurveyScoreMap.set(surveyDate, []);
        }
        employeeSurveyScoreMap.get(surveyDate).push(survey.surveyResponse);
      }
    }
    return employeeSurveyScoreMap;
  }

  private async generateChartData(processedSurveys: Map<string, number[]>): Promise<MultiSeries> {
    const results: MultiSeries = [];
    if (processedSurveys) {
      const series: Series = {
        name: `Milestone Survey`,
        series: []
      };
      const surveys: { date: string, total: number }[] = [];
      for (const entry of processedSurveys.entries()) {
        let sum = 0;
        for (const val of entry[1]) {
          sum = sum + SurveyService.getFivePointResponseScore(val);
        }
        surveys.push({ date: entry[0], total: SurveyService.round((sum / entry[1].length), 2) });
      }
      surveys.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));

      let totalScore = 0;
      let first = true;
      for (const surveyData of surveys) {

        if (first) {
          totalScore = surveyData.total;
          first = false;
        } else {
          totalScore = SurveyService.round((totalScore + surveyData.total) / 2, 2);
        }

        series.series.push({
          value: totalScore,
          name: moment(surveyData.date).toDate(),
          extra: {
            surveyDate: surveyData.date,
            milestoneID: this.milestone.id
          } as MilestoneScoreChartSelection
        });
      }
      if (series.series.length > 0) {
        results.push(series);
      }
      this.score = totalScore;
    }
    return results;
  }

  select(data: any): void {
    if (data.extra) {
      this.selectEvent.emit(data.extra);
    }
  }

  // TODO
  activate(data: any): void {
    // console.debug('Activate', JSON.parse(JSON.stringify(data)));
  }

  // TODO
  deactivate(data: any): void {
    // console.debug('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}

export interface MilestoneScoreChartSelection {
  milestoneID: string;
  surveyDate: string;
}
