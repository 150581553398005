import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  CadenceObjectiveAssignment,
  CalEvent,
  Employee,
  EmployeeImpl,
  EventSurvey,
  IEmployeeImpl,
  Note,
  ObjectiveAssignment,
  ObjectiveSurvey,
  OpenIntHRaction,
  OrganizationShoutOutType,
  QuantifiableObjectiveAssignment,
  StandupSurvey,
  StandupSurveyConfiguration,
  Survey
} from "@inthraction/data-models";
import {
  CalendarEventService,
  EmployeeService,
  InspHRationCounterType,
  NoteService,
  ObjectiveService,
  OrganizationService,
  ProjectService,
  ShoutOutService,
  StandupService,
  SurveyService,
  SurveyType
} from "@inthraction/services";
import {
  AddEditEmployeeObjectiveComponent
} from "../../../components/employee-settings/add-edit-employee-objective.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { InthractionComponent } from "../../../components/shared/inthraction/inthraction.component";
import {
  EventSurveyResponseOptions,
  NoteTypes,
  ObjectiveTypeCodes,
  OrganizationConfigurationCodes,
  ReviewTypeKeys,
  SurveyResponseFilterDirection,
  SurveyTypeCodes
} from "@inthraction/codes";
import {
  StandardDialogComponentComponent
} from "../../../components/shared/dialogs/standard-dialog-component/standard-dialog-component.component";
import * as moment from "moment";
import { ObjectiveResponseComponent } from "../../../components/shared/objective-response/objectiveResponse.component";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  InsphrationResultDialogComponent,
  InspHRationResultsData
} from "../../../components/shared/dialogs/insphration-result-dialog/insphration-result-dialog.component";
import {
  InsphrationResultListDialogComponent,
  InspHRationResultsListData
} from "../../../components/shared/dialogs/insphration-result-list-dialog/insphration-result-list-dialog.component";
import {
  ADD_EMPLOYEE_OBJECTIVES_HELP,
  ANNUAL_REVIEW_HELP,
  BIO_ASPIRATIONS_GROWTH_HELP,
  COMMUNICATION_HELP,
  CONSULTANT_COMMUNICATION_HELP,
  HR_COMMUNICATION_HELP,
  INSPHRATION_ICONS_HELP,
  INSPHRATIONS_GIVEN_HELP,
  INSPHRATIONS_RECEIVED_HELP,
  INTHRACTION_TREND_HELP,
  MANAGER_COMMUNICATION_HELP,
  MISSED_MEETINGS_HELP,
  OBJECTIVE_PANEL_HELP,
  OBJECTIVE_RESPONSE_DISTRIBUTION_HELP,
  ONE_ON_ONE_HELP,
  OPEN_INSPHRATIONS_HELP,
  OPEN_INTHRACTIONS_HELP,
  OPEN_MANAGER_COMMUNICATION_HELP,
  QUARTERLY_REVIEW_HELP,
  RECENT_INTHRACTIONS_HELP
} from "@inthraction/labels";
import { QuestionsMap } from "@inthraction/data-mappers";
import {
  EmployeeStandupHistoryComponent
} from "../../../components/employee-standup-history/employee-standup-history.component";

@Component({
  selector: "inthraction-employee-dashboard",
  templateUrl: "./employee-dashboard.component.html",
  styleUrls: ["./employee-dashboard.component.scss"]
})
export class EmployeeDashboardComponent implements OnInit, OnDestroy {

  readonly INSPHRATION_ICONS_HELP = INSPHRATION_ICONS_HELP;
  readonly OPEN_INTHRACTIONS_HELP = OPEN_INTHRACTIONS_HELP;
  readonly RECENT_INTHRACTIONS_HELP = RECENT_INTHRACTIONS_HELP;
  readonly MISSED_MEETINGS_HELP = MISSED_MEETINGS_HELP;
  readonly OPEN_INSPHRATIONS_HELP = OPEN_INSPHRATIONS_HELP;
  readonly INSPHRATIONS_GIVEN_HELP = INSPHRATIONS_GIVEN_HELP;
  readonly INSPHRATIONS_RECEIVED_HELP = INSPHRATIONS_RECEIVED_HELP;
  readonly INTHRACTION_TREND_HELP = INTHRACTION_TREND_HELP;
  readonly OBJECTIVE_PANEL_HELP = OBJECTIVE_PANEL_HELP;
  readonly BIO_ASPIRATIONS_GROWTH_HELP = BIO_ASPIRATIONS_GROWTH_HELP;
  readonly COMMUNICATION_HELP = COMMUNICATION_HELP;
  readonly MANAGER_COMMUNICATION_HELP = MANAGER_COMMUNICATION_HELP;
  readonly HR_COMMUNICATION_HELP = HR_COMMUNICATION_HELP;
  readonly CONSULTANT_COMMUNICATION_HELP = CONSULTANT_COMMUNICATION_HELP;
  readonly ONE_ON_ONE_HELP = ONE_ON_ONE_HELP;
  readonly QUARTERLY_REVIEW_HELP = QUARTERLY_REVIEW_HELP;
  readonly ANNUAL_REVIEW_HELP = ANNUAL_REVIEW_HELP;
  readonly OPEN_MANAGER_COMMUNICATION_HELP = OPEN_MANAGER_COMMUNICATION_HELP;
  readonly ADD_EMPLOYEE_OBJECTIVES_HELP = ADD_EMPLOYEE_OBJECTIVES_HELP;
  readonly OBJECTIVE_RESPONSE_DISTRIBUTION_HELP = OBJECTIVE_RESPONSE_DISTRIBUTION_HELP;

  @Input() employee: Employee;
  @Input() manager: Employee;
  @Output() showObjectiveResponseDistributions = new EventEmitter<Employee>();
  surveyTypes = SurveyTypeCodes;
  managerWithHierarchy: IEmployeeImpl = null;
  employeeObjectives: (ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment)[] = [];
  daysSinceLastOneOnOne: number;
  daysInPosition: number;
  managerAccess = false;
  openInteractions: OpenIntHRaction[] = [];
  openInteractionsView: OpenIntHRaction[];
  openInteractionsMultiplier = 0;
  interactionEvents: Map<string, RecentSurvey> = new Map<string, RecentSurvey>();
  ytdInteractionScore = 0;
  interactionGraphEmployeesArray: string[] = [];
  clickCount = 1;
  missedEventIDs: string[] = [];
  missedEventIDsView: string[];
  missedEventsMultiplier = 0;
  recentIntHRactionObjects: string[] = [];
  recentIntHRactionEventIDsView: string[] = [];
  showIntHRactionTrendChart = false;
  eventsWithComments = new Set<string>();
  recentViewMultiplier = 0;
  standupNote: Note;
  oneOnOneNotes: Note[];
  hrOneOnOneNotes: Note[];
  consultantOneOnOneNotes: Note[];
  organizationInspHRations: OrganizationShoutOutType[];
  organizationInspHRationsMap: Map<string, OrganizationShoutOutType> = new Map<string, OrganizationShoutOutType>();
  inspHRationCountMap: Map<string, number>;
  openInspHRations: Survey[];
  recentIntHRactionsCount = 0;
  missedEventsCount = 0;
  givenInspHRations: Survey[];
  receivedInspHRations: Survey[];
  givenInspHRationsMultiplier: number;
  givenInspHRationsView: InspHRationSurvey[];
  receivedInspHRationsMultiplier: number;
  receivedInspHRationsView: InspHRationSurvey[];
  managerObjectivesEnabled = false;
  latestStandup: StandupSurvey;
  standupConfiguration: StandupSurveyConfiguration;
  sharedStandupManagers: Employee[] = [];

  private infoDialogRef: MatDialogRef<StandardDialogComponentComponent>;
  private openSurveys: Survey[];
  private _recentObjectiveSurveyResponseIDs: string[];
  private recentObjectiveSurveyList: Survey[];
  private _recentEventIDs: string[];
  private _missedEventIDs: string[];
  private subscriptions: Subscription[] = [];


  constructor(
    private surveyService: SurveyService,
    private standupService: StandupService,
    private employeeService: EmployeeService,
    private calendarEventService: CalendarEventService,
    private organizationService: OrganizationService,
    private objectiveService: ObjectiveService,
    private projectService: ProjectService,
    private noteService: NoteService,
    private shoutOutService: ShoutOutService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  get openInteractionsCount(): number {
    return this.openSurveys ? this.openSurveys.length : 0;
  }

  private static orderEvents(ids: string[], eventsMap: Map<string, RecentSurvey>): string[] {
    const eventByTimestampMap = new Map<string, string[]>();
    for (const id of ids) {
      if (eventsMap.get(id)) {
        if (!eventByTimestampMap.has(eventsMap.get(id).start)) {
          eventByTimestampMap.set(eventsMap.get(id).start, []);
        }
        eventByTimestampMap.get(eventsMap.get(id).start).push(id);
      }
    }
    let sortedKeys = [];
    for (const key of eventByTimestampMap.keys()) {
      sortedKeys.push(key);
    }
    sortedKeys = sortedKeys.sort().reverse();
    const eventsSorted = [];
    for (const key of sortedKeys) {
      for (const id of eventByTimestampMap.get(key)) {
        eventsSorted.push(id);
      }
    }
    return eventsSorted;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit(): Promise<void> {
    const securityUser = await this.employeeService.getCurrentEmployee();
    this.managerAccess = this.employee.email !== securityUser.email;
    if (this.managerAccess) {
      const configuration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.MANAGER_OBJECTIVES_DISABLED, this.employee.orgId);
      this.managerObjectivesEnabled = !(configuration && configuration.configBooleanValue);
    }

    this.findOpenSurveys();
    this.findInspHRationCounts();
    this.findOpenInspHRations();
    this.findRecentInspHRations();
    this.findMissedMeetings();
    this.findRecentIntHRactions();
    if (!this.managerAccess) {
      this.findLatestStandup();
      this.findSharedStandups();
    }

    this.interactionGraphEmployeesArray.push(this.employee.id);
    this.showIntHRactionTrendChart = true;

    let managerWithHierarchy;
    if (this.manager) {
      managerWithHierarchy = await this.employeeService.buildEmployeeHierarchy(this.manager, null, this.managerAccess ? 2 : 1);
    } else { // Not every employee will have a manger ie. the president of a company, use the employee instead.
      managerWithHierarchy = await this.employeeService.buildEmployeeHierarchy(this.employee, null, 1);
      this.manager = this.employee;
    }
    this.managerWithHierarchy = managerWithHierarchy;

    const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION, this.employee.orgId);
    if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
      this.ytdInteractionScore = await this.employeeService.getEmployeeMTDTotalScoreByEmployeeIDMemoize(this.employee.id);
    } else {
      this.ytdInteractionScore = await this.employeeService.getEmployeeYTDTotalScoreByEmployeeIDMemoize(this.employee.id);
    }

    const objectives = await this.objectiveService.getEmployeeObjectiveAssignmentsMemoize(this.employee.id);
    if (objectives && objectives.length > 0) {
      this.employeeObjectives = objectives.sort((a,b) => {
         if (a.endDate == null && b.endDate == null)
           return 0;
         if (a.endDate == null)
            return -1;
         if (b.endDate == null)
           return 1;

         if (isCurrent(a.endDate) && isCurrent(b.endDate)) {
           return a.endDate.localeCompare(b.endDate);
         } else if (isCurrent(a.endDate)) {
           return -1;
         } else {
           return b.endDate.localeCompare(a.endDate);
         }

         function isCurrent(date: string) {
           return date >= moment().format('YYYY-MM-DD');
         }
      });
    }

    if (this.employee.lastOneOnOne) {
      this.daysSinceLastOneOnOne = EmployeeImpl.getDaysIn(this.employee.lastOneOnOne);
    }

    if (this.employee.positionDate) {
      this.daysInPosition = EmployeeImpl.getDaysIn(this.employee.positionDate);
    }

    this.organizationInspHRations = await this.shoutOutService.getActiveOrganizationShoutOuts();
    for (const inspHRation of this.organizationInspHRations) {
      this.organizationInspHRationsMap.set(inspHRation.id, inspHRation);
    }

    this.oneOnOneNotes = await this.findOneOnOneNotes(NoteTypes.ONE_ON_ONE);
    if (!this.managerAccess) {
      this.hrOneOnOneNotes = await this.findOneOnOneNotes(NoteTypes.HR_ONE_ON_ONE);
      this.consultantOneOnOneNotes = await this.findOneOnOneNotes(NoteTypes.CONSULTANT_ONE_ON_ONE);
    }
  }

  openInfoDialog(title: string, message: string): void {
    if (!this.infoDialogRef) {
      this.infoDialogRef = this.dialog.open(StandardDialogComponentComponent, {
        panelClass: "topRightCloseButton",
        width: "450px",
        autoFocus: false,
        data: { message, title }
      });
    }
    this.subscriptions.push(
      this.infoDialogRef.afterClosed().subscribe(() => this.infoDialogRef = null));
  }

  graphTeam(): void {
    this.interactionGraphEmployeesArray.length = 0; // Empty the array
    if (this.managerWithHierarchy && this.managerWithHierarchy.subordinateEmployees && this.managerWithHierarchy.subordinateEmployees.length > 0) {
      for (const emp of this.managerWithHierarchy.subordinateEmployees) {
        this.interactionGraphEmployeesArray.push(emp.id);
      }
    }
    this.clickCount++;
  }

  showEmployeeChart(employeeToShow: Employee): void {
    this.interactionGraphEmployeesArray.length = 0; // Empty the array
    this.interactionGraphEmployeesArray.push(employeeToShow.id);
    this.clickCount++;
  }

  openAddObjective(): void {
    if (this.managerAccess && this.managerObjectivesEnabled) {
      const addDialog = this.dialog.open(AddEditEmployeeObjectiveComponent, {
        width: "600px",
        data: { employee: this.employee, manager: this.manager, objectives: this.employeeObjectives, mode: "add" }
      });

      this.subscriptions.push(
        addDialog.afterClosed().subscribe(result => {
          if (result && result.objective) {
            this.employeeObjectives.push(result.objective);
            this.employeeService.clearMemoizedEmployee(this.employee);
          }
        }));
    }
  }

  openEditObjective(editId: string): void {
    const addDialog = this.dialog.open(AddEditEmployeeObjectiveComponent, {
      width: "600px",
      data: {
        employee: this.employee,
        manager: this.manager,
        objectives: this.employeeObjectives.filter(ug => ug.id === editId),
        mode: "edit"
      }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(result => {
        if (result && result.objective) {
          this.employeeObjectives = this.employeeObjectives.filter(ug => ug.id !== result.objective.id);
          // Populate new version if not deleted
          if (!result.deleted) {
            this.employeeObjectives.push(result.objective);
          }
          this.employeeService.clearMemoizedEmployee(this.employee);
        }
      }));
  }

  async findOpenSurveys(): Promise<void> {
    const surveys = [];
    const standupSurvey = await this.standupService.getPendingStandupSurveyByEmployee(this.employee.id);
    if (standupSurvey) {
      surveys.push(standupSurvey);
    }
    surveys.push(...await this.surveyService.getPendingResponseSurveysByRespondentEmailMemoize(this.employee.email));
    this.openSurveys = surveys;
  }

  surveyRouterLink(surveyType: SurveyTypeCodes): string {
    let routerLink;
    switch (surveyType) {
      case SurveyTypeCodes.STANDUP: {
        routerLink = "stand-up-survey";
        break;
      }
      case SurveyTypeCodes.EVENT: {
        routerLink = "event-survey";
        break;
      }
      case SurveyTypeCodes.OBJECTIVE: {
        routerLink = "objective-survey";
        break;
      }
      case SurveyTypeCodes.PROJECT: {
        routerLink = "project-survey";
        break;
      }
      case SurveyTypeCodes.MILESTONE: {
        routerLink = "milestone-survey";
        break;
      }
      case SurveyTypeCodes.NEW_HIRE_14_DAY:
      case SurveyTypeCodes.NEW_POSITION_60_DAY:
      case SurveyTypeCodes.NEW_MANAGER_30_DAY:
      case SurveyTypeCodes.NEW_HIRE_90_DAY:
      case SurveyTypeCodes.NEW_MANAGER_90_DAY: {
        routerLink = "experience-survey";
        break;
      }

      default:
        routerLink = "error";
    }
    return routerLink;
  }

  async expandOpenIntHRactions() {
    const intHRactions: OpenIntHRaction[] = [];
    const objectIDs: string[] = [];
    for (const survey of this.openSurveys) {
      if ((survey.surveyType === SurveyTypeCodes.EVENT || survey.surveyType === SurveyTypeCodes.PROJECT) && objectIDs.includes(survey.objectID)) {
        continue;
      } else if (survey.surveyType === SurveyTypeCodes.EVENT || survey.surveyType === SurveyTypeCodes.PROJECT) {
        objectIDs.push(survey.objectID);
      }

      switch (survey.surveyType) {

        case SurveyTypeCodes.STANDUP: {
          intHRactions.push(new OpenIntHRaction("Stand-Up Survey", survey.surveyDate, SurveyTypeCodes.STANDUP, [survey.id]));
          break;
        }
        case SurveyTypeCodes.EVENT: {
          const calEvent = await this.calendarEventService.getCalendarEventByIDMemoize(survey.objectID);
          intHRactions.push(new OpenIntHRaction(calEvent.summary, calEvent.start, SurveyTypeCodes.EVENT, [survey.id]));
          break;
        }
        case SurveyTypeCodes.OBJECTIVE: {
          const participant = await this.employeeService.getEmployeeByEmailMemoize(survey.participantEmail);
          intHRactions.push(new OpenIntHRaction(`Objective Survey - ${participant.firstName} ${participant.lastName}`, survey.surveyDate, SurveyTypeCodes.OBJECTIVE, [survey.id]));
          break;
        }
        case SurveyTypeCodes.PROJECT: {
          const project = await this.projectService.getProjectByIDMemoized(survey.objectID);
          intHRactions.push(new OpenIntHRaction(`Project Survey - ${project.title}`, survey.surveyDate, SurveyTypeCodes.PROJECT, [survey.id]));
          break;
        }
        case SurveyTypeCodes.MILESTONE: {
          const milestone = await this.projectService.getProjectMilestoneAssignmentByIDMemoized(survey.objectID);
          intHRactions.push(new OpenIntHRaction(`Milestone Survey - ${milestone.title}`, survey.surveyDate, SurveyTypeCodes.MILESTONE, [survey.id]));
          break;
        }

        case SurveyTypeCodes.NEW_HIRE_14_DAY: {
          intHRactions.push(new OpenIntHRaction(`Experience Survey - New Hire 14 Day`, survey.surveyDate, SurveyTypeCodes.NEW_HIRE_14_DAY, [survey.id]));
          break;
        }
        case SurveyTypeCodes.NEW_POSITION_60_DAY: {
          intHRactions.push(new OpenIntHRaction(`Experience Survey - New Position 60 Day`, survey.surveyDate, SurveyTypeCodes.NEW_POSITION_60_DAY, [survey.id]));
          break;
        }
        case SurveyTypeCodes.NEW_MANAGER_30_DAY: {
          intHRactions.push(new OpenIntHRaction(`Experience Survey - New Manager 30 Day`, survey.surveyDate, SurveyTypeCodes.NEW_MANAGER_30_DAY, [survey.id]));
          break;
        }
        case SurveyTypeCodes.NEW_HIRE_90_DAY: {
          intHRactions.push(new OpenIntHRaction(`Experience Survey - New Hire 90 Day`, survey.surveyDate, SurveyTypeCodes.NEW_HIRE_90_DAY, [survey.id]));
          break;
        }
        case SurveyTypeCodes.NEW_MANAGER_90_DAY: {
          intHRactions.push(new OpenIntHRaction(`Experience Survey - New Manager 90 Day`, survey.surveyDate, SurveyTypeCodes.NEW_MANAGER_90_DAY, [survey.id]));
          break;
        }


        default: {
          console.error("Not configured for Survey Type", survey.surveyType);
        }
      }
    }

    // Sort OpenIntHRactions
    this.openInteractions = intHRactions.sort((a, b) => {
      //Make Stand-Up Always First
      if (a.surveyType == SurveyTypeCodes.STANDUP) {
        return -1;
      } else if (b.surveyType == SurveyTypeCodes.STANDUP) {
        return 1;
      }
      return a.intHRactionDate.localeCompare(b.intHRactionDate);
    });
    this.pageOpenIntHrActions(0);
  }

  async openRecentIntHRaction(eventID: string, employeeID?: string): Promise<void> {
    this.dialog.open(InthractionComponent, {
      width: "600px",
      data: {
        event: eventID,
        employee: employeeID ? await this.employeeService.getEmployeeByIDMemoize(employeeID) : this.employee
      }
    });
  }

  async openObjectiveSurveyResponse(objectiveResponseID: any, employeeID?: any) {
    this.dialog.open(ObjectiveResponseComponent, {
      width: "600px",
      data: {
        objectiveResponseID,
        employee: employeeID ? await this.employeeService.getEmployeeByIDMemoize(employeeID) : this.employee
      }
    });

  }

  async findMissedMeetings(): Promise<void> {
    const eventSurveyList = await this.surveyService.getEventSurveysByParticipantMemoize(this.employee.email, SurveyResponseFilterDirection.le, EventSurveyResponseOptions.DID_NOT_ATTEND, moment().subtract(60, "days").startOf("day").toISOString());
    this._missedEventIDs = this.findEventIDs(eventSurveyList);
    this.missedEventsCount = this._missedEventIDs.length;
  }

  async expandMissedMeetings() {
    await this.buildEventObjects(this._missedEventIDs);
    this.missedEventIDs = EmployeeDashboardComponent.orderEvents(this._missedEventIDs, this.interactionEvents);
    this.pageMissedEvents(0);
  }

  async findLatestStandup(): Promise<void> {
    const latestStandup = await this.standupService.getLatestCompletedStandupSurveyByEmployee(this.employee.id);
    if (latestStandup) {
      this.standupConfiguration = await this.standupService.getStandupConfigurationByID(latestStandup.standupSurveyConfigurationID);
      this.latestStandup = latestStandup;

      const notes = await this.findStandUpNotes(this.latestStandup.id);
      if (notes?.length) {
        this.standupNote = notes[0];
      } else {
        this.standupNote = null;
      }
    }
  }

  async findSharedStandups(): Promise<void> {

    const standupConfigrations = await this.standupService.getStandupConfigurationBySharedWithEmployee(this.employee.orgId);
    for(let configuration of standupConfigrations) {
      const manager = await this.employeeService.getEmployeeByIDMemoize(configuration.employeeID);
      this.sharedStandupManagers.push(manager);
    }
  }

  async findRecentIntHRactions(): Promise<void> {
    const startMoment = moment().subtract(60, "days").startOf("day").toISOString();
    const recentSurveys = await this.surveyService.getRecentSurveysByParticipantMemoize(this.employee.email, startMoment);
    const eventSurveyList = recentSurveys.filter(s => s.surveyType === SurveyType.EVENT);
    this.recentObjectiveSurveyList = recentSurveys.filter(s => s.surveyType === SurveyType.OBJECTIVE);
    this._recentEventIDs = this.findEventIDs(eventSurveyList);
    this._recentObjectiveSurveyResponseIDs = this.findObjectiveSurveyResponseIDs(this.recentObjectiveSurveyList);
    this.recentIntHRactionsCount = this._recentEventIDs.length + this._recentObjectiveSurveyResponseIDs.length;
  }

  private async findRecentInspHRations() {
    const inspHRations = await this.surveyService.getGivenOrReceivedInspHRactions(this.employee.email);
    this.givenInspHRations = inspHRations.filter(i => i.respondentEmail === this.employee.email);
    this.receivedInspHRations = inspHRations.filter(i => i.participantEmail === this.employee.email);
  }

  async expandRecentIntHRactions() {
    await this.buildEventObjects(this._recentEventIDs);
    await this.buildObjectiveObjects(this.recentObjectiveSurveyList);
    this.recentIntHRactionObjects = EmployeeDashboardComponent.orderEvents(this._recentEventIDs.concat(this._recentObjectiveSurveyResponseIDs), this.interactionEvents);
    this.pageRecentIntHrActions(0);
  }

  async buildEventObjects(ids: string[]) {
    for (const id of ids) {
      if (!this.interactionEvents.has(id)) {
        const calEvent = await this.calendarEventService.getCalendarEventByIDMemoize(id);
        this.interactionEvents.set(id, {
          type: SurveyTypeCodes.EVENT,
          id,
          summary: calEvent.summary,
          start: calEvent.start,
          object: calEvent
        });
      }
    }
  }

  async buildObjectiveObjects(surveys: Survey[]) {
    for (const survey of surveys) {
      if (!this.interactionEvents.has(survey.id)) {
        const objectiveAssignment = await this.objectiveService.getObjectiveAssignmentMemoize(survey.objectID);
        if (objectiveAssignment) {
          this.interactionEvents.set(survey.id, {
            type: SurveyTypeCodes.OBJECTIVE,
            id: survey.id,
            summary: objectiveAssignment.objectiveType === ObjectiveTypeCodes.QUANTIFIABLE ? (objectiveAssignment as QuantifiableObjectiveAssignment).title : (objectiveAssignment as ObjectiveAssignment).orgObjective.objective.display,
            start: survey.responseReceivedDate,
            object: survey
          });
        }
      }
    }
  }

  pageMissedEvents(page: number) {
    if (page * 8 > this.missedEventIDs.length) {
      page = 0;
    }
    this.missedEventsMultiplier = page;
    const start = page * 8;
    const missedEventIDsView = [];
    for (let i = 0; i < 8 && start + i < this.missedEventIDs.length; i++) {
      missedEventIDsView.push(this.missedEventIDs[start + i]);
    }
    this.missedEventIDsView = missedEventIDsView;
  }

  pageRecentIntHrActions(page: number) {
    if (page * 8 > this.recentIntHRactionObjects.length) {
      page = 0;
    }
    this.recentViewMultiplier = page;
    const start = page * 8;
    const recentIntHRactionEventIDsView = [];
    for (let i = 0; i < 8 && start + i < this.recentIntHRactionObjects.length; i++) {
      recentIntHRactionEventIDsView.push(this.recentIntHRactionObjects[start + i]);
    }
    this.recentIntHRactionEventIDsView = recentIntHRactionEventIDsView;
  }

  pageOpenIntHrActions(page: number) {
    if (page * 8 > this.openInteractions.length) {
      page = 0;
    }
    this.openInteractionsMultiplier = page;
    const start = page * 8;
    const openIntHRactionEventIDsView = [];
    for (let i = 0; i < 8 && start + i < this.openInteractions.length; i++) {
      openIntHRactionEventIDsView.push(this.openInteractions[start + i]);
    }
    this.openInteractionsView = openIntHRactionEventIDsView;
  }

  async getEmployeeScoreColor(employeeID: string) {
    const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION);
    if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
      return EmployeeService.getScoreColor(await this.employeeService.getEmployeeMTDScoreMemoize(employeeID), 6);
    } else {
      return EmployeeService.getScoreColor(await this.employeeService.getEmployeeYTDScoreMemoize(employeeID), 6);
    }
  }

  async editOneOnOneNote($event: any) {
    let noteTypeRoute = "one-on-one";
    const note = await this.noteService.getNoteByID($event.noteId);
    switch (note.options.reviewType) {
      case ReviewTypeKeys.COMMUNICATION: {
        noteTypeRoute = "communication";
        break;
      }
      case ReviewTypeKeys.ONE: {
        noteTypeRoute = "one-on-one";
        break;
      }
      case ReviewTypeKeys.QUARTER: {
        noteTypeRoute = "quarterly-review";
        break;
      }
      case ReviewTypeKeys.ANNUAL: {
        noteTypeRoute = "annual-review";
        break;
      }
    }
    this.router.navigate([`${noteTypeRoute}/${$event.noteId}`], { relativeTo: this.activatedRoute });
  }

  async pageGivenInspHRations(page: number) {
    if (page * 8 > this.givenInspHRations.length) {
      page = 0;
    }
    this.givenInspHRationsMultiplier = page;
    const start = page * 8;
    const givenInspHRationsView = [];
    for (let i = 0; i < 8 && start + i < this.givenInspHRations.length; i++) {
      const survey: InspHRationSurvey = this.givenInspHRations[start + i];
      const participant = await this.employeeService.getEmployeeByEmailMemoize(survey.participantEmail);
      survey.participantFirstName = participant.firstName;
      survey.participantLastName = participant.lastName;
      givenInspHRationsView.push(survey);
    }
    this.givenInspHRationsView = givenInspHRationsView;
  }

  async expandGivenInspHRations() {
    if (this.givenInspHRations.length > 1) {
      this.givenInspHRations.sort((a, b) => a.responseReceivedDate > b.responseReceivedDate ? -1 : a.responseReceivedDate < b.responseReceivedDate ? 1 : 0);
    }
    await this.pageGivenInspHRations(0);
  }

  async pageReceivedInspHRations(page: number) {
    if (page * 8 > this.receivedInspHRations.length) {
      page = 0;
    }
    this.receivedInspHRationsMultiplier = page;
    const start = page * 8;
    const receivedInspHRationsView = [];
    for (let i = 0; i < 8 && start + i < this.receivedInspHRations.length; i++) {
      const survey: InspHRationSurvey = this.receivedInspHRations[start + i];
      const respondent = await this.employeeService.getEmployeeByEmailMemoize(survey.respondentEmail);
      survey.respondentFirstName = respondent.firstName;
      survey.respondentLastName = respondent.lastName;
      receivedInspHRationsView.push(survey);
    }
    this.receivedInspHRationsView = receivedInspHRationsView;
  }

  async expandReceivedInspHRations() {
    if (this.receivedInspHRations.length > 1) {
      this.receivedInspHRations.sort((a, b) => a.responseReceivedDate > b.responseReceivedDate ? -1 : a.responseReceivedDate < b.responseReceivedDate ? 1 : 0);
    }
    await this.pageReceivedInspHRations(0);
  }

  openInspHRationResult(survey: Survey) {
    const resultsData: InspHRationResultsData = {
      survey
    };
    this.dialog.open(InsphrationResultDialogComponent, {
      width: "400px",
      data: resultsData
    });
  }

  async reviewOneOnOneNote($event: any) {
    let noteTypeRoute = "view-one-on-one";
    const note = await this.noteService.getNoteByID($event.noteId);
    switch (note.options.review) {
      case ReviewTypeKeys.COMMUNICATION: {
        noteTypeRoute = "view-communication";
        break;
      }
      case ReviewTypeKeys.ONE: {
        noteTypeRoute = "view-one-on-one";
        break;
      }
      case ReviewTypeKeys.QUARTER: {
        noteTypeRoute = "view-quarterly-review";
        break;
      }
      case ReviewTypeKeys.ANNUAL: {
        noteTypeRoute = "view-annual-review";
        break;
      }
    }
    this.router.navigate([`${noteTypeRoute}/${$event.noteId}`], { relativeTo: this.activatedRoute });
  }

  async openInspHRationResponseList(insphration: OrganizationShoutOutType) {
    this.dialog.open(InsphrationResultListDialogComponent, {
      width: "600px",
      data: {
        employee: this.employee,
        type: InspHRationCounterType.QTR,
        orgInspHRation: insphration
      } as InspHRationResultsListData
    });
  }

  private findEventIDs(eventSurveyList: EventSurvey[]): string[] {
    const ids = [];
    for (const survey of eventSurveyList) {
      if (!ids.includes(survey.objectID)) {
        ids.push(survey.objectID);
      }
      if (survey.feedback && survey.feedback.length > 0) {
        this.eventsWithComments.add(survey.objectID);
      }
    }
    return ids;
  }

  private findObjectiveSurveyResponseIDs(objectiveSurveyList: ObjectiveSurvey[]): string[] {
    const ids = [];
    for (const survey of objectiveSurveyList) {
      if (!ids.includes(survey.id)) {
        ids.push(survey.id);
      }
      if (survey.feedback && survey.feedback.length > 0) {
        this.eventsWithComments.add(survey.id);
      }
    }
    return ids;
  }

  private async findStandUpNotes(objectID: string): Promise<Note[]> {
    return this.noteService.getOneOnOneNotesByObjectIDMemoize(objectID, NoteTypes.STANDUP);
  }

  private async findOneOnOneNotes(noteType: NoteTypes): Promise<Note[]> {
    const notes = await this.noteService.getOneOnOneNotesByObjectIDMemoize(this.employee.id, noteType);
    return notes.sort((a, b) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);
  }

  private async findInspHRationCounts() {
    const inspHRationMap: Map<string, number> = new Map<string, number>();
    const inspHRationCounts = await this.shoutOutService.getInspHRationCountsForEmployee(this.employee.orgId, this.employee.id, InspHRationCounterType.QTR);
    for (const count of inspHRationCounts) {
      inspHRationMap.set(count.organizationShoutOutTypeID, count.count);
    }
    this.inspHRationCountMap = inspHRationMap;
  }

  private async findOpenInspHRations() {
    this.openInspHRations = await this.surveyService.getOpenInspHRationSurveyForEmployee(this.employee.email);
  }

  showStandupHistory() {
    this.dialog.open(EmployeeStandupHistoryComponent, {
      data: { employee: this.employee }
    });
  }

  getStandupQuestion(configuration: StandupSurveyConfiguration, questionMap: QuestionsMap): string {
    const question = configuration.questions.find(q => q.id == questionMap.id);
    return question.questionTextArray[questionMap.version];
  }
}

interface RecentSurvey {
  type: string;
  id: string;
  summary: string;
  start: string;
  object: CalEvent | ObjectiveSurvey;

}

interface InspHRationSurvey extends Survey {
  participantFirstName?: string;
  participantLastName?: string;
  respondentFirstName?: string;
  respondentLastName?: string;
}
