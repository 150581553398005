import { APIService } from "./API.service";
import { Employee } from "@inthraction/data-models";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import { AuthService } from "./auth.service";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";

export class BaseService {

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
  }

  public async clearAllCache() {
    for (const fnKey of MEMOIZE_FN_MAP.keys()) {
      MEMOIZE_FN_MAP.get(fnKey).clear();
    }
  }

  async getAllWithHashKeyAndRangeKey<T>(fn: Function, hashKey: any, rangeKey:any, filter: any, options?: GetAllOptions): Promise<T[]> {
    let results: T[] = [];
    let response: ResponseInterface<T>;
    const limit = options?.limit ? options.limit : 1000;
    do {
      response = await fn(hashKey, rangeKey, filter, limit, (response?.nextToken ? response.nextToken : null));
      results.push(...response.items);
    } while (response.nextToken);
    return results.filter((elm: T, pos: number, array: T[]) => {
      return array.indexOf(elm) == pos;
    });
  }

  async getAllWithHashKey<T>(fn: Function, hashKey: any, filter: any, options?: GetAllOptions): Promise<T[]> {
    let results: T[] = [];
    let response: ResponseInterface<T>;
    const limit = options?.limit ? options.limit : 1000;
    do {
      response = await fn(hashKey, filter, limit, (response?.nextToken ? response.nextToken : null));
      results.push(...response.items);
    } while (response.nextToken);
    return results.filter((elm: T, pos: number, array: T[]) => {
      return array.indexOf(elm) == pos;
    });
  }

  async getAll<T>(fn: Function, filter: any, options?: GetAllOptions): Promise<T[]> {
    let results: T[] = [];
    let response: ResponseInterface<T>;
    const limit = options?.limit ? options.limit : 1000;
    do {
      response = await fn(filter, limit, (response?.nextToken ? response.nextToken : null));
      results.push(...response.items);
    } while (response.nextToken);
    return results.filter((elm: T, pos: number, array: T[]) => {
      return array.indexOf(elm) == pos;
    });
  }

  @Memoize({ maxAge: 10000, preFetch: true })
  protected async getCurrentUser(): Promise<Employee> {
    let securityUser = this.authService.currentUser;
    if (!securityUser) {
      securityUser = await this.authService.currentAuthenticatedUser(true);
      if (securityUser) {
        this.authService.currentUser = securityUser;
      }
    }
    if (securityUser) {
      try {
        return await this.getEmployee(securityUser);
      } catch (e) {
        securityUser = await this.authService.currentAuthenticatedUser(true);
        if (securityUser) {
          this.authService.currentUser = securityUser;
          return await this.getEmployee(securityUser);
        }
        return undefined;
      }
    }
    return undefined;
  }

  private async getEmployee(securityUser: CognitoUser) {
    const getUserAttributesPromise = () => {
      return new Promise<CognitoUserAttribute[]>((resolve, reject) => {
        securityUser.getUserAttributes((err, data) => {
          if (err) {
            return reject(err);
          }
          resolve(data);
        });
      });
    };
    let userAttributes = await getUserAttributesPromise();
    const emailAttribute = userAttributes.find(a => a.getName() == "email");
    const employeeID = await this.getEmployeeIDByEmailInternal(emailAttribute?.getValue().toLowerCase());
    return this.api.GetEmployee(employeeID);
  }

  @Memoize()
  protected async getEmployeeIDByEmailInternal(email: string): Promise<string> {
    if (email) {
      const employees = await this.getAll<Employee>(this.api.ListEmployees, { email: { eq: email } });
      if (employees.length) {
        return employees[0].id;
      }
    }
    return null;
  }

  protected async getUserGroups(): Promise<string[]> {
    let securityUser = this.authService.currentUser;
    if (!securityUser) {
      securityUser = await this.authService.currentAuthenticatedUser(true);
      if (securityUser) {
        this.authService.currentUser = securityUser;
      }
    }
    const userGroups: string[] = [];
    const signInUserSession = securityUser?.getSignInUserSession();
    if (signInUserSession && signInUserSession.getIdToken().payload["cognito:groups"]?.length) {
      userGroups.push(...signInUserSession.getIdToken().payload["cognito:groups"]);
    }
    return userGroups;
  }

}

export interface GetAllOptions {
  limit?: number;
}

interface ResponseInterface<T> {
  __typename: string;
  items?: T[];
  nextToken?: string;
}
