import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import * as shape from "d3-shape";
import { MultiSeries, Series } from "@swimlane/ngx-charts";
import * as moment from "moment";
import { Moment } from "moment";
import { EmployeeService, OrganizationService, SurveyService } from "@inthraction/services";
import { OBJECTIVE_SURVEY_RESPONSE_TYPES, OrganizationConfigurationCodes } from "@inthraction/codes";
import { OBJECTIVE_SURVEY_RESPONSE_LABELS } from "@inthraction/labels";
import { Employee, ObjectiveAssignmentProgressCheck, Survey } from "@inthraction/data-models";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { SurveyCommentsDialogComponent } from "../dialogs/survey-comments-dialog/survey-comments-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "inthraction-objective-score-chart",
  templateUrl: "./objective-score-chart.component.html",
  styleUrls: ["./objective-score-chart.component.scss"]
})
export class ObjectiveScoreChartComponent implements OnInit, OnChanges {

  @Input() employee: Employee;
  @Input() options: ObjectiveScoreChartOptions = {};
  @Input() objectiveAssignmentID: string;
  @Output() openResponseEmitter = new EventEmitter();

  lowResponses: Survey[] = [];
  highResponses: Survey[] = [];
  emailToNameMap: Map<string, string> = new Map<string, string>();


  surveyResponseOptionsDisplay = OBJECTIVE_SURVEY_RESPONSE_LABELS;
  surveyResponseOptions = OBJECTIVE_SURVEY_RESPONSE_TYPES;

  selectedColorScheme: string;
  view: any[];
  colorScheme: any;
  // colorSets: any;
  schemeType = "ordinal";
  // range: boolean = true;
  animations = true;
  showLegend = false;
  legendTitle = "Legend";
  legendPosition = "right";
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = false;
  showYAxisLabel = false;
  xAxisLabel = "Country";
  yAxisLabel = "GDP Per Capita";
  autoScale = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin = 2;
  yScaleMax = 10;
  timeline = true;
  showGridLines = true;
  curves = {
    "Basis": shape.curveBasis,
    "Basis Closed": shape.curveBasisClosed,
    "Bundle": shape.curveBundle.beta(1),
    "Cardinal": shape.curveCardinal,
    "Cardinal Closed": shape.curveCardinalClosed,
    "Catmull Rom": shape.curveCatmullRom,
    "Catmull Rom Closed": shape.curveCatmullRomClosed,
    "Linear": shape.curveLinear,
    "Linear Closed": shape.curveLinearClosed,
    "Monotone X": shape.curveMonotoneX,
    "Monotone Y": shape.curveMonotoneY,
    "Natural": shape.curveNatural,
    "Step": shape.curveStep,
    "Step After": shape.curveStepAfter,
    "Step Before": shape.curveStepBefore,
    "default": shape.curveLinear
  };
  curveType = "Linear";
  curve: any = this.curves[this.curveType];
  rangeFillOpacity = 0.15;
  roundDomains = false;
  tooltipDisabled = false;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;

  width = 388;
  height = 250;
  fitContainer = true;

  chartData: MultiSeries = [];

  today = new Date();
  currentYear: number = this.today.getFullYear();
  currentMonth: number = this.today.getMonth();
  thisYear: Moment = moment((new Date("1/1/" + this.currentYear)).valueOf());
  rollingYearStart: Moment = moment((new Date((this.currentMonth + 1) + "/1/" + (this.currentYear - 1))).valueOf());

  score: number;
  startingScore: number;

  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService,
    private surveyService: SurveyService,
    private googleAnalyticsService: GoogleAnalyticsService) {
    this.setColorScheme("fire");
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ((changes.options && !changes.options?.firstChange)) {
      this.chartData = await this.generateChartData(this.options?.startDate, this.options?.endDate);
    }
  }

  async ngOnInit(): Promise<void> {
    this.chartData = await this.generateChartData(this.options?.startDate, this.options?.endDate);
    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }

  async getScoreStartMoment(orgID: string): Promise<Moment> {
    let startingPoint: Moment;
    const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION, orgID);
    if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
      startingPoint = this.rollingYearStart;
    } else {
      startingPoint = this.thisYear;
    }
    return startingPoint;
  }

  // TODO Do we need to deal with surveys on the same date... maybe combine the result into one point???
  async generateChartData(startDate?: string, endDate?: string): Promise<MultiSeries> {

    const results: MultiSeries = [];
    const series: Series = {
      name: `Objective Survey`,
      series: []
    };
    let scoreStart = (await this.getScoreStartMoment(this.employee.orgId)).toISOString().replace(".000Z", "Z");
    if (startDate && scoreStart > startDate) {
      scoreStart = startDate;
    }
    const scores = await this.employeeService.getEmployeeObjectiveScoresMemoize(this.employee.id, scoreStart, this.objectiveAssignmentID, endDate);
    if (scores.length > 0) {
      scores.sort((a, b) => (a.scoreStart > b.scoreStart) ? 1 : ((b.scoreStart > a.scoreStart) ? -1 : 0));

      const highID: string[] = [];
      const lowID: string[] = [];

      let count = 0;
      let sum = 0;

      for (const employeeScore of scores) {
        count++;
        if (employeeScore.employeeObjectiveScoreDetails) {
          sum += employeeScore.employeeObjectiveScoreDetails.score;
        } else {
          sum += employeeScore.score;
        }

        if (!startDate || employeeScore.scoreStart >= startDate) {
          if (employeeScore.employeeObjectiveScoreDetails) {
            if (employeeScore.employeeObjectiveScoreDetails.score > 7) { // High Meets = 7.7
              highID.push(employeeScore.specifier);
            }
            if (employeeScore.employeeObjectiveScoreDetails.score < 4) { // Low Meets = 3.7
              lowID.push(employeeScore.specifier);
            }
          } else {
            if (employeeScore.score > 7) { // High Meets = 7.7
              highID.push(employeeScore.specifier);
            }
            if (employeeScore.score < 4) { // Low Meets = 3.7
              lowID.push(employeeScore.specifier);
            }
          }
          series.series.push(
            {
              value: SurveyService.round(sum / count, 2),
              name: moment(employeeScore.scoreStart).toDate(),
              extra: {
                objectiveResponseID: employeeScore.specifier,
                employeeID: this.employee.id
              }
            });
        } else {
          this.startingScore = SurveyService.round(sum / count, 2);
        }
      }

      if (series.series.length) {
        this.score = SurveyService.round(sum / count, 2);
        results.push(series);
      }

      if (this.options.showRecentResponses) {
        const highResponses: Survey[] = [];
        if (highID.length > 0) {
          for (const surveyID of highID.reverse()) {
            const survey = await this.surveyService.getSurveyMemoize(surveyID);
            if (survey && survey.feedback && survey.feedback.length > 0) {
              highResponses.push(survey);
              this.emailToNameMap.set(survey.respondentEmail, await this.getEmployeeName(survey.respondentEmail));
            }
          }
        }
        this.highResponses = highResponses;
        const lowResponses: Survey[] = [];
        if (lowID.length > 0) {
          for (const surveyID of lowID.reverse()) {
            const survey = await this.surveyService.getSurveyMemoize(surveyID);
            if (survey.feedback && survey.feedback.length > 0) {
              lowResponses.push(survey);
              this.emailToNameMap.set(survey.respondentEmail, await this.getEmployeeName(survey.respondentEmail));
            }
          }
        }
        this.lowResponses = lowResponses;
      }

    }
    return results;
  }

  select(data: any): void {
    this.googleAnalyticsService.eventEmitter("objective-chart-response-view", "objective-response", "view_item", "click", 10);
    if (data.extra) {
      this.openResponseEmitter.emit(data.extra);
    }
  }

  // TODO
  activate(data: any): void {
    // console.debug('Activate', JSON.parse(JSON.stringify(data)));
  }

  // TODO
  deactivate(data: any): void {
    // console.debug('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  applyDimensions(): void {
    this.view = [this.width, this.height];
  }

  setColorScheme(name: string): void {
    this.selectedColorScheme = name;
    this.colorScheme = {
      name: "fire",
      selectable: true,
      group: "Ordinal",
      domain: [
        "#ff3d00", "#bf360c", "#ff8f00", "#ff6f00", "#ff5722", "#e65100", "#ffca28", "#ffab00"
      ]
    };
  }

  dateTickFormatting(val: any): string {
    if (val instanceof Date) {
      return moment(val).format("ll");
    } else {
      return val;
    }
  }

  async getEmployeeName(respondentEmail: string): Promise<string> {
    const employee = await this.employeeService.getEmployeeByEmailMemoize(respondentEmail);
    return `${employee.firstName} ${employee.lastName}`;
  }

  showSurveyComments(surveys: Survey[]) {
    this.dialog.open(SurveyCommentsDialogComponent, {
      width: "600px",
      maxHeight: "800px",
      data: surveys
    });
  }

}

export interface ObjectiveScoreChartOptions {
  managerAccess?: boolean;
  hideChart?: boolean;
  showRecentResponses?: boolean;
  objectiveAssignmentProgressCheck?: ObjectiveAssignmentProgressCheck;
  startDate?: string;
  endDate?: string;
}
