import { Injectable } from "@angular/core";
import { APIService, ModelSurveyFilterInput, SurveyType, UpdateSurveyInput } from "./API.service";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import { StandupSurvey, Survey } from "@inthraction/data-models";
import {
  EventSurveyResponseOptions,
  ObjectiveSurveyResponseScores,
  round,
  ShoutOutTypeStatus,
  SurveyResponseFilterDirection,
  SurveyStatus
} from "@inthraction/codes";
import { BaseService } from "./base.service";
import * as moment from "moment";
import { ShoutOutService } from "./shout-out.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class SurveyService extends BaseService {

  constructor(
    protected api: APIService,
    protected shoutOutService: ShoutOutService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  public static getFivePointResponseScore(response: number): number {
    let score: number;
    switch (response) {
      case 1:
        score = ObjectiveSurveyResponseScores.NEEDS_IMPROVEMENT;
        break;
      case 2:
        score = ObjectiveSurveyResponseScores.LOW_MEETS;
        break;
      case 3:
        score = ObjectiveSurveyResponseScores.MEETS;
        break;
      case 4:
        score = ObjectiveSurveyResponseScores.HIGH_MEETS;
        break;
      case 5:
        score = ObjectiveSurveyResponseScores.EXCEEDS;
        break;
    }
    return score;
  }

  static calculateNextSurveyDate(startDate: string, cadence: string, endDate: string): string {
    let nextSurveyDate = moment(startDate, "YYYY-MM-DD");
    switch (cadence) {
      case "WEEKLY": {
        nextSurveyDate = nextSurveyDate.add(7, "days");
        break;
      }
      case "BI_WEEKLY": {
        nextSurveyDate = nextSurveyDate.add(14, "days");
        break;
      }
      case "MONTHLY": {
        nextSurveyDate = nextSurveyDate.add(28, "days");
        break;
      }
      case "QUARTERLY": {
        nextSurveyDate = nextSurveyDate.add(84, "days");
        break;
      }
      case "ANNUALLY": {
        nextSurveyDate = nextSurveyDate.add(364, "days");
        break;
      }
    }
    if (endDate && endDate < nextSurveyDate.format("YYYY-MM-DD")) {
      return endDate;
    }
    if (moment().format("YYYY-MM-DD") > nextSurveyDate.format("YYYY-MM-DD")) {
      return SurveyService.calculateNextSurveyDate(nextSurveyDate.format("YYYY-MM-DD"), cadence, endDate);
    }
    return nextSurveyDate.format("YYYY-MM-DD");
  }

  public static round(value: number, precision: number): number {
    return round(value, precision);
  }

  clearMemoizedOpenSurveys() {
    if (MEMOIZE_FN_MAP.has("getOpenEventSurveyMemoize")) {
      MEMOIZE_FN_MAP.get("getOpenEventSurveyMemoize").clear();
    }
    if (MEMOIZE_FN_MAP.has("getPendingResponseSurveysByRespondentEmailMemoize")) {
      MEMOIZE_FN_MAP.get("getPendingResponseSurveysByRespondentEmailMemoize").clear();
    }
  }

  clearMemoizedInspHRationSurveys(email: string) {
    if (MEMOIZE_FN_MAP.has("getReceivedInspHRations")) {
      MEMOIZE_FN_MAP.get("getReceivedInspHRations").delete(email);
    }
    if (MEMOIZE_FN_MAP.has("getGivenOrReceivedInspHRactions")) {
      MEMOIZE_FN_MAP.get("getGivenOrReceivedInspHRactions").delete(email);
    }
    if (MEMOIZE_FN_MAP.has("getGivenInspHRations")) {
      MEMOIZE_FN_MAP.get("getGivenInspHRations").delete(email);
    }
    if (MEMOIZE_FN_MAP.has("getOpenInspHRationSurveyForEmployee")) {
      if (!email) {
        MEMOIZE_FN_MAP.get("getOpenInspHRationSurveyForEmployee").clear();
      } else {
        MEMOIZE_FN_MAP.get("getOpenInspHRationSurveyForEmployee").delete(email);
      }
    }
  }

  clearMemoizedSurvey(surveyID: string) {
    if (MEMOIZE_FN_MAP.has("getSurveyByIDMemoize")) {
      MEMOIZE_FN_MAP.get("getSurveyByIDMemoize").delete(surveyID);
    }
  }

  async updateInspHRationSurvey(survey: UpdateSurveyInput): Promise<Survey> {
    const result = await this.updateSurvey(survey);
    this.clearMemoizedInspHRationSurveys(result.participantEmail);
    this.clearMemoizedInspHRationSurveys(result.respondentEmail);
    this.clearMemoizedSurvey(result.id);
    return result;
  }

  async updateSurvey(survey: UpdateSurveyInput): Promise<Survey> {
    const surveyResult = await this.api.UpdateSurvey(survey);
    this.clearMemoizedOpenSurveys();
    this.clearMemoizedSurvey(surveyResult.id);
    return surveyResult;
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getPendingResponseSurveysByRespondentEmailMemoize(email: string, objectID?: string): Promise<Survey[]> {
    return this.getPendingResponseSurveysByRespondentEmail(email, objectID);
  }

  async getPendingResponseSurveysByRespondentEmail(email: string, objectID?: string): Promise<Survey[]> {
    const filter: ModelSurveyFilterInput = {
      and: [
        { respondentEmail: { eq: email } },
        { responseReceived: { eq: false } },
        { status: { ne: SurveyStatus.DELETED } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyType: { ne: SurveyType.INSPHRATION } }
      ]
    };

    if (objectID) {
      filter.and.push({ objectID: { eq: objectID } });
    }

    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getSurveyByIDMemoize(surveyId: string): Promise<Survey> {
    return this.getSurveyByID(surveyId);
  }

  async getSurveyByID(surveyId: string): Promise<Survey> {
    if (surveyId) {
      const standupSurvey = await this.api.GetStandupSurvey(surveyId);
      if (standupSurvey) {
        return new StandupSurvey(standupSurvey);
      } else {
        return await this.api.GetSurvey(surveyId);
      }
    } else {
      return null;
    }


    return surveyId ? await this.api.GetSurvey(surveyId) : null;
  }

  async getSurveysByObjectID(objectID: string): Promise<Survey[]> {
    const filter = { objectID: { eq: objectID } };
    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  getSurveysByObjectIDMemoize(objectID: string): Promise<Survey[]> {
    return this.getSurveysByObjectID(objectID);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getSurveyMemoize(surveyId: string): Promise<Survey> {
    return this.getSurveyByID(surveyId);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getOpenEventSurveyMemoize(respondentEmail: string): Promise<Survey[]> {
    const filter = {
      and: [
        { surveyType: { eq: SurveyType.EVENT } },
        { respondentEmail: { eq: respondentEmail } },
        { responseReceived: { eq: false } },
        { status: { ne: SurveyStatus.DELETED } },
        { status: { ne: SurveyStatus.MISSED } }
      ]
    };
    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getRecentSurveysByParticipantMemoize(participantEmail: string, oldestSurveyResponseDate?: string): Promise<Survey[]> {
    const filter = {
      and: [
        {
          or: [
            { surveyType: { eq: SurveyType.OBJECTIVE } },
            {
              and: [
                { surveyType: { eq: SurveyType.EVENT } },
                { surveyResponse: { gt: EventSurveyResponseOptions.DID_NOT_ATTEND } }
              ]
            }
          ]
        },
        { participantEmail: { eq: participantEmail } },
        { responseReceivedDate: { gt: oldestSurveyResponseDate } },
        { status: { ne: SurveyStatus.DELETED } },
        { status: { ne: SurveyStatus.MISSED } }
      ]
    };

    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getObjectiveSurveysByParticipantMemoize(participantEmail: string, oldestSurveyResponseDate?: string): Promise<Survey[]> {
    const filter = {
      and: [
        { surveyType: { eq: SurveyType.OBJECTIVE } },
        { participantEmail: { eq: participantEmail } },
        { responseReceivedDate: { gt: oldestSurveyResponseDate } }
      ]
    };

    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getEventSurveysByRespondentMemoize(respondentEmail: string, surveyResponseFilterDirection?: SurveyResponseFilterDirection, surveyResponseOption?: EventSurveyResponseOptions, oldestSurveyResponseDate?: string): Promise<Survey[]> {
    return this.getEventSurveyByEmail(RetrievalType.RESPONDENT, respondentEmail, surveyResponseFilterDirection, surveyResponseOption, oldestSurveyResponseDate);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getEventSurveysByParticipantMemoize(participantEmail: string, surveyResponseFilterDirection?: SurveyResponseFilterDirection, surveyResponseOption?: EventSurveyResponseOptions, oldestSurveyResponseDate?: string): Promise<Survey[]> {
    return this.getEventSurveyByEmail(RetrievalType.PARTICIPANT, participantEmail, surveyResponseFilterDirection, surveyResponseOption, oldestSurveyResponseDate);
  }

  async getRespondedEventSurveysByParticipantEmailAndObjectID(participantEmail: string, eventID: string) {
    const filter = {
      and: [
        { objectID: { eq: eventID } },
        { responseReceived: { eq: true } },
        { participantEmail: { eq: participantEmail } }
      ]
    };

    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  async getSurveysByObjectIDAndEmail(objectID: string, email: string): Promise<Survey[]> {
    const filter = {
      and: [
        { objectID: { eq: objectID } },
        {
          or: [
            { respondentEmail: { eq: email } },
            { participantEmail: { eq: email } }
          ]
        }
      ]
    };
    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  async getEventSurveysWithFeedback(participantEmail: string, startDate?: string, endDate?: string): Promise<Survey[]> {
    const filter: ModelSurveyFilterInput = {
      and: [
        { surveyType: { eq: SurveyType.EVENT } },
        { feedback: { attributeExists: true } },
        { participantEmail: { eq: participantEmail } }
      ]
    };

    if (startDate) {
      filter.and.push({ surveyDate: { ge: startDate } });
    }

    if (endDate) {
      filter.and.push({ surveyDate: { le: endDate } });
    }

    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  /**
   * Super Admin Function
   */
  async getAllEventSurveysForRange(startDate?: string, endDate?: string): Promise<Survey[]> {
    const filter: ModelSurveyFilterInput = {
      and: [
        { surveyType: { eq: SurveyType.EVENT } }
      ]
    };

    if (!startDate) {
      startDate = moment().subtract(30, "days").toISOString();
    }
    filter.and.push({ surveyDate: { ge: startDate } });

    if (!endDate) {
      endDate = moment().toISOString();
    }
    filter.and.push({ surveyDate: { le: endDate } });

    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  /**
   * Super Admin Function
   */
  async getAllObjectiveSurveysForRange(startDate?: string, endDate?: string): Promise<Survey[]> {
    const filter: ModelSurveyFilterInput = {
      and: [
        { surveyType: { eq: SurveyType.OBJECTIVE } }
      ]
    };

    if (!startDate) {
      startDate = moment().subtract(30, "days").toISOString();
    }
    filter.and.push({ surveyDate: { ge: startDate } });

    if (!endDate) {
      endDate = moment().toISOString();
    }
    filter.and.push({ surveyDate: { le: endDate } });

    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getOpenInspHRationSurveyForEmployee(respondentEmail: string): Promise<Survey[]> {
    const filter: ModelSurveyFilterInput = {
      and: [
        { respondentEmail: { eq: respondentEmail } },
        { responseReceived: { eq: false } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyType: { eq: SurveyType.INSPHRATION } }
      ]
    };
    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getGivenOrReceivedInspHRactions(email: string): Promise<Survey[]> {
    const filter: ModelSurveyFilterInput = {
      and: [
        {
          or: [
            { respondentEmail: { eq: email } },
            { participantEmail: { eq: email } }
          ]
        },
        { responseReceived: { eq: true } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyType: { eq: SurveyType.INSPHRATION } }
      ]
    };
    return this.filterForDeletedShoutOutTypes(await this.getAll<Survey>(this.api.ListSurveys, filter));
  }


  @Memoize({ maxAge: 600000, preFetch: true })
  async getGivenInspHRations(respondentEmail: string): Promise<Survey[]> {
    const filter: ModelSurveyFilterInput = {
      and: [
        { respondentEmail: { eq: respondentEmail } },
        { responseReceived: { eq: true } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyType: { eq: SurveyType.INSPHRATION } }
      ]
    };
    return this.filterForDeletedShoutOutTypes(await this.getAll<Survey>(this.api.ListSurveys, filter));
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getReceivedInspHRations(participantEmail: string, objectId?: string): Promise<Survey[]> {
    const filter: ModelSurveyFilterInput = {
      and: [
        { participantEmail: { eq: participantEmail } },
        { responseReceived: { eq: true } },
        { status: { ne: SurveyStatus.MISSED } },
        { surveyType: { eq: SurveyType.INSPHRATION } }
      ]
    };

    if (objectId) {
      filter.and.push({ objectID: { eq: objectId } });
    }

    return this.filterForDeletedShoutOutTypes(await this.getAll<Survey>(this.api.ListSurveys, filter));
  }

  async filterForDeletedShoutOutTypes(surveys: Survey[]): Promise<Survey[]> {
    const filterIDs: string[] = [];
    for (const item of surveys) {
      if (!filterIDs.includes(item.objectID)) {
        const orgShoutOutType = await this.shoutOutService.getMemoizedOrganizationShoutOutType(item.objectID);
        if (orgShoutOutType.shoutOutType.status == ShoutOutTypeStatus.DELETED) {
          filterIDs.push(item.objectID);
        }
      }
    }
    return surveys.filter(s => !filterIDs.includes(s.objectID));
  }

  private getEventSurveyByEmail(retrieval: RetrievalType, email: string, surveyResponseFilterDirection: SurveyResponseFilterDirection | SurveyResponseFilterDirection.ne | SurveyResponseFilterDirection.eq | SurveyResponseFilterDirection.le | SurveyResponseFilterDirection.lt | SurveyResponseFilterDirection.ge | SurveyResponseFilterDirection.gt, surveyResponseOption: EventSurveyResponseOptions, oldestSurveyResponseDate: string) {
    const filter: ModelSurveyFilterInput = {
      and: [
        { surveyType: { eq: SurveyType.EVENT } }
      ]
    };

    if (retrieval == RetrievalType.RESPONDENT) {
      filter.and.push({ respondentEmail: { eq: email } });
    } else {
      filter.and.push({ participantEmail: { eq: email } });
    }

    if (surveyResponseFilterDirection) {
      let responseFilter;
      switch (surveyResponseFilterDirection) {
        case SurveyResponseFilterDirection.eq: {
          responseFilter = { eq: surveyResponseOption };
          break;
        }
        case SurveyResponseFilterDirection.ge: {
          responseFilter = { ge: surveyResponseOption };
          break;
        }
        case SurveyResponseFilterDirection.gt: {
          responseFilter = { gt: surveyResponseOption };
          break;
        }
        case SurveyResponseFilterDirection.le: {
          responseFilter = { le: surveyResponseOption };
          break;
        }
        case SurveyResponseFilterDirection.lt: {
          responseFilter = { lt: surveyResponseOption };
          break;
        }
        case SurveyResponseFilterDirection.ne: {
          responseFilter = { ne: surveyResponseOption };
          break;
        }
      }
      filter.and.push({ surveyResponse: responseFilter });
    }

    if (oldestSurveyResponseDate) {
      filter.and.push({ surveyDate: { gt: oldestSurveyResponseDate } });
    }

    return this.getAll<Survey>(this.api.ListSurveys, filter);
  }

}

enum RetrievalType {
  RESPONDENT = "respondentEmail",
  PARTICIPANT = "participantEmail"
}
