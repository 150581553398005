<mat-card appearance="outlined" class="employee-quantifiable-objective-card" [class]="expired ? 'expired': ''">
  <mat-expansion-panel class="mat-elevation-z0 card-header">
    <mat-expansion-panel-header>
      <mat-card-header>
        <div class="title-wrapper">
          <div class="title">
            <mat-card-title>{{objectiveAssignment.title}}</mat-card-title>
          </div>
          <div class="title-due-date-wrapper">
            <mat-card-subtitle *ngIf="objectiveAssignment.endDate">
              End: {{objectiveAssignment.endDate | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}}</mat-card-subtitle>
          </div>
          <div class="objectiveTimeFrameFocusWrapper">
            <mat-card-subtitle>Quantity: {{objectiveAssignment.quantity}}</mat-card-subtitle>
            <mat-card-subtitle>{{OBJECTIVE_TYPE_LABELS[objectiveAssignment.objectiveType]}}</mat-card-subtitle>
          </div>
        </div>
      </mat-card-header>
    </mat-expansion-panel-header>
    <mat-card-content>
      <div class="half-width side-by-side">
        <mat-card-subtitle *ngIf="objectiveAssignment.startDate">
          Start: {{objectiveAssignment.startDate | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}}</mat-card-subtitle>
      </div>
      <div class="half-width text-right side-by-side">
        <mat-card-subtitle>Frequency: {{objectiveCadenceTypeLabels[objectiveAssignment.cadence]}}</mat-card-subtitle>
      </div>
      <div *ngIf="objectiveAssignment.managerDescription" class="manager-objective-wrapper">
        <mat-label>Manager Objective</mat-label>
        <p>{{objectiveAssignment.managerDescription}}</p>
      </div>
    </mat-card-content>
  </mat-expansion-panel>
  <mat-card-footer>
    <div class="objective-score-chart-holder">
      <inthraction-objective-score-chart [employee]="employee" [options]="options" [objectiveAssignmentID]="objectiveAssignment.id" (openResponseEmitter)="openObjectiveSurveyResponse($event.objectiveResponseID, $event.employeeID)"></inthraction-objective-score-chart>
    </div>
    <inthraction-progress-check *ngIf="options?.objectiveAssignmentProgressCheck" [progressCheckData]="options.objectiveAssignmentProgressCheck"></inthraction-progress-check>
  </mat-card-footer>
  <button *ngIf="options.managerAccess" mat-icon-button class="panel-footer-button" (click)="openEditClick(objectiveAssignment.id)" aria-label="Edit Objective">
    <mat-icon>edit</mat-icon>
  </button>
</mat-card>
