import { Component, Inject, OnInit } from "@angular/core";
import { CalEvent, Employee, EventSurvey } from "@inthraction/data-models";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CalendarEventService, EmployeeService, SurveyService } from "@inthraction/services";

@Component({
  selector: "inthraction-inthraction",
  templateUrl: "./inthraction.component.html",
  styleUrls: ["./inthraction.component.scss"]
})
export class InthractionComponent implements OnInit {

  eventID: string;
  event: CalEvent;
  participantEmail: string;
  displayOptions = Object.assign([], EventSurvey.SURVEY_RESPONSE_OPTIONS_DISPLAY);
  scores = [];
  totalScore = 0;
  managerAccess = false;
  employee: Employee;
  surveysWithComments: any[] = [];
  respondents: Employee[] = [];
  eventResponseMap = new Map<number, any>();

  constructor(
    private employeeService: EmployeeService,
    private surveyService: SurveyService,
    private calendarEventService: CalendarEventService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.eventID = data.event;
    this.employee = data.employee;
    this.participantEmail = this.employee.email;
    this.displayOptions = this.displayOptions.reverse();
  }

  async ngOnInit(): Promise<void> {
    // TODO ensure participant email is the current user, subordinate of a manager, or user is HR
    const securityUser = await this.employeeService.getCurrentEmployee();
    this.managerAccess = this.participantEmail !== securityUser.email;
    const employeeScore = (await this.employeeService.getEmployeeEventScores(this.employee.id, this.eventID))[0];
    for (const survey of employeeScore.employeeTotalEventScoreDetails.responses) {
      const response = survey.response;
      const scoreCountObj: { count: number, respondents: string[] } = this.eventResponseMap.get(response) || {
        count: 0,
        respondents: []
      };
      scoreCountObj.count = scoreCountObj.count + 1;
      if (this.managerAccess) {
        const respondent = await this.employeeService.getEmployeeByIDMemoize(survey.respondentID);
        const respondentName = `${respondent.firstName} ${respondent.lastName}`;
        scoreCountObj.respondents.push(respondentName);
        if (survey.feedback && survey.feedback.length > 0) {
          this.respondents.push(respondent);
          this.surveysWithComments.push({ respondent: respondentName, event: survey });
        }
      }
      this.eventResponseMap.set(response, scoreCountObj);
    }
    for (const entry of this.eventResponseMap.entries()) {
      this.scores.push(entry);
    }
    this.event = await this.calendarEventService.getCalendarEventByIDMemoize(this.eventID);
    this.totalScore = await this.employeeService.getEmployeeEventScoreMemoize(this.employee.id, this.eventID);
  }

}
