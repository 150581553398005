export enum OrganizationConfigurationCodes {
  OBJECTIVE_INTHRACTION_COUNT = "OBJECTIVE_INTHRACTION_COUNT",
  INTHRACTION_CALCULATION = "INTHRACTION_CALCULATION",
  MILESTONE_MISSED_DAYS = "MILESTONE_MISSED_DAYS",
  PROJECT_MISSED_DAYS = "PROJECT_MISSED_DAYS",
  OBJECTIVE_MISSED_DAYS = "OBJECTIVE_MISSED_DAYS",
  EVENT_MISSED_DAYS = "EVENT_MISSED_DAYS",
  EVENT_SURVEY_DISABLED = "EVENT_SURVEY_DISABLED",
  MANAGER_OBJECTIVES_DISABLED = "MANAGER_OBJECTIVES_DISABLED",
  READINESS_EVALUATION_CADENCE = "READINESS_EVALUATION_CADENCE",
  NEW_HIRE_14_DAY = "NEW_HIRE_14_DAY",
  NEW_HIRE_90_DAY = "NEW_HIRE_90_DAY",
  NEW_MANAGER_30_DAY = "NEW_MANAGER_30_DAY",
  NEW_MANAGER_90_DAY = "NEW_MANAGER_90_DAY",
  NEW_POSITION_60_DAY = "NEW_POSITION_60_DAY",
  OPEN_EMAIL_DOMAIN = "OPEN_EMAIL_DOMAIN"
}
