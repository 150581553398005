import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Objective, ObjectiveDomain, ObjectiveImpl } from "@inthraction/data-models";
import { ObjectiveService } from "@inthraction/services";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { ToastrService } from "ngx-toastr";
import { UserDefinedObjectiveDomainTypes } from "@inthraction/codes";

@Component({
  selector: "inthraction-edit-objective-dialog",
  templateUrl: "./edit-objective-dialog.component.html",
  styleUrls: ["./edit-objective-dialog.component.scss"]
})
export class EditObjectiveDialogComponent {

  objective: Objective;
  domain: ObjectiveDomain;
  addMode = false;
  organizationID: string;
  objectiveForm: FormGroup;
  readonly UserDefinedObjectiveDomainTypes = UserDefinedObjectiveDomainTypes;

  private objectiveTitleFC = new FormControl("", [Validators.required]);
  private objectiveDefinitionFC = new FormControl("", [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<EditObjectiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objectiveService: ObjectiveService,
    private toastr: ToastrService
  ) {
    this.objectiveForm = new FormGroup({
      objectiveTitle: this.objectiveTitleFC,
      objectiveDefinition: this.objectiveDefinitionFC
    });
    if (data.addMode) {
      this.addMode = true;
      this.domain = JSON.parse((JSON.stringify(data.domain)));
      this.objective = new ObjectiveImpl();
      if (data.organizationID) {
        this.organizationID = data.organizationID;
        this.objective.organizationID = this.organizationID;
      }
      this.objective.domain = this.domain;
      this.objective.objectiveDomainId = this.domain.id;
      this.objective.hints = [];
      this.objective.observables = [];
    } else {
      this.objective = JSON.parse((JSON.stringify(data.objective)));
      this.objectiveDefinitionFC.setValue(this.objective.definition);
      this.objectiveTitleFC.setValue(this.objective.display);
      if (!this.objective.hints) {
        this.objective.hints = [];
      }
    }
  }

  onCancelClick(): void {
    this.dialogRef.close(null);
  }

  public hasError = (controlName: string, errorName: string) =>
    this.objectiveForm.controls[controlName].hasError(errorName);

  async onSaveClick(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    this.objective.display = formValue.objectiveTitle;
    this.objective.definition = formValue.objectiveDefinition;
    const observables: string[] = [];
    if (this.objective.observables) {
      for (const observable of this.objective.observables) {
        if (observable && observable.length > 0 && observable.trim().length > 0) {
          observables.push(observable);
        }
      }
    }
    this.objective.observables = observables;

    const hints: string[] = [];
    if (this.objective.hints) {
      for (const hint of this.objective.hints) {
        if (hint && hint.length > 0 && hint.trim().length > 0) {
          hints.push(hint);
        }
      }
    }
    this.objective.hints = hints;

    try {
      let objectiveResult: Objective;
      if (this.addMode) {
        objectiveResult = await this.objectiveService.createObjective(this.objective);
        this.toastr.success("Objective Created");
      } else {
        objectiveResult = await this.objectiveService.updateObjective(this.objective);
        this.toastr.success("Objective Updated");
      }
      this.dialogRef.close({ objective: objectiveResult });
    } catch (err) {
      submitBtn.disabled = !this.objectiveForm.valid;
      console.error(err);
      this.toastr.error("Unable to create or update Objective");
      throw err;
    }
  }

}
