import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Project, Survey } from "@inthraction/data-models";
import { MultiSeries, Series } from "@swimlane/ngx-charts";
import * as shape from "d3-shape";
import { EmployeeService, ProjectService, SurveyService } from "@inthraction/services";
import * as moment from "moment";

@Component({
  selector: "inthraction-project-score-chart",
  templateUrl: "./project-score-chart.component.html",
  styleUrls: ["./project-score-chart.component.scss"]
})
export class ProjectScoreChartComponent implements OnInit, DoCheck {

  @Input() project: Project;
  score: number;

  @Output() scoreChangeEvent = new EventEmitter<number>();
  @Output() selectEvent = new EventEmitter<ProjectScoreChartSelection>();

  chartData: MultiSeries = [];

  selectedColorScheme: string;
  view: any[];
  colorScheme: any;
  // colorSets: any;
  schemeType = "ordinal";
  // range: boolean = true;
  animations = true;
  showLegend = true;
  legendTitle = "Legend";
  legendPosition = "right";
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = false;
  showYAxisLabel = false;
  xAxisLabel = "Country";
  yAxisLabel = "GDP Per Capita";
  autoScale = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  timeline = true;
  showGridLines = true;
  curves = {
    "Basis": shape.curveBasis,
    "Basis Closed": shape.curveBasisClosed,
    "Bundle": shape.curveBundle.beta(1),
    "Cardinal": shape.curveCardinal,
    "Cardinal Closed": shape.curveCardinalClosed,
    "Catmull Rom": shape.curveCatmullRom,
    "Catmull Rom Closed": shape.curveCatmullRomClosed,
    "Linear": shape.curveLinear,
    "Linear Closed": shape.curveLinearClosed,
    "Monotone X": shape.curveMonotoneX,
    "Monotone Y": shape.curveMonotoneY,
    "Natural": shape.curveNatural,
    "Step": shape.curveStep,
    "Step After": shape.curveStepAfter,
    "Step Before": shape.curveStepBefore,
    "default": shape.curveLinear
  };
  curveType = "Linear";
  curve: any = this.curves[this.curveType];
  rangeFillOpacity = 0.15;
  roundDomains = false;
  tooltipDisabled = false;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;

  width = 700;
  height = 250;
  fitContainer = true;

  currentProjectID = null;

  constructor(
    private projectService: ProjectService,
    private surveyService: SurveyService,
    private employeeService: EmployeeService
  ) {
    this.setColorScheme("fire");
  }

  async ngDoCheck() {
    if (this.project.id !== this.currentProjectID) {
      await this.ngOnInit();
    }
  }

  async ngOnInit() {
    this.currentProjectID = this.project.id;
    const surveys = await this.surveyService.getSurveysByObjectIDMemoize(this.project.id);
    const processedSurveys = this.processSurveys(surveys);
    this.chartData = await this.generateChartData(processedSurveys);

    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }

  applyDimensions(): void {
    this.view = [this.width, this.height];
  }

  setColorScheme(name: string): void {
    this.selectedColorScheme = name;
    this.colorScheme = {
      name: "fire",
      selectable: true,
      group: "Ordinal",
      domain: [
        "#ff3d00", "#bf360c", "#ff8f00", "#ff6f00", "#ff5722", "#e65100", "#ffca28", "#ffab00"
      ]
    };
  }

  select(data: any): void {
    if (data.extra) {
      this.selectEvent.emit(data.extra);
    }
  }

  // TODO
  activate(data: any): void {
    // console.debug('Activate', JSON.parse(JSON.stringify(data)));
  }

  // TODO
  deactivate(data: any): void {
    // console.debug('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  private async generateChartData(processedSurveys: Map<string, Map<string, number[]>>): Promise<MultiSeries> {
    let results: MultiSeries = [];
    if (this.project && this.project.team) {
      const teamScoreMap: Map<string, number[]> = new Map<string, number[]>();

      for (const teamMemberID of this.project.team) {
        const employee = await this.employeeService.getEmployeeByIDMemoize(teamMemberID);
        const series: Series = {
          name: `${employee.firstName} ${employee.lastName}`,
          series: []
        };
        if (processedSurveys.has(employee.email)) {
          const surveys: { date: string, total: number }[] = [];
          for (const surveyMap of processedSurveys.get(employee.email)) {
            let sum = 0;
            for (const val of surveyMap[1]) {
              sum += SurveyService.getFivePointResponseScore(val);
            }

            surveys.push({ date: surveyMap[0], total: SurveyService.round((sum / surveyMap[1].length), 2) });
          }
          surveys.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));

          let totalScore = 0;
          let first = true;
          for (const survey of surveys) {

            if (first) {
              totalScore = survey.total;
              first = false;
            } else {
              totalScore = SurveyService.round((totalScore + survey.total) / 2, 2);
            }

            let teamTotals: number[];
            if (teamScoreMap.has(survey.date)) {
              teamTotals = teamScoreMap.get(survey.date);
            } else {
              teamTotals = [];
            }
            teamTotals.push(survey.total);
            teamScoreMap.set(survey.date, teamTotals);

            series.series.push({
              value: totalScore,
              name: moment(survey.date).toDate(),
              extra: {
                participantEmail: employee.email,
                surveyDate: survey.date,
                projectID: this.project.id
              } as ProjectScoreChartSelection
            });
          }
        }
        if (series.series.length > 0) {
          results.push(series);
        }
      }

      // Build team series
      if (teamScoreMap.size) {
        const series: Series = {
          name: `Project Team`,
          series: []
        };
        const surveys: { date: string, total: number }[] = [];
        for (const entry of teamScoreMap.entries()) {
          let sum = 0;
          for (const val of entry[1]) {
            sum += val;
          }
          surveys.push({ date: entry[0], total: SurveyService.round(sum / entry[1].length, 2) });
        }
        surveys.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
        let totalScore = 0;
        let first = true;
        for (const survey of surveys) {
          if (first) {
            totalScore = survey.total;
            first = false;
          } else {
            totalScore = SurveyService.round((totalScore + survey.total) / 2, 2);
          }
          series.series.push({
            value: totalScore,
            name: moment(survey.date).toDate()
          });
        }
        this.score = totalScore;
        this.scoreChangeEvent.emit(this.score);
        if (series.series.length > 0) {
          const newResults = [series];
          newResults.push(...results);
          results = newResults;
        }
      }

    }
    return results;
  }

  private processSurveys(surveys: Survey[]): Map<string, Map<string, number[]>> {
    const employeeSurveyScoreMap = new Map<string, Map<string, number[]>>();
    for (const survey of surveys) {
      const surveyDate = moment(survey.surveyDate).format("YYYY-MM-DD");
      if (survey.responseReceived) {
        if (!employeeSurveyScoreMap.has(survey.participantEmail)) {
          employeeSurveyScoreMap.set(survey.participantEmail, new Map<string, number[]>());
        }
        if (!employeeSurveyScoreMap.get(survey.participantEmail).has(surveyDate)) {
          employeeSurveyScoreMap.get(survey.participantEmail).set(surveyDate, []);
        }
        employeeSurveyScoreMap.get(survey.participantEmail).get(surveyDate).push(survey.surveyResponse);
      }
    }
    return employeeSurveyScoreMap;
  }
}

export interface ProjectScoreChartSelection {
  participantEmail: string;
  surveyDate: string;
  projectID: string;
}
