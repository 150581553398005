import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EChartsOption } from "echarts";
import { DEPARTMENT_TYPE_LABELS } from "@inthraction/labels";
import { EmployeeService, SurveyService } from "@inthraction/services";
import { Employee, EventSurvey } from "@inthraction/data-models";
import { EventSurveyResponseOptions, EventSurveyResponseScores } from "@inthraction/codes";
import { CallbackDataParams } from "echarts/types/dist/shared";
import { OptionDataItemObject, OptionDataValue } from "echarts/types/src/util/types";

@Component({
  selector: "inthraction-inthraction-graph",
  templateUrl: "./inthraction-graph.component.html",
  styleUrls: ["./inthraction-graph.component.scss"]
})
export class InthractionGraphComponent implements OnInit {

  constructor(
    private employeeService: EmployeeService,
    private surveyService: SurveyService
  ) {
    const reversedLegend = [];
    reversedLegend.push(...EventSurvey.SURVEY_RESPONSE_OPTIONS_DISPLAY.filter(s => s !== "Did Not Attend"));
    this.legend = reversedLegend.reverse();
    for (const name of this.legend) {
      this.categories.push({ name });
    }
  }

  @Input()
  employee: Employee;

  @Input()
  configuration: any;

  @Output()
  graphRenderedEmitter = new EventEmitter<boolean>();

  options: EChartsOption;

  readonly legend;
  private categories = [];
  private ratesOthers = false;

  private static initializeDataCategoryAndColor(seriesData: SeriesGraphData) {
    seriesData.category = 5;
    if (seriesData.itemStyle)
      seriesData.itemStyle.color = "red";
    if (seriesData.value >= EventSurveyResponseScores.DISRUPTED && seriesData.value < EventSurveyResponseScores.DISTRACTED) {
      seriesData.category = 4;
      if (seriesData.itemStyle)
        seriesData.itemStyle.color = "orange";
    } else if (seriesData.value >= EventSurveyResponseScores.DISTRACTED && seriesData.value < EventSurveyResponseScores.PARTICIPATED) {
      seriesData.category = 3;
      if (seriesData.itemStyle)
        seriesData.itemStyle.color = "gold";
    } else if (seriesData.value >= EventSurveyResponseScores.PARTICIPATED && seriesData.value < EventSurveyResponseScores.CONTRIBUTED) {
      seriesData.category = 2;
      if (seriesData.itemStyle)
        seriesData.itemStyle.color = "limegreen";
    } else if (seriesData.value >= EventSurveyResponseScores.CONTRIBUTED && seriesData.value < EventSurveyResponseScores.LEAD) {
      seriesData.category = 1;
      if (seriesData.itemStyle)
        seriesData.itemStyle.color = "darkgreen";
    } else if (seriesData.value >= EventSurveyResponseScores.LEAD) {
      seriesData.category = 0;
      if (seriesData.itemStyle)
        seriesData.itemStyle.color = "blue";
    }
  }

  private static getEventScoreFromResponse(response: EventSurveyResponseOptions): number {
    let score: number;
    switch (+response) {
      case EventSurveyResponseOptions.LEAD: {
        score = EventSurveyResponseScores.LEAD;
        break;
      }
      case EventSurveyResponseOptions.CONTRIBUTED: {
        score = EventSurveyResponseScores.CONTRIBUTED;
        break;
      }
      case EventSurveyResponseOptions.PARTICIPATED: {
        score = EventSurveyResponseScores.PARTICIPATED;
        break;
      }
      case EventSurveyResponseOptions.DISTRACTED: {
        score = EventSurveyResponseScores.DISTRACTED;
        break;
      }
      case EventSurveyResponseOptions.DISRUPTED: {
        score = EventSurveyResponseScores.DISRUPTED;
        break;
      }
      case EventSurveyResponseOptions.PROBLEM: {
        score = EventSurveyResponseScores.PROBLEM;
        break;
      }
    }
    if (Number.isNaN(score)) {
      score = 0;
    }
    return score;
  }

  async ngOnInit() {
    if (this.configuration && this.configuration.ratesOthers) {
      this.ratesOthers = true;
    }
    this.categories.push({ name: this.employee.firstName + " " + this.employee.lastName });
    this.options = await this.generateGraphOptions(this.employee, this.categories, this.legend, this.ratesOthers);
  }

  private async generateGraphOptions(employee: Employee, categories: any[], legend: any[], ratesOther: boolean) {
    const options: EChartsOption = {
      color: ["red", "orange", "gold", "limegreen", "darkgreen", "blue"].reverse(),
      animation: false,
      title: {
        text: employee.firstName + " " + employee.lastName,
        subtext: "" + (this.ratesOthers ? "Rates Others" : "intHRactions"),
        top: "top",
        left: "left"
      },
      tooltip: {
        // @ts-ignore
        formatter: (params, ticket, callback) => {
          let param: CallbackDataParams;
          if (params && Array.isArray(params)) {
            param = params[0];
          } else {
            param = (params as CallbackDataParams);
          }
          let res = param.name;
          const data: SeriesGraphData = param.data as SeriesGraphData;
          res += `<br/> intHRactions : ${data.count}`;
          res += `<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>`;
          res += `Rating : ${data?.value ? data?.value : 0}`;
          return res;
        }
      },
      legend: [
        {
          left: "right",
          orient: "vertical",
          data: legend
        }
      ],
      series: []
    };

    const series = {
      name: employee.firstName + " " + employee.lastName,
      id: employee.id,
      type: "graph",
      layout: "force",
      data: [],
      links: [],
      categories,
      roam: true,
      label: {
        position: "right"
      },
      force: {
        repulsion: 1000
      }
    };

    let surveys;

    if (ratesOther) {
      surveys = await this.surveyService.getEventSurveysByRespondentMemoize(employee.email);
    } else {
      surveys = await this.surveyService.getEventSurveysByParticipantMemoize(employee.email);
    }


    let surveyCount = 0;

    const departmentResponseMap: Map<string, any> = new Map<string, any>();
    const responseMap: Map<string, any> = new Map<string, any>();
    for (const survey of surveys) {
      if (!survey.responseReceived || !survey.respondentAttended || !survey.surveyResponse || survey.surveyResponse <= 0) {
        continue;
      }
      surveyCount++;

      let email;
      if (ratesOther) {
        email = survey.participantEmail;
      } else {
        email = survey.respondentEmail;
      }
      let data: SeriesGraphData;
      if (responseMap.has(email)) {
        data = responseMap.get(email);
      } else {
        const peerEmployee = await this.employeeService.getEmployeeByEmailMemoize(email);
        if (peerEmployee) {
          data = {
            sum: 0,
            count: 0,
            category: 0,
            draggable: true,
            id: peerEmployee.id,
            department: peerEmployee.department,
            name: peerEmployee.firstName + " " + peerEmployee.lastName,
            symbolSize: 10,
            value: 1,
            x: null,
            y: null
          };
        } else {
          console.debug("No user found for email: " + email);
          continue;
        }
      }

      let departmentData: SeriesGraphData;
      if (departmentResponseMap.has(data.department)) {
        departmentData = departmentResponseMap.get(data.department);
      } else {
        departmentData = {
          sum: 0,
          count: 0,
          category: 0,
          draggable: true,
          id: data.department,
          name: DEPARTMENT_TYPE_LABELS[data.department],
          symbolSize: 10,
          value: 1,
          x: null,
          y: null
        };
      }

      const newScore = InthractionGraphComponent.getEventScoreFromResponse(survey.surveyResponse);
      data.sum = data.sum + newScore;
      data.count++;
      data.value = SurveyService.round(data.sum / data.count, 2);
      data.symbolSize = data.count;

      departmentData.sum = departmentData.sum + newScore;
      departmentData.count++;
      departmentData.value = SurveyService.round(departmentData.sum / departmentData.count, 2);
      departmentData.symbolSize = departmentData.count;

      InthractionGraphComponent.initializeDataCategoryAndColor(data);
      InthractionGraphComponent.initializeDataCategoryAndColor(departmentData);

      responseMap.set(email, data);
      departmentResponseMap.set(data.department, departmentData);
    }

    let linkID = 0;
    let totalCount = 0;
    let scoreSum = 0;
    let scoreCount = 0;

    for (const d of departmentResponseMap.values()) {
      series.data.push(d);
      series.links.push({
        id: "" + linkID,
        lineStyle: {},
        name: null,
        source: d.id,
        target: series.id
      });
      linkID++;
    }

    for (const d of responseMap.values()) {
      series.data.push(d);
      series.links.push({
        id: "" + linkID,
        lineStyle: {},
        name: null,
        source: d.id,
        // target: series.id
        target: d.department
      });
      linkID++;
      totalCount = totalCount + d.count;
      scoreSum = scoreSum + d.value;
      scoreCount++;
    }

    const participantData: SeriesGraphData = {
      sum: 0,
      count: surveyCount,
      category: 0,
      draggable: true,
      id: series.id,
      itemStyle: { color: "red" },
      name: series.name,
      symbolSize: totalCount,
      value: SurveyService.round(scoreSum / scoreCount, 2),
      x: null,
      y: null
    };
    InthractionGraphComponent.initializeDataCategoryAndColor(participantData);

    participantData.category = categories.length - 1;

    series.data.push(participantData);


    if (totalCount > 0) {
      for (const data of series.data) {
        data.symbolSize = Math.round((data.symbolSize / totalCount) * 100);
        if (data.symbolSize < 10) {
          data.symbolSize = 10;
        }
      }
    } else {
      for (const data of series.data) {
        data.symbolSize = 100;
      }
    }

    (options.series as any[]).push(series);
    if (series.data.length > 1) {
      this.graphRenderedEmitter.emit(true);
      return options;
    } else {
      this.graphRenderedEmitter.emit(false);
      return null;
    }
  }

  async resetChart() {
    if (this.options) {
      this.options = null;
      this.options = await this.generateGraphOptions(this.employee, this.categories, this.legend, this.ratesOthers);
    }
  }
}


interface SeriesGraphData extends OptionDataItemObject<OptionDataValue> {
  draggable: boolean;
  symbolSize: number;
  count: number;
  name: string;
  x?: number | null;
  y?: number | null;
  itemStyle?: { color: string } | null;
  sum?: number;
  id: string;
  department?: string | null;
  category: number;
  value: number;
}
