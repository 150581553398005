<inthraction-page-header pageTitle="Edit Objectives" [addBtnDisabled]="!initialized" addBtnTitle="Add New Objective Domain" (addBtnClickEvent)="addNewObjectiveDomain()"></inthraction-page-header>
<div *ngIf="initialized" class="system-objective-editor-container">
  <mat-tab-group [selectedIndex]="0" (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" class="system-objective-configuration-tab-group">
    <ng-container *ngFor="let domain of objectiveDomains">
      <mat-tab>
        <ng-template mat-tab-label>
          <span title="{{domain.display}}: {{getObjectiveTitles(domain.id)}}">{{domain.display}}</span>
        </ng-template>
        <inthraction-page-header pageTitle="{{domain.display}}" addBtnTitle="Add New Objective" (addBtnClickEvent)="addNewObjective(domain)">
          <button mat-icon-button title="Edit Objective Domain" (click)="editObjectiveDomain(domain)"><mat-icon>edit</mat-icon></button>
        </inthraction-page-header>
        <div class="editObjectivesContainer">
          <ng-container *ngFor="let editObjectiveDTO of objectiveDomainsMap.get(domain.id)">
            <mat-card appearance="outlined" *ngIf="objectivesMap.has(editObjectiveDTO.objectiveID)">
              <mat-card-header>
                <div class="title-wrapper">
                  <mat-card-title>{{objectivesMap.get(editObjectiveDTO.objectiveID).display}}</mat-card-title>
                </div>
                <div class="objectiveSubtitleWrapper">
                  <mat-card-subtitle>{{objectivesMap.get(editObjectiveDTO.objectiveID).definition}}</mat-card-subtitle>
                </div>
              </mat-card-header>
              <mat-card-content>
                <mat-accordion>
                  <mat-expansion-panel *ngIf="objectivesMap.get(editObjectiveDTO.objectiveID).observables && objectivesMap.get(editObjectiveDTO.objectiveID).observables.length > 0"
                                       expanded="true">
                    <mat-expansion-panel-header>
                      <mat-panel-title>Observables</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul class="objectiveObservables">
                      <li
                        *ngFor="let observable of objectivesMap.get(editObjectiveDTO.objectiveID).observables">{{observable}}</li>
                    </ul>
                  </mat-expansion-panel>
                  <mat-expansion-panel
                    *ngIf="objectivesMap.get(editObjectiveDTO.objectiveID).hints && objectivesMap.get(editObjectiveDTO.objectiveID).hints.length > 0">
                    <mat-expansion-panel-header>
                      <mat-panel-title>Manager Hints</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul class="objectiveHints">
                      <li *ngFor="let hint of objectivesMap.get(editObjectiveDTO.objectiveID).hints">{{hint}}</li>
                    </ul>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button (click)="deleteObjective(editObjectiveDTO.objectiveID)" *ngIf="!editObjectiveDTO.isInUse" type="submit" title="Delete">Delete</button>
                <div class="spacer"></div>
                <button mat-icon-button (click)="filterOrganization(objectivesMap.get(editObjectiveDTO.objectiveID))" title="Filter Organizations">
                  <mat-icon>filter_list</mat-icon>
                </button>
                <button mat-icon-button (click)="editObjective(objectivesMap.get(editObjectiveDTO.objectiveID))" title="Edit">
                  <mat-icon>edit</mat-icon>
                </button>
              </mat-card-actions>
            </mat-card>
          </ng-container>
        </div>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>

<inthraction-loading className="system-objective-editor-container" [initDone]="initialized"></inthraction-loading>
