import { Component, Inject, OnInit } from "@angular/core";
import { Employee, OrganizationShoutOutType, Survey } from "@inthraction/data-models";
import { EmployeeService, InspHRationCounterType, ShoutOutService, SurveyService } from "@inthraction/services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";

@Component({
  selector: "inthraction-insphration-result-list-dialog",
  templateUrl: "./insphration-result-list-dialog.component.html",
  styleUrls: ["./insphration-result-list-dialog.component.scss"]
})
export class InsphrationResultListDialogComponent implements OnInit {

  readonly orgInspHRation: OrganizationShoutOutType;
  readonly employee: Employee;
  receivedInspHRations: Survey[];
  respondentMap: Map<string, Employee> = new Map<string, Employee>();

  constructor(
    private dialogRef: MatDialogRef<InsphrationResultListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: InspHRationResultsListData,
    private surveyService: SurveyService,
    private employeeService: EmployeeService
  ) {
    this.employee = data.employee;
    this.orgInspHRation = data.orgInspHRation;
  }

  async ngOnInit() {
    const group = ShoutOutService.getInspHRationGroup(this.data.type);
    const receivedInspHRations = (await this.surveyService.getReceivedInspHRations(this.employee.email, this.orgInspHRation.id)).filter(survey =>
      this.getInspHRationGroupDate(this.data.type, survey.responseReceivedDate) === group);
    for (const survey of receivedInspHRations) {
      if (!this.respondentMap.has(survey.respondentEmail)) {
        this.respondentMap.set(survey.respondentEmail, await this.employeeService.getEmployeeByEmailMemoize(survey.respondentEmail));
      }
    }
    this.receivedInspHRations = receivedInspHRations.sort((a, b) => b.responseReceivedDate.localeCompare(a.responseReceivedDate));
  }

  private getInspHRationGroupDate(type: InspHRationCounterType, date: string) {
    let group: string;
    switch (type) {
      case InspHRationCounterType.WEEK: {
        group = moment(date).format("YYYY-WW");
        break;
      }
      case InspHRationCounterType.MONTH: {
        group = moment(date).format("YYYY-MM");
        break;
      }
      case InspHRationCounterType.QTR: {
        group = moment(date).format("YYYY-Q");
        break;
      }
      case InspHRationCounterType.YEAR: {
        group = moment(date).format("YYYY");
        break;
      }
    }
    return group;
  }

}

export interface InspHRationResultsListData {
  orgInspHRation: OrganizationShoutOutType;
  type: InspHRationCounterType;
  employee: Employee;
}
