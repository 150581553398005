import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Employee, ObjectiveAssignment, ReadinessHistory, TotalEmployeeObjectiveScore } from "@inthraction/data-models";
import { DEPARTMENT_TYPE, round } from "@inthraction/codes";
import { EmployeeService, ObjectiveService } from "@inthraction/services";
import moment from "moment";
import { DEPARTMENT_TYPE_LABELS, OBJECTIVE_TYPE_LABELS, READINESS_TYPE_LABELS } from "@inthraction/labels";
import { MatSort } from "@angular/material/sort";
import { animate, state, style, transition, trigger } from "@angular/animations";
import {
  EmployeeObjectiveAssignmentDetailRecord
} from "./employee-objective-assignments-detail/EmployeeObjectiveAssignmentRecord";
import { saveAs } from "file-saver";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "inthraction-employee-objective-assignments",
  templateUrl: "./employee-objective-assignments.component.html",
  styleUrls: ["./employee-objective-assignments.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0", display: "none" })),
      state("expanded", style({ height: "*", display: "table-row" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
export class EmployeeObjectiveAssignmentsComponent implements OnInit, AfterViewInit, OnDestroy {

  static organizationIDParameterName = "organization-id";

  displayedColumns: string[] = ["department", "manager", "employee", "totalObjectives", "totalCurrent", "totalFuture", "currentScore", "futureScore", "lastOneOnOne", "currentReadiness", "view"];
  tableData: MatTableDataSource<TableData> = new MatTableDataSource<TableData>([]);
  expandedElement: TableData | null;
  noDataFound = false;

  @ViewChild(MatSort) sort: MatSort;
  filterExample: string;
  private managerView: boolean = true;
  private hrView: boolean = true;
  private consultantView: boolean = true;
  assignedCount: number = 0;
  private subscriptions: Subscription[] = [];
  private organizationID: string;

  constructor(
    private employeeService: EmployeeService,
    private objectiveService: ObjectiveService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone
  ) {
    switch (this.route.snapshot.parent.url[0].path) {

      case "hr" : {
        this.managerView = false;
        this.hrView = true;
        this.consultantView = false;
        break;
      }

      case "manager": {
        this.managerView = true;
        this.hrView = false;
        this.consultantView = false;
        break;
      }

      case "consultant": { // Consultant View
        this.managerView = false;
        this.hrView = false;
        this.consultantView = true;
        break;
      }

      default: { // How did you get here?
        this.managerView = false;
        this.hrView = false;
        this.consultantView = false;
        //All false should route back to My Dashboard
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit() {
    if(!(this.consultantView || this.managerView || this.hrView)){
      console.error("Objective Assignments unable to determine view")
      await this.ngZone.run(async () => {
        await this.router.navigate([`dashboard`]);
      });
    } else if (this.consultantView) {
      this.subscriptions.push(this.route.paramMap.subscribe(async queryParams => {
        if (queryParams.has(EmployeeObjectiveAssignmentsComponent.organizationIDParameterName)) {
          this.organizationID = queryParams.get(EmployeeObjectiveAssignmentsComponent.organizationIDParameterName);
          await this.initializeData(this.organizationID);
        } else {
          console.error("Objective Assignments missing Organization ID")
          await this.ngZone.run(async () => {
            await this.router.navigate([`dashboard`]);
          });
        }
      }));
    } else {
      this.initializeData();
    }
  }

  async initializeData(organizationID?: string) {
    const securityUser = await this.employeeService.getCurrentEmployee();
    const data: TableData[] = [];
    let employees, readyness, YTDScores, objectiveAssignments;
    if (this.consultantView) {
      employees = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:false, organizationID, memoize:true});
      readyness = await this.employeeService.getEmployeeReadinessHistoryForOrganization(organizationID);
      YTDScores = await this.employeeService.getEmployeeYTDObjectiveScoresForOrganization(organizationID);
      objectiveAssignments = await this.objectiveService.getEmployeeObjectiveAssignmentsForOrganization(organizationID);
    } else {
      employees = await this.employeeService.getEmployeesForOrganizationByOrganization({memoize:true});
      readyness = await this.employeeService.getEmployeeReadinessHistoryForOrganization();
      YTDScores = await this.employeeService.getEmployeeYTDObjectiveScoresForOrganization();
      objectiveAssignments = await this.objectiveService.getEmployeeObjectiveAssignmentsForOrganization();
    }


    if (this.managerView) {
      const filteredEmployees: Employee[] = [];
      let directSub = employees.filter(e => e.managerID === securityUser.id);
      filteredEmployees.push(...directSub);
      let prevLength = 0;
      do {
        prevLength = filteredEmployees.length;
        let sub = employees.filter(e => {
          const ids = filteredEmployees.map(e => e.id);
          return ids.includes(e.managerID) && !ids.includes(e.id);
        });
        filteredEmployees.push(...sub);
      } while (filteredEmployees.length != prevLength);

      filteredEmployees.push(securityUser);

      employees = filteredEmployees;
    }

    if (this.consultantView) {
      let favorites = [];
      const consultantFavorites = await this.employeeService.getConsultantFavorites(organizationID);
      if (consultantFavorites) {
        favorites = consultantFavorites.favorites;
      }
      const filteredEmployees: Employee[] = [];
      filteredEmployees.push(...employees.filter(e => favorites.includes(e.id)));
      employees = filteredEmployees;
    }


    for (let employee of employees) {

      let manager: Employee;
      if (employee.managerID) {
        manager = employees.find(emp => emp.id === employee.managerID);
      }
      const tableRecord = new EmployeeObjectiveAssignmentRecord(employee, objectiveAssignments.filter(o => o.employeeId === employee.id), YTDScores.filter(score => score.employeeID === employee.id), manager, readyness.find(ready => ready.id === employee.readiness));
      data.push({
        department: tableRecord.department,
        managerName: tableRecord.managerName,
        employeeName: tableRecord.employeeName,
        totalObjectives: tableRecord.totalObjectives,
        totalCurrent: tableRecord.totalCurrent,
        totalFuture: tableRecord.totalFuture,
        lastOneOnOneDate: tableRecord.lastOneOnOneDate,
        currentReadiness: tableRecord.currentReadiness,
        objectiveAssignmentData: tableRecord.objectiveAssignmentData,
        currentScore: tableRecord.currentScore,
        futureScore: tableRecord.futureScore,
        employee: tableRecord.employee
      } as TableData);
      if (tableRecord.totalObjectives) {
        this.assignedCount++;
      }
      if (!this.filterExample) {
        this.filterExample = tableRecord.department;
      }
    }

    this.tableData.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "lastOneOnOne":
          return item.lastOneOnOneDate ? item.lastOneOnOneDate : "";
        case "employee":
          return item.employeeName;
        case "manager":
          return item.managerName;
        default:
          return item[property];
      }
    };
    this.tableData.data = data;
    this.noDataFound = this.tableData.data.length <= 0;
  }

  ngAfterViewInit() {
    if (this.tableData) {
      this.tableData.sort = this.sort;
    }
  }

  download() {
    const csvData = [];
    const csvHeaders = [
      "Department",
      "Manager",
      "User",
      "Total Objectives",
      "Total Current",
      "Total Future",
      "Current Score",
      "Future Score",
      "Last 1-1",
      "Current Readiness",
      "Domain",
      "Objective",
      "Objective Type",
      "Survey Type",
      "Start Date",
      "End Date",
      "Score",
      "Responses"
    ];

    csvData.push(csvHeaders);
    for (const record of this.tableData.data) {
      if (record.objectiveAssignmentData?.data?.length) {
        for (const detail of record.objectiveAssignmentData.data) {
          const rowData = [
            record.department || "",
            `"${record.managerName}"` || "",
            `"${record.employeeName}"` || "",
            record.totalObjectives || 0,
            record.totalCurrent || 0,
            record.totalFuture || 0,
            record.currentScore || "",
            record.futureScore || "",
            record.lastOneOnOneDate ? moment(record.lastOneOnOneDate, "YYYY-MM-DD").format("l") : "",
            record.currentReadiness || "",
            detail.domain || "",
            detail.objective || "",
            detail.objectiveType || "",
            detail.surveyType || "",
            detail.start?.format("l") || "",
            detail.end?.format("l") || "",
            detail.score || "",
            detail.responseCount || ""
          ];
          csvData.push(rowData);
        }
      } else {
        const rowData = [
          record.department || "",
          `"${record.managerName}"` || "",
          `"${record.employeeName}"` || "",
          record.totalObjectives || 0,
          record.totalCurrent || 0,
          record.totalFuture || 0,
          record.currentScore || "",
          record.futureScore || "",
          record.lastOneOnOneDate ? moment(record.lastOneOnOneDate, "YYYY-MM-DD").format("l") : "",
          record.currentReadiness || "",
          "", // Domain
          "", // Objective
          "", // Objective Type
          "", // Survey Type
          "", // Start
          "", // End
          "", // Score
          "" // Responses
        ];
        csvData.push(rowData);
      }
    }
    let csvArray = csvData.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, `Employee_Objective_Assignments-${moment().format("YYYYMMDDHHmmss")}.csv`.replace(" ", "_"));
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();

    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  async view(employee: Employee) {
    if (this.managerView) {
      await this.router.navigate([`/manager/${employee.id}/workforce`]);
    }
    if (this.consultantView) {
      await this.router.navigate([`/consultant/${this.organizationID}/workforce/${employee.id}/edit`]);
    } else {
      await this.router.navigate([`/hr/workforce/${employee.id}/edit`]);
    }
  }

}

class EmployeeObjectiveAssignmentRecord implements TableData {

  manager?: Employee;
  employee: Employee;
  objectives: ObjectiveAssignment[];
  totalFuture: number = 0;
  totalCurrent: number = 0;
  futureScore: number;
  currentScore: number;
  objectiveAssignmentData: MatTableDataSource<EmployeeObjectiveAssignmentDetailRecord> = new MatTableDataSource<EmployeeObjectiveAssignmentDetailRecord>([]);

  private futureObjectives: ObjectiveAssignment[] = [];
  private currentObjectives: ObjectiveAssignment[] = [];
  private readinessHistory: ReadinessHistory;
  private ytdScores: TotalEmployeeObjectiveScore[];

  constructor(employee: Employee, objectives: ObjectiveAssignment[], ytdScores: TotalEmployeeObjectiveScore[], manager?: Employee, readinessHistory?: ReadinessHistory) {
    this.employee = employee;
    this.manager = manager;
    this.objectives = objectives;
    this.readinessHistory = readinessHistory;
    this.ytdScores = ytdScores;

    for (const objective of objectives) {
      if (objective.priority?.key == "FUTURE") {
        this.totalFuture++;
        this.futureObjectives.push(objective);
      } else {
        this.totalCurrent++;
        this.currentObjectives.push(objective);
      }
    }

    if (this.totalCurrent) {
      this.currentScore = this.initializeScore(this.currentObjectives, ytdScores);
    }
    if (this.totalFuture) {
      this.futureScore = this.initializeScore(this.futureObjectives, ytdScores);
    }

    const data: EmployeeObjectiveAssignmentDetailRecord[] = [];
    if (this.objectives.length) {
      for (let objective of this.objectives) {
        const detail = this.ytdScores.find(s => s.scoreID === objective.id);
        data.push({
          objectiveAssignmentId: objective.id,
          domain: objective.orgObjective?.objective.domain.display,
          objective: objective.orgObjective?.objective.display,
          objectiveType: objective.priority?.display || "Current",
          surveyType: OBJECTIVE_TYPE_LABELS[objective.objectiveType],
          start: objective.startDate ? moment(objective.startDate) : moment(objective.createdAt),
          end: objective.endDate ? moment(objective.endDate) : null,
          score: detail?.score,
          responseCount: (objective.priority?.key == "FUTURE" ? detail?.employeeTotalObjectiveScoreDetails?.futureObjectiveResponses.length : detail?.employeeTotalObjectiveScoreDetails?.responses.length) | 0
        } as EmployeeObjectiveAssignmentDetailRecord);
      }
    }
    this.objectiveAssignmentData.data = data;
  }

  private initializeScore(objectives: ObjectiveAssignment[], ytdScores: TotalEmployeeObjectiveScore[]) {
    if (objectives.length && ytdScores.length) {
      const scoredObjectives = ytdScores.map(s => s.scoreID).filter(onlyUnique);
      let currentScoreSum: number = 0;
      let count = 0;
      for (let objective of objectives) {
        if (scoredObjectives.includes(objective.id)) {
          count++;
          const score = ytdScores.find(score => score.scoreID == objective.id);
          currentScoreSum = currentScoreSum + getScore(score);
        }
      }
      if (count) {
        return round(currentScoreSum / count, 2);
      }
    }
  }

  get department(): DEPARTMENT_TYPE | string {
    return DEPARTMENT_TYPE_LABELS[this.employee.department];
  }

  get managerName(): string {
    return this.manager ? `${this.manager.lastName}, ${this.manager.firstName}` : "";
  }

  get employeeName(): string {
    return `${this.employee.lastName}, ${this.employee.firstName}`;
  }

  get totalObjectives(): number {
    return this.objectives.length;
  }

  get lastOneOnOneDate(): string {
    return this.employee.lastOneOnOne;
  }

  get currentReadiness(): string {
    return READINESS_TYPE_LABELS[this.readinessHistory?.readiness];
  }

}

interface TableData {
  department: string;
  managerName?: string;
  employeeName: string;
  totalObjectives: number;
  lastOneOnOneDate: string;
  currentReadiness: string;
  totalFuture: number;
  totalCurrent: number;
  futureScore: number;
  currentScore: number;
  employee: Employee;
  objectiveAssignmentData: MatTableDataSource<EmployeeObjectiveAssignmentDetailRecord>;
}

function getScore(employeeScore: TotalEmployeeObjectiveScore) {
  if (employeeScore?.employeeTotalObjectiveScoreDetails?.responses?.length) {
    return round(employeeScore.employeeTotalObjectiveScoreDetails.responses.reduce((a, b) => a + b.score, 0) / employeeScore.employeeTotalObjectiveScoreDetails.responses.length, 2);
  }
  return (employeeScore ? employeeScore.score : 0);
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
