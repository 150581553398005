import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { Employee, EmployeeImageInterface } from "@inthraction/data-models";
import { OrganizationConfigurationCodes } from "@inthraction/codes";

@Component({
  selector: "inthraction-mini-employee-card",
  templateUrl: "./mini-employee-card.component.html",
  styleUrls: ["./mini-employee-card.component.scss"]
})
export class MiniEmployeeCardComponent implements OnInit {

  @Output() clickEvent = new EventEmitter();
  @Output() removeEvent = new EventEmitter();
  @Input() employee: Employee;
  @Input() options: MiniEmployeeCardOptions = {};
  scoreColor: string;
  employeeImage: EmployeeImageInterface;

  constructor(
    private employeeService: EmployeeService,
    private organizationService: OrganizationService
  ) {
  }

  async ngOnInit() {
    if (this.options.showScoreColorIndicator) {
      const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION, this.employee.orgId);
      if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
        this.scoreColor = EmployeeService.getScoreColor(await this.employeeService.getEmployeeMTDTotalScoreByEmployeeIDMemoize(this.employee?.id), 6);
      } else {
        this.scoreColor = EmployeeService.getScoreColor(await this.employeeService.getEmployeeYTDTotalScoreByEmployeeIDMemoize(this.employee?.id), 6);
      }
    }
    this.employeeImage = await this.employeeService.getEmployeeImageMemoize(this.employee?.id, this.employee?.orgId);
  }

}

export interface MiniEmployeeCardOptions {
  clickable?: boolean;
  showScoreColorIndicator?: boolean;
  showRemoveButton?: boolean;
}
