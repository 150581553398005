import {
  ExternalSurveyDataMapper,
  ObjectiveDetailsDataMapper,
  ParticipantDataMapper,
  SurveyDataMapper
} from "@inthraction/data-mappers";
import { SurveyStatus } from "@inthraction/codes";


export class ExternalSurvey implements ExternalSurveyDataMapper {
  id: string;
  __typename = "ExternalSurvey";
  surveyType: string;
  surveyDate?: string;
  externalRespondentID: string;
  participant: ParticipantDataMapper;
  objectiveDetails?: ObjectiveDetailsDataMapper;
  surveyResponse?: number;
  feedback?: string;
  responseReceived?: boolean;
  responseReceivedDate?: string;
  respondentAttended?: boolean;
  status?: string | SurveyStatus;
  createdAt?: string;
  updatedAt?: string;
}

export class Survey implements SurveyDataMapper {
  id: string;
  __typename = "Survey";
  objectID: string;
  surveyType: string;
  surveyDate?: string;
  respondentEmail?: string;
  participantEmail?: string;
  surveyResponse?: number;
  feedback?: string;
  responseReceived?: boolean;
  responseReceivedDate?: string;
  respondentAttended?: boolean;
  status?: string | SurveyStatus;
  createdAt?: string;
  updatedAt?: string;
}
