import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Employee,
  EmployeeImageInterface,
  EmployeeImpl,
  OrganizationShoutOutType,
  Survey
} from "@inthraction/data-models";
import { SurveyStatus } from "@inthraction/codes";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EmployeeService, ShoutOutService, SurveyService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import { Observable, Subscription } from "rxjs";
import * as moment from "moment";
import { map, startWith } from "rxjs/operators";

const delay = ms => new Promise(res => setTimeout(res, ms));

@Component({
  selector: "inthraction-insphration-survey",
  templateUrl: "./insphration-survey.component.html",
  styleUrls: ["./insphration-survey.component.scss"]
})
export class InsphrationSurveyComponent implements OnInit, OnDestroy {

  static surveyIDParameterName = "survey-id";
  organizationInspHRations: OrganizationShoutOutType[];
  readonly errorMessage = "Please try again.";
  filteredEmails: Observable<string[]>;
  survey: Survey;
  private surveyID: string;
  private employee: EmployeeImpl;
  participant: Employee;
  participantImage: EmployeeImageInterface;
  private routeParamMapSubscription: Subscription;
  private emails: string[];
  private participantEmail = new FormControl("", [Validators.required, Validators.email]);
  surveyForm = new FormGroup(
    {
      participantEmail: this.participantEmail,
      isValidEmail: new FormControl("", [Validators.requiredTrue]),
      surveyValue: new FormControl("", [Validators.required]),
      feedback: new FormControl("", [Validators.required])
    });
  private processing = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surveyService: SurveyService,
    private employeeService: EmployeeService,
    private shoutOutService: ShoutOutService,
    private toastr: ToastrService
  ) {
  }

  async ngOnInit() {
    this.employee = new EmployeeImpl(await this.employeeService.getCurrentEmployee());
    this.surveyID = this.route.snapshot.paramMap.get(InsphrationSurveyComponent.surveyIDParameterName);
    this.surveyForm.reset();
    this.organizationInspHRations = await this.shoutOutService.getActiveOrganizationShoutOuts();

    const employees = await this.employeeService.getEmployeesForOrganizationByOrganization({memoize:true});
    this.emails = employees.map(emp => emp.email);
    this.filteredEmails = this.participantEmail.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );

    this.survey = await this.loadSurvey(this.surveyID);
    if (this.survey) {
      this.routeParamMapSubscription = this.route.paramMap.subscribe(async queryParams => {
        this.surveyID = queryParams.get(InsphrationSurveyComponent.surveyIDParameterName);
        this.survey = await this.loadSurvey(this.surveyID);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.routeParamMapSubscription) {
      this.routeParamMapSubscription.unsubscribe();
    }
  }

  async submitSurvey(formValue: any) {
    if (this.surveyForm.valid) {
      // Can't submit a inspHRation for yourself
      if (formValue.participantEmail === this.employee.email) {
        this.toastr.error("Nominee must not be yourself.");
        return;
      } else {
        await this.surveyService.updateInspHRationSurvey({
          id: this.survey.id,
          participantEmail: formValue.participantEmail,
          feedback: formValue.feedback,
          objectID: formValue.surveyValue.id,
          responseReceived: true,
          surveyResponse: 1,
          responseReceivedDate: moment().toISOString(),
          updatedAt: moment().toISOString()
        });
        this.toastr.success("Thank you for your response", "inspHRation Received");
        this.router.navigate(["/dashboard"]);
      }
    } else {
      this.toastr.error(this.errorMessage);
    }
  }

  async onSearchClick() {
    this.processing = true;
    const emailSearchFC = this.surveyForm.get("participantEmail");
    if (emailSearchFC) {
      const employee = await this.employeeService.getEmployeeByEmailMemoize(emailSearchFC.value.toLowerCase());
      if (employee) {
        if (employee.id !== this.employee.id) {
          this.participant = employee;
          this.participantImage = await this.employeeService.getEmployeeImageMemoize(employee.id, employee.orgId);
          // @ts-ignore
          this.surveyForm.get("isValidEmail").setValue(true);
        } else {
          this.participant = employee;
          this.participantImage = await this.employeeService.getEmployeeImageMemoize(employee.id, employee.orgId);
          // @ts-ignore
          this.surveyForm.get("isValidEmail").setValue(false);
          this.surveyForm.get("participantEmail").setErrors({ notSelf: true });
        }
      } else {
        this.participant = undefined;
        this.participantImage = undefined;
        // @ts-ignore
        this.surveyForm.get("isValidEmail").setValue(false);
        this.surveyForm.get("participantEmail").setErrors({ notfound: true });
      }
    } else {
      this.participant = undefined;
      this.participantImage = undefined;
      // @ts-ignore
      this.surveyForm.get("isValidEmail").setValue(false);
    }
    this.surveyForm.get("participantEmail").markAsDirty();
    this.surveyForm.get("isValidEmail").markAsDirty();
    this.surveyForm.updateValueAndValidity();
    this.processing = false;
  }

  async searchEmployee(employee: Employee) {
    this.surveyForm.get("participantEmail").setValue(employee.email);
    await this.onSearchClick();
  }

  async onSuggestSearchClick(email: string) {
    let i = 1;
    while (this.processing && 1 < 9999) {
      await delay(i++);
    }
    this.surveyForm.get("participantEmail").setValue(email);
    await this.onSearchClick();
  }

  public hasFormError = (errorName: string) => this.surveyForm.hasError(errorName);

  public hasError = (controlName: string, errorName?: string) => {
    const hasError = errorName ? this.surveyForm.controls[controlName].hasError(errorName) : !(!this.surveyForm.controls[controlName].errors);
    return hasError;
  };

  onCancelClick() {
    this.toastr.warning("Canceled");
    this.router.navigate(["/dashboard"]);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.emails.filter(option => option.toLowerCase().includes(filterValue));
  }

  private async loadSurvey(surveyID: string): Promise<Survey> {
    const survey = await this.surveyService.getSurveyByID(surveyID);
    if (!survey || survey.status === SurveyStatus.DELETED) {
      // Survey must exist
      this.toastr.error("Requested survey does not exist");
      // TODO Navigate to Next Survey if it exists instead of back to Employee Dashboard
      this.router.navigate(["/dashboard"]);
      return null;
    } else if (survey.status === SurveyStatus.MISSED) {
      this.toastr.error("Sorry, it is too late to respond to this survey.");
      // TODO Navigate to Next Survey if it exists instead of back to Employee Dashboard
      this.router.navigate(["/dashboard"]);
      return null;
    } else if (survey.respondentEmail !== this.employee.email) {
      // You can't respond to someone else's survey
      this.toastr.error("Requested survey does not exist");
      // TODO Navigate to Next Survey if it exists instead of back to Employee Dashboard
      this.router.navigate(["/dashboard"]);
      return null;
    } else if (survey.responseReceived) {
      // You can't respond more than one time
      this.toastr.warning("A response for this survey has already been recorded");
      // TODO Navigate to Next Survey if it exists instead of back to Employee Dashboard
      this.surveyService.clearMemoizedInspHRationSurveys(this.employee.email);
      this.router.navigate(["/dashboard"]);
      return null;
    }
    return survey;
  }

}
