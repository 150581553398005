import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { NgxEchartsModule } from "ngx-echarts";
import { init } from "echarts";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ManagerWorkforceComponent } from "./pages/manager/manager-workforce/manager-workforce.component";
import { EmployeeDashboardComponent } from "./pages/dashboard/employee-dashboard/employee-dashboard.component";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCommonModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatGridListModule } from "@angular/material/grid-list";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatExpansionModule } from "@angular/material/expansion";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FooterComponent } from "./components/shared/layout/footer/footer.component";
import { HeaderComponent } from "./components/shared/layout/header/header.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { OrgchartComponent } from "./pages/admin/orgchart/orgchart.component";
import { OrgChartModule } from "./components/org-chart-module";
import { InactiveEmployeeComponent } from "./components/inactive-employee/inactive-employee.component";
import { EmployeeSettingsComponent } from "./components/employee-settings/employee-settings.component";
import {
  ObjectiveConfigurationComponent
} from "./components/objective-configuration/objective-configuration.component";
import { MomentModule } from "ngx-moment";
import {
  ConfirmationDialogComponent
} from "./components/shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ChangePasswordComponent } from "./pages/profile/change-password.component";
import {
  EmployeeDashboardLoaderComponent
} from "./pages/dashboard/employee-dashboard/employee-dashboard-loader.component";
import { MatBadgeModule } from "@angular/material/badge";
import { EventSurveyComponent } from "./pages/surveys/event-survey/eventSurvey.component";
import { MatRadioModule } from "@angular/material/radio";
import {
  InteractionScoreChartComponent
} from "./components/shared/interaction-score-chart/interaction-score-chart.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ToastrModule } from "ngx-toastr";
import { InthractionComponent } from "./components/shared/inthraction/inthraction.component";
import { ScoreCardModule } from "./components/shared/score-card";
import { ObjectiveSurveyComponent } from "./pages/surveys/objective-survey/objective-survey.component";
import {
  ObjectiveScoreChartComponent
} from "./components/shared/objective-score-chart/objective-score-chart.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  AuthorizeCalendarAccessComponent
} from "./components/authorize-calendar-access/authorize-calendar-access.component";
import {
  SystemObjectiveEditorComponent
} from "./pages/super-admin/system-objective-editor/system-objective-editor.component";
import { EditObjectiveDialogComponent } from "./components/shared/add-edit-objective/edit-objective-dialog.component";
import {
  StandardDialogComponentComponent
} from "./components/shared/dialogs/standard-dialog-component/standard-dialog-component.component";
import { ShowSuperDirective } from "./components/shared/show-super.directive";
import { SubscriptionComponent } from "./pages/admin/subscription/subscription.component";
import { OrgChartElementDialogComponent } from "./pages/admin/orgchart/orgChartElementDialog.component";
import { ObjectiveComponent } from "./components/shared/objective/objective.component";
import { CadenceObjectiveComponent } from "./components/shared/cadence-objective/cadenceObjective.component";
import {
  QuantifiableObjectiveComponent
} from "./components/shared/quantifiable-objective/quantifiable-objective.component";
import { environment } from "../environments/environment";
import { errorHandlerFactory } from "./app.error-handler";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { ObjectiveResponseComponent } from "./components/shared/objective-response/objectiveResponse.component";
import { ProjectDashboardComponent } from "./pages/dashboard/project-dashboard/project-dashboard.component";
import { AddProjectComponent } from "./components/add-project/add-project.component";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { AddTeamMemberComponent } from "./components/add-team-member/add-team-member.component";
import { MatStepperModule } from "@angular/material/stepper";
import {
  AddEditProjectMilestoneComponent
} from "./components/add-edit-project-milestone/add-edit-project-milestone.component";
import { ProjectMilestoneComponent } from "./components/shared/project-milestone/project-milestone.component";
import { AddEditProjectNoteComponent } from "./components/shared/add-edit-project-note/add-edit-project-note.component";
import { ProjectSurveyComponent } from "./pages/surveys/project-survey/project-survey.component";
import { MilestoneSurveyComponent } from "./pages/surveys/milestone-survey/milestone-survey.component";
import { ProjectScoreChartComponent } from "./components/shared/project-score-chart/project-score-chart.component";
import {
  MilestoneScoreChartComponent
} from "./components/shared/milestone-score-chart/milestone-score-chart.component";
import { HrWorkforceComponent } from "./pages/hr/hr-workforce/hr-workforce.component";
import { ExecutiveDashboardComponent } from "./pages/dashboard/executive-dashboard/executive-dashboard.component";
import { InthractionGraphComponent } from "./components/inthraction-graph/inthraction-graph.component";
import { TeamViewComponentComponent } from "./components/team-view-component/team-view-component.component";
import { EmployeeCardComponentComponent } from "./components/employee-card-component/employee-card-component.component";
import { UISharedModule } from "@inthraction/features";
import { HrEmployeeEditComponent } from "./pages/hr/hr-employee-edit/hr-employee-edit.component";
import { EmployeeReadinessComponent } from "./components/shared/employee-readiness/employee-readiness.component";
import { MatSliderModule } from "@angular/material/slider";
import { OneOnOneComponent } from "./components/shared/one-on-one/one-on-one.component";
import {
  ProgressCheckDialogComponent
} from "./components/shared/progress-check-dialog/progress-check-dialog.component";
import { NoteHistoryDialogComponent } from "./components/shared/note-history-dialog/note-history-dialog.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MiniEmployeeCardComponent } from "./components/shared/mini-employee-card/mini-employee-card.component";
import { NotesComponent } from "./components/shared/notes/notes.component";
import {
  ProjectSurveyResultsComponent
} from "./components/shared/dialogs/project-survey-results/project-survey-results.component";
import {
  MilestoneSurveyResultsComponent
} from "./components/shared/dialogs/milestone-survey-results/milestone-survey-results.component";
import { SystemConfigurationComponent } from "./pages/admin/system-configuration/system-configuration.component";
import { ShoutoutConfigurationComponent } from "./pages/admin/shoutout-configuration/shoutout-configuration.component";
import {
  SystemShoutoutEditorComponent
} from "./pages/super-admin/system-shoutout-editor/system-shoutout-editor.component";
import {
  EditShoutOutTypeDialogComponent
} from "./components/shared/dialogs/edit-shout-out-type-dialog/edit-shout-out-type-dialog.component";
import {
  EditOrganizationShoutOutTypeDialogComponent
} from "./components/shared/dialogs/edit-organization-shout-out-type-dialog/edit-organization-shout-out-type-dialog.component";
import { EmployeeInsphrationComponent } from "./components/shared/employee-insphration/employee-insphration.component";
import {
  InsphrationLeaderBoardComponent
} from "./pages/reports/insphration-leader-board/insphration-leader-board.component";
import { InsphrationSurveyComponent } from "./pages/insphration-survey/insphration-survey.component";
import {
  InsphrationResultDialogComponent
} from "./components/shared/dialogs/insphration-result-dialog/insphration-result-dialog.component";
import {
  InsphrationResultListDialogComponent
} from "./components/shared/dialogs/insphration-result-list-dialog/insphration-result-list-dialog.component";
import {
  IthractionGraphDialogComponent
} from "./components/shared/dialogs/ithraction-graph-dialog/ithraction-graph-dialog.component";
import { ProgressCheckComponent } from "./components/shared/progress-check/progress-check.component";
import {
  SurveyCommentsDialogComponent
} from "./components/shared/dialogs/survey-comments-dialog/survey-comments-dialog.component";
import { EmployeePersonalGoalsComponent } from "./components/employee-personal-goals/employee-personal-goals.component";
import { ManageOrganizationsComponent } from "./pages/support/manage-organizations/manage-organizations.component";
import { MatTableModule } from "@angular/material/table";
import {
  EditOrganizationDialogComponent
} from "./components/support/edit-organization-dialog/edit-organization-dialog.component";
import {
  ManageOrganizationEmployeesComponent
} from "./pages/support/manage-organization-employees/manage-organization-employees.component";
import { ManageConsultantsComponent } from "./pages/support/manage-consultants/manage-consultants.component";
import {
  AddConsultantAssignmentComponent
} from "./components/support/add-consultant-assignment/add-consultant-assignment.component";
import {
  EditConsultantAssignmentComponent
} from "./components/support/edit-consultant-assignment/edit-consultant-assignment.component";
import { ConsultantDashboardComponent } from "./pages/dashboard/consultant-dashboard/consultant-dashboard.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import {
  ConsultantObjectiveConfigurationComponent
} from "./pages/consultant/consultant-objective-configuration/consultant-objective-configuration.component";
import { SurveyComponent } from "./pages/surveys/survey/survey.component";
import { ConsultantFavoritesComponent } from "./pages/consultant/consultant-favorites/consultant-favorites.component";
import { MilestoneCommentsComponent } from "./components/shared/milestone-comments/milestone-comments.component";
import { AddCommentDialogComponent } from "./components/shared/dialogs/add-comment-dialog/add-comment-dialog.component";
import { HelpBubbleComponent } from "./components/shared/help-bubble/help-bubble.component";
import {
  AddEditEmployeeObjectiveComponent
} from "./components/employee-settings/add-edit-employee-objective.component";
import { MarkdownModule } from "ngx-markdown";
import { HelpDialogComponent } from "./components/shared/dialogs/help-dialog/help-dialog.component";
import {
  ObjectiveDistributionDetailComponent
} from "./components/objective-distribution/objective-distribution-detail/objective-distribution-detail.component";
import {
  HrObjectiveDistributionComponent
} from "./pages/hr/hr-objective-distribution/hr-objective-distribution.component";
import {
  ManagerObjectiveDistributionComponent
} from "./pages/manager/manager-objective-distribution/manager-objective-distribution.component";
import {
  ObjectiveDistributionSummaryComponent
} from "./components/objective-distribution/objective-distribution-summary/objective-distribution-summary.component";
import {
  ObjectiveDistributionListComponent
} from "./components/objective-distribution/objective-distribution-list/objective-distribution-list.component";
import { ReadinessHistoryComponent } from "./components/shared/readiness-history/readiness-history.component";
import { ReadinessReportComponent } from "./pages/hr/readiness-report/readiness-report.component";
import { MatSortModule } from "@angular/material/sort";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { EmployeeSearchComponent } from "./components/shared/search/employee-search/employee-search.component";
import { NgxResizeObserverModule } from "ngx-resize-observer";
import {
  ObjectiveResponseDistributionComponent
} from "./components/objective-response-distribution/objective-response-distribution.component";
import {
  HrObjectiveResponseDistributionComponent
} from "./pages/hr/hr-objective-response-distribution/hr-objective-response-distribution.component";
import {
  ManagerObjectiveResponseDistributionComponent
} from "./pages/manager/manager-objective-response-distribution/manager-objective-response-distribution.component";
import {
  ObjectiveResponseDistributionDetailsComponent
} from "./components/objective-response-distribution/objective-response-distribution-details/objective-response-distribution-details.component";
import {
  ObjectiveResponseDistributionDialogComponent
} from "./components/objective-response-distribution/objective-response-distribution-dialog/objective-response-distribution-dialog.component";
import { ExternalSurveyComponent } from "./pages/public/external-survey/external-survey.component";
import { UnsubscribeComponent } from "./pages/public/unsubscribe/unsubscribe.component";
import { LowercaseModule } from "./components/shared/lowercase/lowercase.module";
import { TermsOfUseComponent } from "./components/shared/layout/terms-of-use/terms-of-use.component";
import {
  EmployeeObjectiveAssignmentsComponent
} from "./pages/reports/employee-objective-assignments/employee-objective-assignments.component";
import {
  EmployeeObjectiveAssignmentsDetailComponent
} from "./pages/reports/employee-objective-assignments/employee-objective-assignments-detail/employee-objective-assignments-detail.component";
import {
  ObjectiveDialogComponent
} from "./pages/reports/employee-objective-assignments/objective-dialog/objective-dialog.component";
import { UsersReportComponent } from "./pages/reports/users-report/users-report.component";
import {
  OrganizationSurveyStatisticsComponent
} from "./pages/super-admin/organization-survey-statistics/organization-survey-statistics.component";
import { LoadingComponent } from "./components/shared/loading/loading.component";
import {
  OrganizationExperienceSurveyComponent
} from "./pages/surveys/organization-experience-survey/organization-experience-survey.component";
import { HeadwayComponent } from "./components/shared/headway/headway.component";
import { ZendeskComponent } from "./components/shared/zendesk/zendesk.component";
import { AuthComponent } from "./pages/auth/auth.component";
import {
  OrganizationExperienceReportComponent
} from "./pages/reports/organization-experience-report/organization-experience-report.component";
import { StandupConfigurationComponent } from "./components/standup-configuration/standup-configuration.component";
import { StandupHistoryComponent } from "./pages/manager/standup-history/standup-history.component";
import { StandupSurveyComponent } from "./pages/surveys/standup-survey/standup-survey.component";
import {
  EmployeeStandupHistoryComponent
} from "./components/employee-standup-history/employee-standup-history.component";
import {
  StandUpSurveyDisplayComponent
} from "./components/shared/stand-up-survey-display/stand-up-survey-display.component";
import { ConsultantStandUpComponent } from './pages/consultant/consultant-stand-up/consultant-stand-up.component';
import { SearchEmployeeDialogComponent } from './components/shared/dialogs/search-employee-dialog/search-employee-dialog.component';
import { CareerAspirationsComponent } from './components/career-aspirations/career-aspirations.component';
import { PortalModule } from "@angular/cdk/portal";
import { HrDashboardComponent } from './pages/dashboard/hr-dashboard/hr-dashboard.component';
import { SideNavDrawerComponent } from './components/shared/side-nav-drawer/side-nav-drawer.component';
import { ManagerDashboardComponent } from './pages/dashboard/manager-dashboard/manager-dashboard.component';
import { AdminDashboardComponent } from './pages/dashboard/admin-dashboard/admin-dashboard.component';
import { SupportDashboardComponent } from './pages/dashboard/support-dashboard/support-dashboard.component';
import { ConsultantRoutingDashboardComponent } from './pages/dashboard/consultant-routing-dashboard/consultant-routing-dashboard.component';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { PageHeaderComponent } from './components/shared/page-header/page-header.component';
import { FilterOrganizationDialogComponent } from './components/shared/filter-organization-dialog/filter-organization-dialog.component';
import { OrganizationSearchComponent } from './components/shared/search/organization-search/organization-search.component';
import { EditObjectiveDomainDialogComponent } from './components/shared/edit-objective-domain-dialog/edit-objective-domain-dialog.component';
import { ConsultantEmployeeEditComponent } from './pages/consultant/consultant-employee-edit/consultant-employee-edit.component';
import { ConsultantOneOnOneComponent } from './pages/consultant/consultant-one-on-one/consultant-one-on-one.component';
import { HrOneOnOneComponent } from './pages/hr/hr-one-on-one/hr-one-on-one.component';
import { ManagerOneOnOneComponent } from './pages/manager/manager-one-on-one/manager-one-on-one.component';
import { StandupOneOnOneComponent } from './pages/manager/standup-one-on-one/standup-one-on-one.component';
import {NgPipesModule} from 'ngx-pipes';

Sentry.init({
  dsn: "https://c02217876fa94f79a4e5841c0d79dfc5@sentry.io/3568254",
  environment: environment.environmentName,
  maxValueLength: 1000
});

@NgModule({
  declarations: [
    AddEditEmployeeObjectiveComponent,
    EmployeeDashboardLoaderComponent,
    AppComponent,
    ProfileComponent,
    ChangePasswordComponent,
    ManagerWorkforceComponent,
    EmployeeDashboardComponent,
    FooterComponent,
    HeaderComponent,
    ShowSuperDirective,
    OrgchartComponent,
    OrgChartElementDialogComponent,
    ConfirmationDialogComponent,
    ObjectiveConfigurationComponent,
    InactiveEmployeeComponent,
    EmployeeSettingsComponent,
    EventSurveyComponent,
    InteractionScoreChartComponent,
    InthractionComponent,
    ObjectiveResponseComponent,
    ObjectiveSurveyComponent,
    ObjectiveScoreChartComponent,
    AuthorizeCalendarAccessComponent,
    SystemObjectiveEditorComponent,
    EditObjectiveDialogComponent,
    StandardDialogComponentComponent,
    SubscriptionComponent,
    ObjectiveComponent,
    CadenceObjectiveComponent,
    QuantifiableObjectiveComponent,
    ProjectDashboardComponent,
    AddProjectComponent,
    AddTeamMemberComponent,
    AddEditProjectMilestoneComponent,
    ProjectMilestoneComponent,
    AddEditProjectNoteComponent,
    ProjectSurveyComponent,
    MilestoneSurveyComponent,
    ProjectScoreChartComponent,
    MilestoneScoreChartComponent,
    HrWorkforceComponent,
    ExecutiveDashboardComponent,
    InthractionGraphComponent,
    TeamViewComponentComponent,
    EmployeeCardComponentComponent,
    HrEmployeeEditComponent,
    EmployeeReadinessComponent,
    OneOnOneComponent,
    ProgressCheckDialogComponent,
    NoteHistoryDialogComponent,
    MiniEmployeeCardComponent,
    NotesComponent,
    ProjectSurveyResultsComponent,
    MilestoneSurveyResultsComponent,
    SystemConfigurationComponent,
    ShoutoutConfigurationComponent,
    SystemShoutoutEditorComponent,
    EditShoutOutTypeDialogComponent,
    EditOrganizationShoutOutTypeDialogComponent,
    EmployeeInsphrationComponent,
    InsphrationLeaderBoardComponent,
    InsphrationSurveyComponent,
    InsphrationResultDialogComponent,
    InsphrationResultListDialogComponent,
    IthractionGraphDialogComponent,
    ProgressCheckComponent,
    SurveyCommentsDialogComponent,
    EmployeePersonalGoalsComponent,
    ManageOrganizationsComponent,
    EditOrganizationDialogComponent,
    ManageOrganizationEmployeesComponent,
    ManageConsultantsComponent,
    AddConsultantAssignmentComponent,
    EditConsultantAssignmentComponent,
    ConsultantDashboardComponent,
    ConsultantObjectiveConfigurationComponent,
    SurveyComponent,
    ConsultantFavoritesComponent,
    MilestoneCommentsComponent,
    AddCommentDialogComponent,
    HelpBubbleComponent,
    HelpDialogComponent,
    ObjectiveDistributionDetailComponent,
    HrObjectiveDistributionComponent,
    ObjectiveDistributionSummaryComponent,
    ManagerObjectiveDistributionComponent,
    ObjectiveDistributionListComponent,
    ReadinessHistoryComponent,
    ReadinessReportComponent,
    EmployeeSearchComponent,
    ObjectiveResponseDistributionComponent,
    HrObjectiveResponseDistributionComponent,
    ManagerObjectiveResponseDistributionComponent,
    ObjectiveResponseDistributionDetailsComponent,
    ObjectiveResponseDistributionDialogComponent,
    ExternalSurveyComponent,
    UnsubscribeComponent,
    TermsOfUseComponent,
    EmployeeObjectiveAssignmentsComponent,
    EmployeeObjectiveAssignmentsDetailComponent,
    ObjectiveDialogComponent,
    UsersReportComponent,
    OrganizationExperienceSurveyComponent,
    OrganizationSurveyStatisticsComponent,
    LoadingComponent,
    HeadwayComponent,
    ZendeskComponent,
    AuthComponent,
    OrganizationExperienceReportComponent,
    StandupConfigurationComponent,
    StandupHistoryComponent,
    StandupSurveyComponent,
    EmployeeStandupHistoryComponent,
    StandUpSurveyDisplayComponent,
    ConsultantStandUpComponent,
    SearchEmployeeDialogComponent,
    CareerAspirationsComponent,
    HrDashboardComponent,
    SideNavDrawerComponent,
    ManagerDashboardComponent,
    AdminDashboardComponent,
    SupportDashboardComponent,
    ConsultantRoutingDashboardComponent,
    PageHeaderComponent,
    FilterOrganizationDialogComponent,
    OrganizationSearchComponent,
    EditObjectiveDomainDialogComponent,
    ConsultantEmployeeEditComponent,
    ConsultantOneOnOneComponent,
    HrOneOnOneComponent,
    ManagerOneOnOneComponent,
    StandupOneOnOneComponent
  ],
  imports: [
    MarkdownModule.forRoot({ loader: HttpClient }),
    NgxEchartsModule.forRoot({
      echarts: {
        init
      }
    }),
    // StorageServiceModule,
    LowercaseModule,
    MatSortModule,
    MatSidenavModule,
    MatBadgeModule,
    MomentModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatListModule,
    MatDialogModule,
    MatCommonModule,
    MatMomentDateModule,
    NgxResizeObserverModule,
    BrowserAnimationsModule,
    OrgChartModule,
    MatToolbarModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatExpansionModule,
    NgxChartsModule,
    NgPipesModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatRadioModule,
    MatTooltipModule,
    ScoreCardModule,
    ToastrModule.forRoot(),
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatStepperModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    UISharedModule,
    MatTableModule,
    DragDropModule,
    PortalModule,
    MatButtonToggleModule
  ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    GoogleAnalyticsService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline" } }
  ],
  exports: [
    HelpBubbleComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly iconRegistry: MatIconRegistry) {
    const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
    const outlinedFontSetClasses = defaultFontSetClasses
      .filter((fontSetClass) => fontSetClass !== 'material-icons')
      .concat(['material-symbols-outlined']);
    iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
  }
}
