import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { Employee, EmployeeImageInterface, EventSurvey, Project, Survey } from "@inthraction/data-models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EmployeeService, ProjectService, SurveyService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { OBJECTIVE_SURVEY_RESPONSE_LABELS, OBJECTIVE_SURVEY_RESPONSE_TIPS } from "@inthraction/labels";
import {
  OBJECTIVE_SURVEY_RESPONSE_ICON_PATHS,
  OBJECTIVE_SURVEY_RESPONSE_ICONS,
  OBJECTIVE_SURVEY_RESPONSE_TYPES,
  OBJECTIVE_SURVEY_RESPONSE_VALUES
} from "@inthraction/codes";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { MatButton } from "@angular/material/button";
import { SurveyCompleteEvent } from "../survey/survey.component";

@Component({
  selector: "inthraction-project-survey",
  templateUrl: "./project-survey.component.html",
  styleUrls: ["./project-survey.component.scss"]
})
export class ProjectSurveyComponent implements OnInit, OnDestroy, OnChanges {

  static surveyIDParameterName = "survey-id";
  static surveyResponseParameterName = "survey-response";
  @ViewChild("submitButtonElement") submitButtonElm: ElementRef<MatButton | HTMLButtonElement>;
  readonly surveyResponseTypes = Object.assign([], OBJECTIVE_SURVEY_RESPONSE_TYPES).reverse();
  readonly surveyResponseLabels = OBJECTIVE_SURVEY_RESPONSE_LABELS;
  readonly surveyResponseValues = OBJECTIVE_SURVEY_RESPONSE_VALUES;
  readonly surveyResponseOptionsTips = OBJECTIVE_SURVEY_RESPONSE_TIPS;
  readonly surveyResponseIcons = OBJECTIVE_SURVEY_RESPONSE_ICONS;
  readonly errorMessage = "Please try again.";
  attendee: Employee;
  attendeeImage: EmployeeImageInterface;
  project: Project;
  surveyForm = new FormGroup(
    {
      surveyValue: new FormControl("", [Validators.required]),
      feedback: new FormControl("", [])
    });
  private surveyValueChangeSubscription: Subscription;

  @Input() survey: Survey;
  @Input() employee: Employee;
  @Input() remainingSurveysCount? = 0;
  @Input() surveyResponse?: number;
  @Output() surveyComplete: EventEmitter<SurveyCompleteEvent> = new EventEmitter<SurveyCompleteEvent>();

  constructor(
    protected surveyService: SurveyService,
    private projectService: ProjectService,
    private employeeService: EmployeeService,
    protected toastr: ToastrService,
    protected iconRegistry: MatIconRegistry,
    protected sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon("north", sanitizer.bypassSecurityTrustResourceUrl(OBJECTIVE_SURVEY_RESPONSE_ICON_PATHS.EXCEEDS));
    iconRegistry.addSvgIcon("north-east", sanitizer.bypassSecurityTrustResourceUrl(OBJECTIVE_SURVEY_RESPONSE_ICON_PATHS.HIGH_MEETS));
    iconRegistry.addSvgIcon("east", sanitizer.bypassSecurityTrustResourceUrl(OBJECTIVE_SURVEY_RESPONSE_ICON_PATHS.MEETS));
    iconRegistry.addSvgIcon("south-east", sanitizer.bypassSecurityTrustResourceUrl(OBJECTIVE_SURVEY_RESPONSE_ICON_PATHS.LOW_MEETS));
    iconRegistry.addSvgIcon("south", sanitizer.bypassSecurityTrustResourceUrl(OBJECTIVE_SURVEY_RESPONSE_ICON_PATHS.NEEDS_IMPROVEMENT));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.survey && changes.survey.currentValue) {
      this.surveyForm.reset();
      this.attendee = null;
      this.attendeeImage = null;
      this.project = null;
      this.initialize(changes.survey.currentValue, this.surveyResponse);
    }
    if (changes.surveyResponse && changes.surveyResponse.currentValue) {
      this.initializeResponse(changes.surveyResponse.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.surveyValueChangeSubscription) {
      this.surveyValueChangeSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.surveyValueChangeSubscription = this.surveyForm.get("surveyValue").valueChanges.subscribe(surveyValue => {
      if (surveyValue === OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT.toString() || surveyValue === OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS.toString()) {
        this.surveyForm.get("feedback").setValidators([Validators.required]);
        this.surveyForm.get("feedback").markAsTouched();
      } else {
        this.surveyForm.get("feedback").setValidators(null);
      }
      this.surveyForm.get("feedback").updateValueAndValidity();

      /*
       * Move focus to submit button after selecting an option to allow enter button to submit the form.
       * This is necessary because the options are using a custom styling that makes them not part of the form.
       */
      if (this.submitButtonElm) {
        setTimeout((elementRef) => {
            elementRef.nativeElement.focus();
          }, 100,
          // @ts-ignore //Submit button is wrapped with mat-button and is not a standard element.
          this.submitButtonElm._elementRef);
      }

    });
    await this.initialize(this.survey, this.surveyResponse);
  }

  async initialize(survey: EventSurvey, response: number) {
    if (survey) {
      this.attendee = await this.employeeService.getEmployeeByEmailMemoize(survey.participantEmail);
      this.attendeeImage = await this.employeeService.getEmployeeImageMemoize(this.attendee.id, this.attendee.orgId);
      this.project = await this.projectService.getProjectByIDMemoized(survey.objectID);
    }
    await this.initializeResponse(response);
  }

  async initializeResponse(response: number) {
    if ((response || response === 0) && !Number.isNaN(response) && response <= OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS && response >= OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT) {
      if (response === OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT || response === OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS) {
        this.surveyForm.get("feedback").markAsTouched();
      }
      // @ts-ignore
      this.surveyForm.get("surveyValue").setValue(response);
    } else {
      this.surveyResponse = null;
    }
  }

  async submitSurvey(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if (this.surveyForm.valid) {
      this.survey.surveyResponse = formValue.surveyValue;
      this.survey.feedback = formValue.feedback;
      this.survey.responseReceived = true;
      this.survey.responseReceivedDate = moment().milliseconds(0).toISOString().replace(".000Z", "Z");
      await this.surveyService.updateSurvey(
        {
          id: this.survey.id,
          feedback: ((this.survey.feedback == null || this.survey.feedback.length <= 0) ? null : this.survey.feedback),
          surveyResponse: this.survey.surveyResponse,
          responseReceived: true,
          responseReceivedDate: this.survey.responseReceivedDate
        });
      this.toastr.success("Thank you for your response", "intHRaction Received");
      this.surveyComplete.emit({ completedSurveyIDs: [this.survey.id] });
    } else {
      submitBtn.disabled = !this.surveyForm.valid;
      this.toastr.error(this.errorMessage);
    }
  }

  public hasError = (controlName: string, errorName: string) =>
    this.surveyForm.controls[controlName].hasError(errorName);

}
