import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

import * as shape from "d3-shape";
import { MultiSeries, Series } from "@swimlane/ngx-charts";
import { Employee } from "@inthraction/data-models";
import * as moment from "moment";
import { Moment } from "moment";
import { EmployeeService, OrganizationService, SurveyService } from "@inthraction/services";
import { OrganizationConfigurationCodes } from "@inthraction/codes";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";

@Component({
  selector: "inthraction-interaction-score-chart",
  templateUrl: "./interaction-score-chart.component.html",
  styleUrls: ["./interaction-score-chart.component.scss"]
})
export class InteractionScoreChartComponent implements OnInit, OnChanges {

  @Input() orgID: string;
  @Input() employees: string[];
  @Input() employee: Employee;
  @Input() managerView: boolean;
  @Input() options: InteractionScoreChartOptions = {};
  @Output() openEventEmitter = new EventEmitter();
  @Output() scoreEmitter = new EventEmitter();
  @Output() noDataEmitter = new EventEmitter();
  selectedColorScheme: string;
  view: any[];
  colorScheme: any;
  // colorSets: any;
  schemeType = "ordinal";
  // range: boolean = true;
  animations = true;
  showLegend = true;
  legendTitle = "Legend";
  legendPosition = "right";
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = false;
  showYAxisLabel = false;
  xAxisLabel = "Country";
  yAxisLabel = "GDP Per Capita";
  autoScale = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  timeline = true;
  showGridLines = true;
  curves = {
    "Basis": shape.curveBasis,
    "Basis Closed": shape.curveBasisClosed,
    "Bundle": shape.curveBundle.beta(1),
    "Cardinal": shape.curveCardinal,
    "Cardinal Closed": shape.curveCardinalClosed,
    "Catmull Rom": shape.curveCatmullRom,
    "Catmull Rom Closed": shape.curveCatmullRomClosed,
    "Linear": shape.curveLinear,
    "Linear Closed": shape.curveLinearClosed,
    "Monotone X": shape.curveMonotoneX,
    "Monotone Y": shape.curveMonotoneY,
    "Natural": shape.curveNatural,
    "Step": shape.curveStep,
    "Step After": shape.curveStepAfter,
    "Step Before": shape.curveStepBefore,
    "default": shape.curveLinear
  };
  curveType = "Linear";
  curve: any = this.curves[this.curveType];
  rangeFillOpacity = 0.15;
  roundDomains = false;
  tooltipDisabled = false;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;

  width = 700;
  height = 250;
  fitContainer = true;

  chartData: MultiSeries = [];

  today = new Date();
  currentYear: number = this.today.getFullYear();
  currentMonth: number = this.today.getMonth();
  thisYear: Moment = moment((new Date("1/1/" + this.currentYear)).valueOf());
  rollingYearStart: Moment = moment((new Date((this.currentMonth + 1) + "/1/" + (this.currentYear - 1))).valueOf());

  score: number;
  startingScore: number;

  constructor(
    private employeeService: EmployeeService,
    private organizationService: OrganizationService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.setColorScheme("fire");
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ((changes.options && !changes.options?.firstChange) || (changes.employees && !changes.employees?.firstChange)) {
      this.chartData = await this.generateChartData(this.employees, this.options?.startDate, this.options?.endDate);
    }
  }

  async ngOnInit(): Promise<void> {
    this.chartData = await this.generateChartData(this.employees, this.options?.startDate, this.options?.endDate);
    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }

  async getScoreStartMoment(orgID: string): Promise<Moment> {
    let startingPoint: Moment;
    const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION, orgID);
    if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
      startingPoint = this.rollingYearStart;
    } else {
      startingPoint = this.thisYear;
    }
    return startingPoint;
  }

  async generateChartData(employees: string[], startDate?: string, endDate?: string): Promise<MultiSeries> {
    const scoreStart = (await this.getScoreStartMoment(this.orgID)).toISOString().replace(".000Z", "Z");
    const results: MultiSeries = [];
    let totalScore = 0;
    let first = true;
    for (const employeeID of employees) {
      const employee: Employee = await this.employeeService.getEmployeeByIDMemoize(employeeID);
      const series: Series = {
        name: `${employee.firstName} ${employee.lastName}`,
        series: []
      };
      const scores = await this.employeeService.getEmployeeEventScoresMemoize(employee.id, scoreStart, endDate);
      if (scores.length > 0) {
        scores.sort((a, b) => (a.scoreStart > b.scoreStart) ? 1 : ((b.scoreStart > a.scoreStart) ? -1 : 0));
        let count = 0;
        let sum = 0;
        for (const employeeScore of scores) {
          if (employeeScore.employeeTotalEventScoreDetails?.responses?.length) {
            count += employeeScore.employeeTotalEventScoreDetails.responses.length;
            sum += employeeScore.employeeTotalEventScoreDetails.responses.reduce((a, b) => a + b.score, 0);
          } else {
            count++;
            sum += employeeScore.score;
          }
          if (this.employee.id !== employee.id || !startDate || employeeScore.scoreStart >= startDate) {
            series.series.push(
              {
                value: SurveyService.round(sum / count, 2),
                name: new Date(employeeScore.scoreStart),
                extra: this.managerView || this.employee.id === employee.id ? {
                  scoreID: employeeScore.scoreID,
                  employeeID: employee.id
                } : null
              });
          } else {
            this.startingScore = SurveyService.round(sum / count, 2);
          }
        }
        if (first) {
          totalScore = SurveyService.round(sum / count, 2);
          first = false;
        } else {
          const currentEmpScore = SurveyService.round(sum / count, 2);
          totalScore = SurveyService.round((totalScore + currentEmpScore) / 2, 2);
        }
        if (series.series.length) {
          results.push(series);
        }
      }
    }
    if (results.length) {
      this.score = totalScore;
    } else {
      this.score = null;
    }

    this.scoreEmitter.emit({ startingScore: this.startingScore, endingScore: this.score });
    this.noDataEmitter.emit({ nodata: (!results.length) });
    return results;
  }

  select(data: any): void {
    this.googleAnalyticsService.eventEmitter("inthraction-chart-response-view", "inthraction-response", "view_item", "click", 20);
    if (data.extra) {
      this.openEventEmitter.emit(data.extra);
    }
  }

  // TODO
  activate(data: any): void {
    // console.debug('Activate', JSON.parse(JSON.stringify(data)));
  }

  // TODO
  deactivate(data: any): void {
    // console.debug('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  applyDimensions(): void {
    this.view = [this.width, this.height];
  }

  setColorScheme(name: string): void {
    this.selectedColorScheme = name;
    this.colorScheme = {
      name: "fire",
      selectable: true,
      group: "Ordinal",
      domain: [
        "#ff3d00", "#bf360c", "#ff8f00", "#ff6f00", "#ff5722", "#e65100", "#ffca28", "#ffab00"
      ]
    };
  }

  dateTickFormatting(val: any): string {
    if (val instanceof Date) {
      return moment(val).format("ll");
    } else {
      return val;
    }
  }

}

export interface InteractionScoreChartOptions {
  startDate?: string;
  endDate?: string;
}
