import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { Employee, ExperienceSurvey, Survey } from "@inthraction/data-models";
import { SurveyCompleteEvent } from "../survey/survey.component";
import {
  OBJECTIVE_SURVEY_RESPONSE_ICONS,
  OBJECTIVE_SURVEY_RESPONSE_TYPES,
  OBJECTIVE_SURVEY_RESPONSE_VALUES,
  SurveyTypeCodes
} from "@inthraction/codes";
import {
  NEW_MANAGER_30_SURVEY_DESCRIPTION,
  NEW_MANAGER_30_SURVEY_RESPONSE_LABELS,
  NEW_MANAGER_30_SURVEY_TITLE,
  NEW_MANAGER_90_SURVEY_DESCRIPTION,
  NEW_MANAGER_90_SURVEY_RESPONSE_LABELS,
  NEW_MANAGER_90_SURVEY_TITLE,
  OBJECTIVE_SURVEY_RESPONSE_LABELS,
  OBJECTIVE_SURVEY_RESPONSE_TIPS,
  ONBOARDING_14_SURVEY_DESCRIPTION,
  ONBOARDING_14_SURVEY_RESPONSE_LABELS,
  ONBOARDING_14_SURVEY_TITLE,
  ONBOARDING_90_SURVEY_DESCRIPTION,
  ONBOARDING_90_SURVEY_RESPONSE_LABELS,
  ONBOARDING_90_SURVEY_TITLE,
  POSITION_60_SURVEY_DESCRIPTION,
  POSITION_60_SURVEY_RESPONSE_LABELS,
  POSITION_60_SURVEY_TITLE,
  NEW_HIRE_14_DAY_FOOTER,
  NEW_POSITION_60_DAY_FOOTER,
  NEW_MANAGER_30_DAY_FOOTER,
  NEW_HIRE_90_DAY_FOOTER,
  NEW_MANAGER_90_DAY_FOOTER
} from "@inthraction/labels";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { SurveyService } from "@inthraction/services";

@Component({
  selector: "inthraction-organization-experience-survey",
  templateUrl: "./organization-experience-survey.component.html",
  styleUrls: ["./organization-experience-survey.component.scss"]
})
export class OrganizationExperienceSurveyComponent implements OnInit, OnDestroy, OnChanges {

  static surveyIDParameterName = "survey-id";
  static surveyResponseParameterName = "survey-response";

  @ViewChild("submitButtonElement") submitButtonElm: ElementRef;

  @Input() survey: Survey;
  @Input() employee: Employee;
  @Input() remainingSurveysCount? = 0;
  @Input() surveyResponse?: number;
  @Output() surveyComplete: EventEmitter<SurveyCompleteEvent> = new EventEmitter<SurveyCompleteEvent>();

  private surveyValueChangeSubscription: Subscription;

  readonly SURVEY_TYPES = SurveyTypeCodes;
  readonly surveyResponseTypes = Object.assign([], OBJECTIVE_SURVEY_RESPONSE_TYPES).reverse();
  surveyResponseLabels: any = OBJECTIVE_SURVEY_RESPONSE_LABELS;
  readonly surveyResponseValues = OBJECTIVE_SURVEY_RESPONSE_VALUES;
  readonly surveyResponseTips = OBJECTIVE_SURVEY_RESPONSE_TIPS;
  readonly surveyResponseIcons = OBJECTIVE_SURVEY_RESPONSE_ICONS;
  readonly ONBOARDING_14_SURVEY_TITLE = ONBOARDING_14_SURVEY_TITLE;
  readonly POSITION_60_SURVEY_TITLE = POSITION_60_SURVEY_TITLE;
  readonly NEW_MANAGER_30_SURVEY_TITLE = NEW_MANAGER_30_SURVEY_TITLE;
  readonly ONBOARDING_90_SURVEY_TITLE = ONBOARDING_90_SURVEY_TITLE;
  readonly NEW_MANAGER_90_SURVEY_TITLE = NEW_MANAGER_90_SURVEY_TITLE;
  readonly ONBOARDING_14_SURVEY_DESCRIPTION = ONBOARDING_14_SURVEY_DESCRIPTION;
  readonly POSITION_60_SURVEY_DESCRIPTION = POSITION_60_SURVEY_DESCRIPTION;
  readonly NEW_MANAGER_30_SURVEY_DESCRIPTION = NEW_MANAGER_30_SURVEY_DESCRIPTION;
  readonly ONBOARDING_90_SURVEY_DESCRIPTION = ONBOARDING_90_SURVEY_DESCRIPTION;
  readonly NEW_MANAGER_90_SURVEY_DESCRIPTION = NEW_MANAGER_90_SURVEY_DESCRIPTION;
  readonly NEW_HIRE_14_DAY_FOOTER = NEW_HIRE_14_DAY_FOOTER;
  readonly NEW_POSITION_60_DAY_FOOTER = NEW_POSITION_60_DAY_FOOTER;
  readonly NEW_MANAGER_30_DAY_FOOTER = NEW_MANAGER_30_DAY_FOOTER;
  readonly NEW_HIRE_90_DAY_FOOTER = NEW_HIRE_90_DAY_FOOTER;
  readonly NEW_MANAGER_90_DAY_FOOTER = NEW_MANAGER_90_DAY_FOOTER;
  surveyForm: FormGroup;
  feedback = new FormControl("", []);
  surveyValue = new FormControl("", [Validators.required]);

  constructor(
    private surveyService: SurveyService,
    protected toastr: ToastrService,
    protected iconRegistry: MatIconRegistry,
    protected sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon("north", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_north-24px.svg"));
    iconRegistry.addSvgIcon("north-east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_north_east-24px.svg"));
    iconRegistry.addSvgIcon("east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_east-24px.svg"));
    iconRegistry.addSvgIcon("south-east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_south_east-24px.svg"));
    iconRegistry.addSvgIcon("south", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_south-24px.svg"));

    this.surveyForm = new FormGroup(
      {
        surveyValue: this.surveyValue,
        feedback: this.feedback
      });
  }

  async ngOnInit() {
    this.surveyValueChangeSubscription = this.surveyForm.get("surveyValue").valueChanges.subscribe(surveyValue => {
      this.surveyForm.get("feedback").setValidators([Validators.required]);
      this.surveyForm.get("feedback").markAsTouched();
      this.surveyForm.get("feedback").updateValueAndValidity();

      /*
       * Move focus to submit button after selecting an option to allow enter button to submit the form.
       * This is necessary because the options are using a custom styling that makes them not part of the form.
       */
      if (this.submitButtonElm) {
        setTimeout((elementRef) => {
            elementRef.nativeElement.focus();
          }, 100,
          // @ts-ignore //Submit button is wrapped with mat-button and is not a standard element.
          this.submitButtonElm._elementRef);
      }

    });

    await this.initialize(this.survey, this.surveyResponse);
  }

  async initialize(survey: ExperienceSurvey, response: number) {

    switch (survey.surveyType) {
      case SurveyTypeCodes.NEW_HIRE_14_DAY : {
        this.surveyResponseLabels = ONBOARDING_14_SURVEY_RESPONSE_LABELS;
        break;
      }
      case SurveyTypeCodes.NEW_POSITION_60_DAY : {
        this.surveyResponseLabels = POSITION_60_SURVEY_RESPONSE_LABELS;
        break;
      }
      case SurveyTypeCodes.NEW_MANAGER_30_DAY : {
        this.surveyResponseLabels = NEW_MANAGER_30_SURVEY_RESPONSE_LABELS;
        break;
      }
      case SurveyTypeCodes.NEW_HIRE_90_DAY : {
        this.surveyResponseLabels = ONBOARDING_90_SURVEY_RESPONSE_LABELS;
        break;
      }
      case SurveyTypeCodes.NEW_MANAGER_90_DAY : {
        this.surveyResponseLabels = NEW_MANAGER_90_SURVEY_RESPONSE_LABELS;
        break;
      }
      default: {
        this.surveyResponseLabels = OBJECTIVE_SURVEY_RESPONSE_LABELS;
      }
    }

    await this.initializeResponse(response);
  }

  async initializeResponse(response: number) {
    if ((response || response === 0) && !Number.isNaN(response) && response <= OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS && response >= OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT) {
      if (response === OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT || response === OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS) {
        this.surveyForm.get("feedback").markAsTouched();
      }
      this.surveyForm.get("surveyValue").setValue(response);
    } else {
      this.surveyResponse = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.survey && changes.survey.currentValue) {
      this.surveyForm.reset();
      this.initialize(changes.survey.currentValue, this.surveyResponse);
    }
    if (changes.surveyResponse && changes.surveyResponse.currentValue) {
      this.initializeResponse(changes.surveyResponse.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.surveyValueChangeSubscription) {
      this.surveyValueChangeSubscription.unsubscribe();
    }
  }

  public hasError = (controlName: string, errorName: string) =>
    this.surveyForm.controls[controlName].hasError(errorName);

  async submitSurvey(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if (this.surveyForm.valid) {
      this.survey.surveyResponse = formValue.surveyValue;
      this.survey.feedback = formValue.feedback;
      this.survey.responseReceived = true;
      this.survey.responseReceivedDate = moment().milliseconds(0).toISOString().replace(".000Z", "Z");

      await this.surveyService.updateSurvey({
        id: this.survey.id,
        feedback: ((this.survey.feedback == null || this.survey.feedback.length <= 0) ? null : this.survey.feedback),
        surveyResponse: this.survey.surveyResponse,
        responseReceived: true,
        responseReceivedDate: this.survey.responseReceivedDate
      });
      this.toastr.success("Thank you for your response", "intHRaction Received");
      this.surveyComplete.emit({ completedSurveyIDs: [this.survey.id] });
    } else {
      submitBtn.disabled = !this.surveyForm.valid;
      this.toastr.error("Please try again.");
    }
  }

}
