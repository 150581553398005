import { Component, HostListener, Inject, NgZone, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DEPARTMENT_TYPES, OrganizationConfigurationCodes } from "@inthraction/codes";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { Moment } from "moment";
import { EmployeeService, OrganizationService, UpdateEmployee } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import {
  Employee,
  EmployeeImageInterface,
  EmployeeImpl,
  Organization,
  OrganizationConfiguration
} from "@inthraction/data-models";
import { DEPARTMENT_TYPE_LABELS } from "@inthraction/labels";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Storage } from "aws-amplify";
import { StoragePutOutput } from "@aws-amplify/storage/src/types";

@Component({
  selector: "inthraction-oc-edit-employee",
  templateUrl: "./edit-employee.component.html",
  styleUrls: ["./edit-employee.component.scss"]
})
export class EditEmployeeComponent implements OnInit {

  selectedToggle = "profile";
  isEditingSelf = false;
  employee: Employee;
  showPhoto: boolean;
  editingPhoto: boolean;
  disableEdited: boolean;
  user: Employee;
  employeeImage: EmployeeImageInterface;
  manager: Employee;
  newManager: Employee;
  private currentUserAuthId: string;
  filteredManagerEmails: Observable<string[]>;

  departmentTypes = DEPARTMENT_TYPES;
  departmentTypeLabels = DEPARTMENT_TYPE_LABELS;

  private emails: string[];
  private managerEmail = new FormControl("", [Validators.required, Validators.email]);
  changeManagerFG = new FormGroup({
    email: this.managerEmail,
    isValidEmail: new FormControl(false, [Validators.requiredTrue]),
    isValidOrgStructure: new FormControl(true, [Validators.requiredTrue])
  });

  editEmployeeFG = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    title: new FormControl("", [Validators.required]),
    dateHired: new FormControl("", []),
    positionStartDate: new FormControl("", []),
    department: new FormControl("", [Validators.required]),
    admin: new FormControl(false, []),
    hr: new FormControl(false, []),
    pm: new FormControl(false, [])
  });
  private photoUpdate: boolean;
  private originalManager: Employee;
  private allowAllEmailDomainsConfiguration: OrganizationConfiguration;
  selectedFile: File;

  constructor(
    private dialogRef: MatDialogRef<EditEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private ngZone: NgZone
  ) {
    dialogRef.disableClose = true;
    this.employee = JSON.parse(JSON.stringify(data.employee));
    this.populateFormGroupFromEmployee();
    dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      this.onCancelClick();
    });
  }

  @HostListener("window:keyup.esc") onKeyUp() {
    this.onCancelClick();
  }

  imageSelected = (e: Event) => {
    const input = e.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    this.selectedFile = input.files[0];
    this.editingPhoto = true;
  };

  uploadImage = async () => {
    if (!this.selectedFile) {
      return;
    }
    try {
      const filteredName = this.selectedFile.name.replace(/\s/g, "_").replace(/[^a-zA-Z0-9_.]+/g, "");
      const uploadedPhoto = await Storage.put(`image/${filteredName}`, this.selectedFile, {
        contentType: "image/*",
        level: "protected"
      });

      await this.onImageUploaded(uploadedPhoto);

    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  async onImageUploaded(storagePutOutput: StoragePutOutput<any>): Promise<void> {
    if (storagePutOutput.key) {
      let updateImage = this.employeeImage;
      if (!updateImage) {
        updateImage = {
          employeeID: this.user.id,
          organizationID: this.user.orgId
        };
      }
      updateImage.image = storagePutOutput.key;
      updateImage.imageIdentityId = this.currentUserAuthId;
      this.employeeImage = await this.employeeService.putEmployeeImage(updateImage);
      this.photoUpdate = true;

      setTimeout(() => this.ngZone.run(() => {
        this.showPhoto = true;
        this.editingPhoto = false;
      }), 200);
    }
  }


  private populateFormGroupFromEmployee() {
    const employeeImpl: EmployeeImpl = new EmployeeImpl(this.employee);
    this.editEmployeeFG.get("email").setValue(this.employee.email);
    this.editEmployeeFG.get("firstName").setValue(this.employee.firstName);
    this.editEmployeeFG.get("lastName").setValue(this.employee.lastName);
    this.editEmployeeFG.get("title").setValue(this.employee.title);
    // @ts-ignore
    this.editEmployeeFG.get("dateHired").setValue(this.employee.hireDate ? moment(this.employee.hireDate, "YYYY-MM-DD") : "");
    // @ts-ignore
    this.editEmployeeFG.get("positionStartDate").setValue(this.employee.positionDate ? moment(this.employee.positionDate, "YYYY-MM-DD") : "");
    this.editEmployeeFG.get("admin").setValue(employeeImpl.isAdmin());
    this.editEmployeeFG.get("hr").setValue(employeeImpl.isHR());
    this.editEmployeeFG.get("pm").setValue(employeeImpl.isProjectManager());
    this.editEmployeeFG.get("department").setValue(this.employee.department);
    this.disableEnableEmployeeForm(this.employee.disabled);
  }

  private disableEnableEmployeeForm(disabled: boolean) {
    if (disabled || (this.employee.authId != null && this.employee.authId.length > 0) || this.employee.inviteSent) {
      this.editEmployeeFG.get("email").disable();
    } else {
      this.editEmployeeFG.get("email").enable();
    }
    if (disabled) {
      this.editEmployeeFG.get("firstName").disable();
      this.editEmployeeFG.get("lastName").disable();
      this.editEmployeeFG.get("title").disable();
      this.editEmployeeFG.get("dateHired").disable();
      this.editEmployeeFG.get("positionStartDate").disable();
      this.editEmployeeFG.get("admin").disable();
      this.editEmployeeFG.get("hr").disable();
      this.editEmployeeFG.get("pm").disable();
      this.editEmployeeFG.get("department").disable();
    } else {
      this.editEmployeeFG.get("firstName").enable();
      this.editEmployeeFG.get("lastName").enable();
      this.editEmployeeFG.get("title").enable();
      this.editEmployeeFG.get("dateHired").enable();
      this.editEmployeeFG.get("positionStartDate").enable();
      this.editEmployeeFG.get("admin").enable();
      this.editEmployeeFG.get("hr").enable();
      this.editEmployeeFG.get("pm").enable();
      this.editEmployeeFG.get("department").enable();
    }
  }

  async ngOnInit(): Promise<void> {
    this.disableEdited = false;
    this.editingPhoto = false;
    this.isEditingSelf = false;
    this.user = await this.employeeService.getEmployeeByEmailMemoize(this.employee.email.toLowerCase());
    if (!this.user) {
      console.error("Unable to find user", this.employee);
    }
    this.employeeImage = await this.employeeService.getEmployeeImageMemoize(this.user.id, this.user.orgId);
    this.showPhoto = this.user && this.employeeImage?.image && !!this.employeeImage?.image.trim();

    const currentEmployee = await this.employeeService.getCurrentEmployee();
    this.currentUserAuthId = currentEmployee.authId;
    if (this.user && this.user.authId === this.currentUserAuthId) {
      this.isEditingSelf = true;
      this.editEmployeeFG.get("admin").disable();
    }
    if (this.employee.managerID) {
      this.manager = await this.employeeService.getEmployeeByIDMemoize(this.employee.managerID);
    }

    const employees = await this.employeeService.getEmployeesForOrganizationByOrganization({organizationID:this.employee.orgId, includeDisabled:false, memoize:true});
    this.emails = employees.map(emp => emp.email).filter(email => email !== this.employee.email);

    this.filteredManagerEmails = this.managerEmail.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );

    this.allowAllEmailDomainsConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.OPEN_EMAIL_DOMAIN, this.employee.orgId);
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.emails.filter(option => option.toLowerCase().includes(filterValue));
  }

  onCancelClick(): void {
    this.dialogRef.close({
      employee: this.data.employee,
      photoUpdate: this.photoUpdate,
      employeeImage: this.employeeImage,
      disableEdited: this.disableEdited
    });
  }

  async enableEmployeeClick(): Promise<void> {
    this.employee.disabled = false;
    this.employee.disabledBy = null;
    this.employee.disabledDate = null;

    this.disableEnableEmployeeForm(this.employee.disabled);
    await this.updateEmployeeForDisableToggle();
    this.toastr.success("User Enabled");
  }

  async disableEmployeeClick(): Promise<void> {
    this.selectedToggle = "profile";
    this.employee.disabled = true;
    this.employee.disabledBy = this.currentUserAuthId; // TODO find a better way to indicate who disabled the employee
    this.employee.disabledDate = moment().toISOString();

    this.disableEnableEmployeeForm(this.employee.disabled);
    await this.updateEmployeeForDisableToggle();
    this.toastr.success("User Disabled");
  }

  cancelEditPhoto(): void {
    this.showPhoto = true;
    this.editingPhoto = false;
  }

  editPhoto(): void {
    this.showPhoto = false;
    this.editingPhoto = true;
  }

  async onSaveClick(value: any): Promise<void> {
    if (value.dateHired && (value.dateHired as Moment).isValid()) {
      this.employee.hireDate = (value.dateHired as Moment).format("YYYY-MM-DD");
    } else {
      this.employee.hireDate = null;
    }
    if (value.positionStartDate && (value.positionStartDate as Moment).isValid()) {
      this.employee.positionDate = (value.positionStartDate as Moment).format("YYYY-MM-DD");
    } else {
      this.employee.positionDate = null;
    }

    const employeeImpl = new EmployeeImpl(this.employee);
    employeeImpl.setHR(value.hr);
    employeeImpl.setPM(value.pm);
    if (!this.isEditingSelf) {
      employeeImpl.setAdmin(value.admin);
    }

    const updateEmployee: UpdateEmployee = {
      id: this.user.id,
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email ? value.email : this.employee.email,
      title: value.title,
      hireDate: this.employee.hireDate,
      positionDate: this.employee.positionDate,
      department: value.department
    };
    if (employeeImpl.roles) {
      updateEmployee.roles = employeeImpl.roles;
    } else {
      updateEmployee.roles = null;
    }
    this.employee = await this.employeeService.updateEmployee(updateEmployee);
    this.dialogRef.close({
      employee: this.employee,
      photoUpdate: this.photoUpdate,
      employeeImage: this.employeeImage,
      disableEdited: this.disableEdited
    });
  }

  private async updateEmployeeForDisableToggle(): Promise<void> {
    await this.employeeService.updateEmployee({
      id: this.user.id,
      disabled: this.employee.disabled,
      disabledBy: this.employee.disabledBy,
      disabledDate: this.employee.disabledDate
    });
    this.data.employee.disabled = this.employee.disabled;
    this.data.employee.disabledBy = this.employee.disabledBy;
    this.data.employee.disabledDate = this.employee.disabledDate;

    this.disableEdited = true;
  }

  async validateUpdatedEmail(): Promise<void> {
    const emailFC = this.editEmployeeFG.get("email");
    if (emailFC
      && emailFC.value.length > 0
      && emailFC.value.trim().length > 0
      && !emailFC.errors) {
      if (emailFC.value === this.user.email || !await this.verifyEmailNotExisting(emailFC.value)) {
        emailFC.setErrors({ duplicate: true });
      } else if ((!await this.verifyDomain(emailFC.value)) && !(this.allowAllEmailDomainsConfiguration?.configBooleanValue)) {
        emailFC.setErrors({ domain: true });
      }
    } else if (emailFC) {
      emailFC.setErrors({ email: true });
    }
  }

  private async verifyEmailNotExisting(newEmail: string): Promise<boolean> {
    const userSearch = await this.employeeService.doesEmployeeExistByEmail(newEmail.trim().toLowerCase());
    return !(userSearch);
  }

  private async verifyDomain(email: string): Promise<boolean> {
    const domain = email.split("@")[1].toLowerCase();
    const organization = await this.retrieveOrganization();
    return organization.domains.includes(domain);
  }

  private async retrieveOrganization(): Promise<Organization> {
    return this.organizationService.getOrganizationByIDMemoize(this.employee.orgId);
  }

  public hasError = (form: FormGroup, errorName: string, controlName?: string) => {
    if (controlName) {
      if ("null" === errorName) {
        return !(!form.controls[controlName].errors);
      }
      return form.controls[controlName].hasError(errorName);
    }
    if ("null" === errorName) {
      return !(!form.errors);
    }
    return form.hasError(errorName);
  };

  async sendInvite(): Promise<void> {
    await this.employeeService.sendAccountInvite(this.employee);
    this.toastr.success("Account Invite Sent");
  }

  changeManager() {
    this.originalManager = this.manager;
    this.manager = null;
  }

  onCancelManagerChangeClick() {
    this.manager = this.originalManager;
    this.changeManagerFG.reset();
  }

  async onChangeManagerClick(value: any) {
    if (this.newManager == null || !(await this.verifyNewManagerIsNotAlreadyASubordinate(this.employee.id, this.newManager.id))) {
      if (this.newManager != null) {
        this.employee = await this.employeeService.updateEmployee({
          id: this.employee.id,
          managerID: this.newManager.id,
          managerChangeDate: moment().format("YYYY-MM-DD")
        });
        // this.manager.subordinateEmployees.push(this.employee);
        this.toastr.success("User assigned to new manager");
        this.dialogRef.close({ employee: this.employee, managerChanged: true, manager: this.newManager });
      } else {
        // Remove Manager
        this.employee = await this.employeeService.updateEmployee({
          id: this.employee.id,
          managerID: null,
          managerChangeDate: moment().format("YYYY-MM-DD")
        });
        this.toastr.success("Manager removed");
        this.dialogRef.close({ employee: this.employee, manager: this.originalManager, managerChanged: true });
      }
    } else {
      this.changeManagerFG.get("isValidOrgStructure").setValue(false);
      this.toastr.error("Invalid Organization Chart Structure");
    }
  }

  private async verifyNewManagerIsNotAlreadyASubordinate(employeeID: string, managerID: string): Promise<boolean> {
    let matched = false;
    const subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:employeeID, includeDisabled:true});
    if (subordinates.map(s => s.id).includes(managerID)) {
      matched = true;
    } else {
      for (const sId of subordinates.map(s => s.id)) {
        matched = await this.verifyNewManagerIsNotAlreadyASubordinate(sId, managerID);
        if (matched) {
          break;
        }
      }
    }
    return matched;
  }

  async removeManagerClick() {
    this.manager = null;
    return this.onChangeManagerClick(null);
  }

  async managerEmailSelected(email: string) {
    const manager = await this.employeeService.getEmployeeByEmail(email, this.user.orgId);
    if (manager) {
      this.newManager = manager;
      this.changeManagerFG.get("isValidEmail").setValue(true);
      if (!await this.verifyNewManagerIsNotAlreadyASubordinate(this.employee.id, this.newManager.id)) {
        this.changeManagerFG.get("isValidOrgStructure").setValue(true);
      } else {
        this.changeManagerFG.get("isValidOrgStructure").setValue(false);
      }
    } else {
      this.newManager = null;
      this.changeManagerFG.get("isValidOrgStructure").setValue(false);
      this.changeManagerFG.get("isValidEmail").setValue(false);
    }
  }
}
