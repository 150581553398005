import { Component, Inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import {
  CadenceObjectiveAssignment,
  Code,
  ExternalCadenceObjectiveAssignment,
  ObjectiveAssignment,
  ObjectiveDomain,
  Organization,
  OrganizationObjective,
  QuantifiableObjectiveAssignment
} from "@inthraction/data-models";
import {
  CADENCE_TYPES,
  ExternalRespondentStatus,
  OBJECTIVE_TYPES,
  ObjectiveType,
  ObjectiveTypeCodes,
  UserDefinedObjectiveDomainTypes
} from "@inthraction/codes";
import {
  ADD_EMPLOYEE_OBJECTIVES_HELP,
  CADENCE_TYPE_LABELS,
  DUE_DATE_HELP,
  END_DATE_HELP,
  FOCUS_HELP,
  FREQUENCY_HELP,
  MANAGER_DEFINED_QUANTIFIABLE_HELP,
  MANAGER_OBJECTIVE_HELP,
  OBJECTIVE_DOMAIN_HELP,
  OBJECTIVE_HELP,
  OBJECTIVE_TITLE_HELP,
  OBJECTIVE_TYPE_HELP,
  OBJECTIVE_TYPE_LABELS,
  QUANTITY_HELP,
  START_DATE_HELP,
  TIME_FRAME_HELP
} from "@inthraction/labels";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  AbstractControl,
  AsyncValidator,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import * as moment from "moment";
import { Moment } from "moment";
import { ConfirmationDialogComponent } from "../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {
  EmployeeService,
  ExternalService,
  ObjectiveService,
  OrganizationService,
  SurveyService
} from "@inthraction/services";
import { DateValidators } from "../../validators/date-validators";
import { Subscription } from "rxjs";
import { MatButton } from "@angular/material/button";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "inthraction-add-edit-employee-objective",
  templateUrl: "./add-edit-employee-objective.component.html",
  styleUrls: ["./add-edit-employee-objective.component.scss"]
})
export class AddEditEmployeeObjectiveComponent implements OnInit, OnDestroy {

  readonly objectiveTypes = OBJECTIVE_TYPES;
  readonly objectiveTypeLabels = OBJECTIVE_TYPE_LABELS;

  readonly objectiveCadenceTypes = CADENCE_TYPES;
  readonly objectiveCadenceTypeLabels = CADENCE_TYPE_LABELS;

  readonly OBJECTIVE_TYPE_HELP = OBJECTIVE_TYPE_HELP;
  readonly ADD_EMPLOYEE_OBJECTIVES_HELP = ADD_EMPLOYEE_OBJECTIVES_HELP;
  readonly OBJECTIVE_DOMAIN_HELP = OBJECTIVE_DOMAIN_HELP;
  readonly OBJECTIVE_HELP = OBJECTIVE_HELP;
  readonly FOCUS_HELP = FOCUS_HELP;
  readonly TIME_FRAME_HELP = TIME_FRAME_HELP;
  readonly MANAGER_OBJECTIVE_HELP = MANAGER_OBJECTIVE_HELP;
  readonly DUE_DATE_HELP = DUE_DATE_HELP;
  readonly MANAGER_DEFINED_QUANTIFIABLE_HELP = MANAGER_DEFINED_QUANTIFIABLE_HELP;
  readonly OBJECTIVE_TITLE_HELP = OBJECTIVE_TITLE_HELP;
  readonly START_DATE_HELP = START_DATE_HELP;
  readonly END_DATE_HELP = END_DATE_HELP;
  readonly FREQUENCY_HELP = FREQUENCY_HELP;
  readonly QUANTITY_HELP = QUANTITY_HELP;

  priorities: Code[];
  focuses: Code[];
  organizationObjectiveDomains: ObjectiveDomain[] = [];
  domainToOrganizationObjectivesMap: Map<string, OrganizationObjective[]>;

  readonly formEditMode: boolean;

  objectiveToEdit: ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment;

  employeeObjective = {
    id: null,
    employeeId: null,
    organizationID: null,
    assignmentDate: null,
    endDate: null,
    startDate: null,
    cadence: null,
    managerDescription: null,
    objectiveAssignmentAssignedById: null,
    objectiveAssignmentPriorityId: null,
    objectiveAssignmentFocusId: null,
    objectiveAssignmentOrgObjectiveId: null,
    objectiveType: null,
    title: null,
    quantity: null,
    nextSurveyDate: null,
    consultantId: null,
    externalEmails: null
  };

  quantifiableObjectiveForm = new FormGroup({
    objectiveTitle: new FormControl("", [Validators.required]),
    managerDescription: new FormControl("", []),
    startDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
    endDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
    cadence: new FormControl("", [Validators.required]),
    quantity: new FormControl("", [Validators.required])
  }, { validators: DateValidators.dateLessThan("startDate", "endDate", { range: true }) });

  predefinedPeerRatedObjectiveForm = new FormGroup({
    managerDescription: new FormControl("", []),
    endDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
    objectiveDomain: new FormControl("", [Validators.required]),
    organizationObjective: new FormControl("", [Validators.required]),
    objectiveFocus: new FormControl("", [Validators.required]),
    objectiveTimeFrame: new FormControl("", [Validators.required])
  });

  predefinedManagerOrSelfRatedObjectiveForm = new FormGroup({
    managerDescription: new FormControl("", []),
    cadence: new FormControl("", [Validators.required]),
    startDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
    endDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
    objectiveDomain: new FormControl("", [Validators.required]),
    organizationObjective: new FormControl("", [Validators.required]),
    objectiveFocus: new FormControl("", [Validators.required]),
    objectiveTimeFrame: new FormControl("", [Validators.required])
  });

  predefinedExternalRatedObjectiveForm = new FormGroup({
    managerDescription: new FormControl("", []),
    cadence: new FormControl("", [Validators.required]),
    startDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
    endDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
    objectiveDomain: new FormControl("", [Validators.required]),
    organizationObjective: new FormControl("", [Validators.required]),
    objectiveFocus: new FormControl("", [Validators.required]),
    objectiveTimeFrame: new FormControl("", [Validators.required]),
    email: new FormControl(false, { validators: [Validators.required, Validators.requiredTrue] })
  });

  externalEmails: string[] = [];

  externalEmailForm = new FormGroup({
    email: new FormControl("", {
      validators: [Validators.required, Validators.email, Validators.pattern(/^\S+@\S+\.\S+$/)],
      asyncValidators: [],
      updateOn: "change"
    })
  });


  objectiveType: ObjectiveType = "DEFINED";
  objectiveForm: FormGroup = this.predefinedPeerRatedObjectiveForm;

  creatingUserType: UserType;

  private consultantDomain: ObjectiveDomain;
  private subscriptions: Subscription[] = [];
  private organization: Organization;

  constructor(
    public dialogRef: MatDialogRef<AddEditEmployeeObjectiveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private objectiveService: ObjectiveService,
    private surveyService: SurveyService,
    private organizationService: OrganizationService,
    private doNotDisturbValidator: DoNotDisturbValidator,
    private toastr: ToastrService
  ) {
    this.formEditMode = data.mode === "edit";
    if (this.formEditMode) {
      this.objectiveToEdit = this.data.objectives[0];
      this.populateEmployeeObjectiveForEdit();
    }

    if (this.data.callingPage === "CONSULTANT") {
      this.creatingUserType = UserType.CONSULTANT;
    } else if (this.data.callingPage === "HR") {
      this.creatingUserType = UserType.HR;
    } else {
      this.creatingUserType = UserType.MANAGER;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit(): Promise<void> {

    if (this.creatingUserType === UserType.CONSULTANT) {
      this.priorities = (await this.objectiveService.getPrioritiesMemoize()).filter(p => p.key === "FUTURE");
    } else {
      this.priorities = await this.objectiveService.getPrioritiesMemoize();
    }

    this.focuses = await this.objectiveService.getFocusMemoize();
    this.organization = await this.organizationService.getOrganizationByIDMemoize(this.data.employee.orgId);

    this.externalEmailForm.get("email").addValidators(outsideDomainValidator(this.organization.domains));
    this.externalEmailForm.get("email").addAsyncValidators(this.doNotDisturbValidator.validate.bind(this.doNotDisturbValidator));

    await this.buildOrganizationDomainObjectivesList();
  }

  onDeleteClick(): void {
    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Delete this objective assignment?"
    });

    this.subscriptions.push(
      deleteDialogRef.afterClosed().subscribe(async result => {
        if (result) {
          await this.objectiveService.deleteObjectiveAssignment(this.employeeObjective.id);
          this.dialogRef.close({ objective: this.objectiveToEdit, deleted: true });
        }
      }));
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async onSaveClick(value: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if (this.formEditMode) {
      await this.saveEditEmployeeObjective(value);
    } else {
      await this.saveNewEmployeeObjective(value);
    }
    submitBtn.disabled = !this.objectiveForm.valid;
  }

  private async buildOrganizationDomainObjectivesList(): Promise<void> {
    const domainToObjectivesMap = new Map<string, OrganizationObjective[]>();
    const objectiveDomainsList: ObjectiveDomain[] = [];

    if (this.creatingUserType === UserType.CONSULTANT) {
      this.consultantDomain = await this.objectiveService.getObjectiveDomainByKey(UserDefinedObjectiveDomainTypes.CONSULTANT_DEFINED);
      const consultantObjectives = await this.objectiveService.getObjectivesForOrganizationByDomainMemoize(this.data.manager.orgId, UserDefinedObjectiveDomainTypes.CONSULTANT_DEFINED);
      if (consultantObjectives && consultantObjectives.length) {
        objectiveDomainsList.push(this.consultantDomain);
        domainToObjectivesMap.set(this.consultantDomain.id, consultantObjectives.map(o => {
              const oo: OrganizationObjective = {
                __typename: "OrganizationObjective",
                businessObjective: "",
                enabled: true,
                id: o.id,
                organizationID: this.data.employee.orgId,
                organizationObjectiveObjectiveId: o.id,
                status: null,
                objective: o
              };
              return oo;
            }
          )
        );
      }
    } else {
      const existingObjectiveIDs = this.data.objectives ? this.data.objectives.map(g => g.id) : [];
      const organizationObjectives = (await this.objectiveService.getOrganizationObjectivesByOrganizationIDMemoize(this.data.employee.orgId))
        .filter(orgObjectives => !existingObjectiveIDs.includes(orgObjectives.objective.id));

      const domainKeys = [];
      for (const objective of organizationObjectives) {
        if (!domainKeys.includes(objective.objective.objectiveDomainId)) {
          objectiveDomainsList.push(objective.objective.domain);
          domainKeys.push(objective.objective.objectiveDomainId);
        }
        if (!domainToObjectivesMap.has(objective.objective.objectiveDomainId)) {
          domainToObjectivesMap.set(objective.objective.objectiveDomainId, [objective]);
        } else {
          const list = domainToObjectivesMap.get(objective.objective.objectiveDomainId);
          if (!(list.map(i => i.id).includes(objective.id))) {
            list.push(objective);
            domainToObjectivesMap.set(objective.objective.objectiveDomainId, list);
          }
        }
      }

    }

    this.domainToOrganizationObjectivesMap = domainToObjectivesMap;
    this.organizationObjectiveDomains = objectiveDomainsList;
  }

  private async saveEditEmployeeObjective(value: any): Promise<void> {
    if (value.endDate && (value.endDate as Moment).isValid()) {
      this.employeeObjective.endDate = moment(value.endDate).format("YYYY-MM-DD");
    }
    this.employeeObjective.managerDescription = value.managerDescription;
    if (this.employeeObjective.managerDescription != null && this.employeeObjective.managerDescription.length <= 0) {
      this.employeeObjective.managerDescription = null;
    }
    if (!this.employeeObjective.objectiveType) {
      this.employeeObjective.objectiveType = value.objectiveType;
    }

    switch (this.employeeObjective.objectiveType) {
      case ObjectiveTypeCodes.DEFINED: {
        this.employeeObjective.title = null;
        this.employeeObjective.cadence = null;
        this.employeeObjective.nextSurveyDate = null;
        this.employeeObjective.startDate = null;
        this.employeeObjective.quantity = null;

        this.employeeObjective.objectiveAssignmentFocusId = value.objectiveFocus;
        this.employeeObjective.objectiveAssignmentPriorityId = value.objectiveTimeFrame;
        break;
      }
      case ObjectiveTypeCodes.QUANTIFIABLE: {
        this.employeeObjective.objectiveAssignmentFocusId = null;
        this.employeeObjective.objectiveAssignmentOrgObjectiveId = null;
        this.employeeObjective.objectiveAssignmentPriorityId = null;

        this.employeeObjective.title = value.objectiveTitle;

        if (value.startDate && (value.startDate as Moment).isValid()) {
          this.employeeObjective.startDate = moment(value.startDate).format("YYYY-MM-DD");
        }
        if (this.employeeObjective.cadence !== value.cadence) {
          this.employeeObjective.cadence = value.cadence;
          const lastSurveyDate = await this.getTheLastSurveyDate(this.employeeObjective.id);
          this.employeeObjective.nextSurveyDate = SurveyService.calculateNextSurveyDate(lastSurveyDate, this.employeeObjective.cadence, this.employeeObjective.endDate);
        }
        break;
      }
      case ObjectiveTypeCodes.DEFINED_SELF : // Same as DEFINED_MANAGER
      case ObjectiveTypeCodes.DEFINED_MANAGER: {
        this.employeeObjective.title = null;
        this.employeeObjective.quantity = null;

        this.employeeObjective.objectiveAssignmentFocusId = value.objectiveFocus;
        this.employeeObjective.objectiveAssignmentPriorityId = value.objectiveTimeFrame;

        if (value.startDate && (value.startDate as Moment).isValid()) {
          this.employeeObjective.startDate = moment(value.startDate).format("YYYY-MM-DD");
        }
        if (this.employeeObjective.cadence !== value.cadence) {
          this.employeeObjective.cadence = value.cadence;
          const lastSurveyDate = await this.getTheLastSurveyDate(this.employeeObjective.id);
          if (lastSurveyDate < this.employeeObjective.startDate) {
            this.employeeObjective.nextSurveyDate = this.employeeObjective.startDate;
          } else {
            this.employeeObjective.nextSurveyDate = SurveyService.calculateNextSurveyDate(lastSurveyDate, this.employeeObjective.cadence, this.employeeObjective.endDate);
          }
        }
        break;
      }
      case ObjectiveTypeCodes.DEFINED_EXTERNAL: {
        this.employeeObjective.title = null;
        this.employeeObjective.quantity = null;

        this.employeeObjective.objectiveAssignmentPriorityId = value.objectiveTimeFrame;
        this.employeeObjective.objectiveAssignmentFocusId = value.objectiveFocus;

        if (value.startDate && (value.startDate as Moment).isValid()) {
          this.employeeObjective.startDate = moment(value.startDate).format("YYYY-MM-DD");
        }

        if (this.employeeObjective.cadence !== value.cadence) {
          this.employeeObjective.cadence = value.cadence;
          const lastSurveyDate = await this.getTheLastSurveyDate(this.employeeObjective.id);
          if (lastSurveyDate < this.employeeObjective.startDate) {
            this.employeeObjective.nextSurveyDate = this.employeeObjective.startDate;
          } else {
            this.employeeObjective.nextSurveyDate = SurveyService.calculateNextSurveyDate(lastSurveyDate, this.employeeObjective.cadence, this.employeeObjective.endDate);
          }
        }
        this.employeeObjective.externalEmails = this.externalEmails;
        break;
      }
      default: {
        throw new Error("Undefined Objective Type");
      }
    }

    const objective = await this.objectiveService.updateObjectiveAssignment(this.employeeObjective);
    this.toastr.success("Objective Assignment updated successfully");
    this.dialogRef.close({ objective });
  }

  private async saveNewEmployeeObjective(value: any): Promise<void> {
    this.employeeObjective.organizationID = this.data.employee.orgId;
    if (this.creatingUserType === UserType.CONSULTANT) {
      this.employeeObjective.consultantId = this.data.manager.id;
    }
    this.employeeObjective.objectiveType = this.objectiveType;
    this.employeeObjective.assignmentDate = moment().format("YYYY-MM-DD");
    if (value.endDate && (value.endDate as Moment).isValid()) {
      this.employeeObjective.endDate = moment(value.endDate).format("YYYY-MM-DD");
    }
    this.employeeObjective.employeeId = this.data.employee.id;
    this.employeeObjective.objectiveAssignmentAssignedById = this.data.manager.id;
    this.employeeObjective.managerDescription = value.managerDescription;
    if (this.employeeObjective.managerDescription != null && this.employeeObjective.managerDescription.length <= 0) {
      this.employeeObjective.managerDescription = null;
    }

    switch (this.employeeObjective.objectiveType) {
      case ObjectiveTypeCodes.DEFINED: {
        this.employeeObjective.title = null;
        this.employeeObjective.cadence = null;
        this.employeeObjective.nextSurveyDate = null;
        this.employeeObjective.startDate = null;
        this.employeeObjective.quantity = null;

        this.employeeObjective.objectiveAssignmentFocusId = value.objectiveFocus;
        this.employeeObjective.objectiveAssignmentPriorityId = value.objectiveTimeFrame;
        if (this.creatingUserType === UserType.CONSULTANT && value.objectiveDomain === this.consultantDomain.id) {
          this.employeeObjective.objectiveAssignmentOrgObjectiveId = await this.findOrCreateOrganizationDomainForConsultantObjective(this.data.employee.orgId, value.organizationObjective);
        } else {
          this.employeeObjective.objectiveAssignmentOrgObjectiveId = value.organizationObjective;
        }
        break;
      }
      case ObjectiveTypeCodes.QUANTIFIABLE: {
        this.employeeObjective.objectiveAssignmentFocusId = null;
        this.employeeObjective.objectiveAssignmentOrgObjectiveId = null;
        this.employeeObjective.objectiveAssignmentPriorityId = null;

        if (value.startDate && (value.startDate as Moment).isValid()) {
          this.employeeObjective.startDate = moment(value.startDate).format("YYYY-MM-DD");
        }
        this.employeeObjective.cadence = value.cadence;
        this.employeeObjective.title = value.objectiveTitle;
        this.employeeObjective.quantity = value.quantity;

        this.employeeObjective.nextSurveyDate = SurveyService.calculateNextSurveyDate(value.startDate, this.employeeObjective.cadence, this.employeeObjective.endDate);
        break;
      }
      case ObjectiveTypeCodes.DEFINED_SELF: // Same as DEFINED_MANAGER
      case ObjectiveTypeCodes.DEFINED_MANAGER: {
        this.employeeObjective.title = null;
        this.employeeObjective.quantity = null;

        this.employeeObjective.objectiveAssignmentPriorityId = value.objectiveTimeFrame;
        this.employeeObjective.objectiveAssignmentFocusId = value.objectiveFocus;
        if (this.creatingUserType === UserType.CONSULTANT && value.objectiveDomain === this.consultantDomain.id) {
          this.employeeObjective.objectiveAssignmentOrgObjectiveId = await this.findOrCreateOrganizationDomainForConsultantObjective(this.data.employee.orgId, value.organizationObjective);
        } else {
          this.employeeObjective.objectiveAssignmentOrgObjectiveId = value.organizationObjective;
        }

        if (value.startDate && (value.startDate as Moment).isValid()) {
          this.employeeObjective.startDate = moment(value.startDate).format("YYYY-MM-DD");
        }
        this.employeeObjective.cadence = value.cadence;
        // this.employeeObjective.nextSurveyDate = SurveyService.calculateNextSurveyDate(value.startDate, this.employeeObjective.cadence, this.employeeObjective.endDate);
        this.employeeObjective.nextSurveyDate = this.employeeObjective.startDate;
        break;
      }
      case ObjectiveTypeCodes.DEFINED_EXTERNAL: {
        this.employeeObjective.title = null;
        this.employeeObjective.quantity = null;

        this.employeeObjective.objectiveAssignmentPriorityId = value.objectiveTimeFrame;
        this.employeeObjective.objectiveAssignmentFocusId = value.objectiveFocus;
        if (this.creatingUserType === UserType.CONSULTANT && value.objectiveDomain === this.consultantDomain.id) {
          this.employeeObjective.objectiveAssignmentOrgObjectiveId = await this.findOrCreateOrganizationDomainForConsultantObjective(this.data.employee.orgId, value.organizationObjective);
        } else {
          this.employeeObjective.objectiveAssignmentOrgObjectiveId = value.organizationObjective;
        }

        if (value.startDate && (value.startDate as Moment).isValid()) {
          this.employeeObjective.startDate = moment(value.startDate).format("YYYY-MM-DD");
        }
        this.employeeObjective.cadence = value.cadence;
        // this.employeeObjective.nextSurveyDate = SurveyService.calculateNextSurveyDate(value.startDate, this.employeeObjective.cadence, this.employeeObjective.endDate);
        this.employeeObjective.nextSurveyDate = this.employeeObjective.startDate;
        this.employeeObjective.externalEmails = this.externalEmails;
        break;
      }
      default: {
        throw new Error("Undefined Objective Type");
      }
    }

    const objective = await this.objectiveService.createObjectiveAssignment(this.employeeObjective);
    this.dialogRef.close({ objective });
  }

  private populateEmployeeObjectiveForEdit(): void {
    this.employeeObjective.objectiveType = this.objectiveToEdit.objectiveType ? this.objectiveToEdit.objectiveType : "DEFINED";
    this.changeObjectiveType(this.employeeObjective.objectiveType);

    this.employeeObjective.organizationID = this.objectiveToEdit.organizationID;
    this.employeeObjective.consultantId = this.objectiveToEdit.consultantId;

    switch (this.employeeObjective.objectiveType) {
      case ObjectiveTypeCodes.DEFINED: {
        this.employeeObjective.objectiveAssignmentPriorityId = (this.objectiveToEdit as ObjectiveAssignment).priority.id;
        this.employeeObjective.objectiveAssignmentOrgObjectiveId = (this.objectiveToEdit as ObjectiveAssignment).orgObjective.id;
        this.employeeObjective.objectiveAssignmentFocusId = (this.objectiveToEdit as ObjectiveAssignment).focus.id;

        this.objectiveForm.get("objectiveDomain").setValue((this.objectiveToEdit as ObjectiveAssignment).orgObjective.objective.domain.id);
        this.objectiveForm.get("objectiveDomain").disable();

        this.objectiveForm.get("objectiveFocus").setValue((this.objectiveToEdit as ObjectiveAssignment).focus.id);

        this.objectiveForm.get("objectiveTimeFrame").setValue((this.objectiveToEdit as ObjectiveAssignment).priority.id);

        this.objectiveForm.get("organizationObjective").setValue((this.objectiveToEdit as ObjectiveAssignment).orgObjective.id);
        this.objectiveForm.get("organizationObjective").disable();
        break;
      }
      case ObjectiveTypeCodes.QUANTIFIABLE: {
        this.employeeObjective.nextSurveyDate = (this.objectiveToEdit as QuantifiableObjectiveAssignment).nextSurveyDate;

        this.employeeObjective.startDate = (this.objectiveToEdit as QuantifiableObjectiveAssignment).startDate;
        const startDateFormControl = this.objectiveForm.get("startDate");
        if ((this.objectiveToEdit as QuantifiableObjectiveAssignment).startDate) {
          startDateFormControl.setValue(moment((this.objectiveToEdit as QuantifiableObjectiveAssignment).startDate, "YYYY-MM-DD"));
          if (this.employeeObjective.startDate <= moment().format("YYYY-MM-DD")) {
            // If start date has already passed, disable the field.
            startDateFormControl.setValidators(null);
            startDateFormControl.disable();
          }
        }

        this.employeeObjective.cadence = (this.objectiveToEdit as QuantifiableObjectiveAssignment).cadence;
        this.objectiveForm.get("cadence").setValue((this.objectiveToEdit as QuantifiableObjectiveAssignment).cadence);

        this.employeeObjective.title = (this.objectiveToEdit as QuantifiableObjectiveAssignment).title;
        this.objectiveForm.get("objectiveTitle").setValue((this.objectiveToEdit as QuantifiableObjectiveAssignment).title);

        this.employeeObjective.quantity = (this.objectiveToEdit as QuantifiableObjectiveAssignment).quantity;
        this.objectiveForm.get("quantity").setValue((this.objectiveToEdit as QuantifiableObjectiveAssignment).quantity);
        this.objectiveForm.get("quantity").disable();
        break;
      }
      case ObjectiveTypeCodes.DEFINED_SELF: // Same as Defined Manager
      case ObjectiveTypeCodes.DEFINED_MANAGER: {
        this.employeeObjective.objectiveAssignmentPriorityId = (this.objectiveToEdit as CadenceObjectiveAssignment).priority.id;
        this.employeeObjective.objectiveAssignmentOrgObjectiveId = (this.objectiveToEdit as CadenceObjectiveAssignment).orgObjective.id;
        this.employeeObjective.objectiveAssignmentFocusId = (this.objectiveToEdit as CadenceObjectiveAssignment).focus.id;

        this.objectiveForm.get("objectiveDomain").setValue((this.objectiveToEdit as CadenceObjectiveAssignment).orgObjective.objective.domain.id);
        this.objectiveForm.get("objectiveDomain").disable();

        this.objectiveForm.get("objectiveFocus").setValue((this.objectiveToEdit as CadenceObjectiveAssignment).focus.id);

        this.objectiveForm.get("objectiveTimeFrame").setValue((this.objectiveToEdit as CadenceObjectiveAssignment).priority.id);

        this.objectiveForm.get("organizationObjective").setValue((this.objectiveToEdit as CadenceObjectiveAssignment).orgObjective.id);
        this.objectiveForm.get("organizationObjective").disable();

        this.employeeObjective.nextSurveyDate = (this.objectiveToEdit as CadenceObjectiveAssignment).nextSurveyDate;

        this.employeeObjective.startDate = (this.objectiveToEdit as CadenceObjectiveAssignment).startDate;
        const startDateFormControl = this.objectiveForm.get("startDate");
        if ((this.objectiveToEdit as CadenceObjectiveAssignment).startDate) {
          startDateFormControl.setValue(moment((this.objectiveToEdit as CadenceObjectiveAssignment).startDate, "YYYY-MM-DD"));
          if (this.employeeObjective.startDate <= moment().format("YYYY-MM-DD")) {
            // If start date has already passed, disable the field.
            startDateFormControl.setValidators(null);
            startDateFormControl.disable();
          }
        }

        this.employeeObjective.cadence = (this.objectiveToEdit as CadenceObjectiveAssignment).cadence;
        this.objectiveForm.get("cadence").setValue((this.objectiveToEdit as CadenceObjectiveAssignment).cadence);

        break;
      }
      case ObjectiveTypeCodes.DEFINED_EXTERNAL: {
        this.employeeObjective.objectiveAssignmentPriorityId = (this.objectiveToEdit as ExternalCadenceObjectiveAssignment).priority.id;
        this.employeeObjective.objectiveAssignmentOrgObjectiveId = (this.objectiveToEdit as ExternalCadenceObjectiveAssignment).orgObjective.id;
        this.employeeObjective.objectiveAssignmentFocusId = (this.objectiveToEdit as ExternalCadenceObjectiveAssignment).focus.id;

        this.objectiveForm.get("objectiveDomain").setValue((this.objectiveToEdit as ExternalCadenceObjectiveAssignment).orgObjective.objective.domain.id);
        this.objectiveForm.get("objectiveDomain").disable();

        this.objectiveForm.get("objectiveFocus").setValue((this.objectiveToEdit as ExternalCadenceObjectiveAssignment).focus.id);

        this.objectiveForm.get("objectiveTimeFrame").setValue((this.objectiveToEdit as ExternalCadenceObjectiveAssignment).priority.id);

        this.objectiveForm.get("organizationObjective").setValue((this.objectiveToEdit as ExternalCadenceObjectiveAssignment).orgObjective.id);
        this.objectiveForm.get("organizationObjective").disable();

        this.employeeObjective.nextSurveyDate = (this.objectiveToEdit as ExternalCadenceObjectiveAssignment).nextSurveyDate;

        this.employeeObjective.startDate = (this.objectiveToEdit as ExternalCadenceObjectiveAssignment).startDate;
        const startDateFormControl = this.objectiveForm.get("startDate");
        if ((this.objectiveToEdit as ExternalCadenceObjectiveAssignment).startDate) {
          startDateFormControl.setValue(moment((this.objectiveToEdit as ExternalCadenceObjectiveAssignment).startDate, "YYYY-MM-DD"));
          if (this.employeeObjective.startDate <= moment().format("YYYY-MM-DD")) {
            // If start date has already passed, disable the field.
            startDateFormControl.setValidators(null);
            startDateFormControl.disable();
          }
        }

        this.employeeObjective.cadence = (this.objectiveToEdit as ExternalCadenceObjectiveAssignment).cadence;
        this.objectiveForm.get("cadence").setValue((this.objectiveToEdit as ExternalCadenceObjectiveAssignment).cadence);
        this.objectiveForm.get("email").setValue((this.objectiveToEdit as ExternalCadenceObjectiveAssignment).externalEmails?.length > 0);
        this.externalEmails.push(...(this.objectiveToEdit as ExternalCadenceObjectiveAssignment).externalEmails);
        break;
      }
      default: {
        throw new Error("Undefined Objective Type");
      }
    }

    this.employeeObjective.id = this.objectiveToEdit.id;
    this.employeeObjective.assignmentDate = this.objectiveToEdit.assignmentDate;

    this.employeeObjective.managerDescription = this.objectiveToEdit.managerDescription;
    this.objectiveForm.get("managerDescription").setValue(this.objectiveToEdit.managerDescription);

    this.employeeObjective.endDate = this.objectiveToEdit.endDate;
    if (this.objectiveToEdit.endDate) {
      this.objectiveForm.get("endDate").setValue(moment(this.objectiveToEdit.endDate, "YYYY-MM-DD"));
    }

    this.employeeObjective.employeeId = this.objectiveToEdit.employeeId;
    this.employeeObjective.objectiveAssignmentAssignedById = this.objectiveToEdit.objectiveAssignmentAssignedById;

  }

  public hasError = (controlName: string, errorName: string, formGroup: FormGroup = this.objectiveForm) => {
    if (controlName && formGroup.controls[controlName]) {
      return formGroup.controls[controlName].hasError(errorName);
    }
    return formGroup.hasError(errorName);
  };

  private async getTheLastSurveyDate(id: string): Promise<string> {
    const surveys = await this.surveyService.getSurveysByObjectID(id);
    let lastSurveyDate = surveys.map(s => s.surveyDate).reduce((a, b) => {
      if (moment(a).isBefore(moment(b))) {
        return b;
      }
      return a;
    }, "1900-01-01");
    return lastSurveyDate ? lastSurveyDate : moment().format("YYYY-MM-DD");
  }

  changeObjectiveType(objectiveType: any) {
    this.objectiveType = objectiveType;

    switch (this.objectiveType) {
      case ObjectiveTypeCodes.DEFINED: {
        this.objectiveForm = this.predefinedPeerRatedObjectiveForm;
        break;
      }
      case ObjectiveTypeCodes.QUANTIFIABLE: {
        this.objectiveForm = this.quantifiableObjectiveForm;
        break;
      }
      case ObjectiveTypeCodes.DEFINED_EXTERNAL: {
        this.objectiveForm = this.predefinedExternalRatedObjectiveForm;
        break;
      }
      case ObjectiveTypeCodes.DEFINED_SELF:
      case ObjectiveTypeCodes.DEFINED_MANAGER: {
        this.objectiveForm = this.predefinedManagerOrSelfRatedObjectiveForm;
        break;
      }
      default : {
        throw new Error("Undefined Objective Type");
      }
    }
  }

  getObjectiveTitles(id: string): string {
    return this.domainToOrganizationObjectivesMap.get(id)
      .map(o => o.objective.display)
      .join(", ");
  }

  private async findOrCreateOrganizationDomainForConsultantObjective(organizationID: string, organizationObjectiveObjectiveId: string): Promise<string> {
    let organizationObjective: OrganizationObjective = await this.objectiveService.getOrganizationObjectivesByOrganizationIDAndObjectiveID(organizationID, organizationObjectiveObjectiveId);
    if (!organizationObjective) {
      organizationObjective = await this.objectiveService.createNewOrganizationObjective(
        {
          organizationID,
          organizationObjectiveObjectiveId,
          enabled: true
        });
    }
    return organizationObjective.id;
  }

  onAddExternalEmailClick(value: any, submitBtn: MatButton) {
    if (value && !this.externalEmails.includes(value.email)) {
      this.externalEmails.push(value.email);
    }
    this.externalEmailForm.reset({ email: "" }, { emitEvent: true });
    this.objectiveForm.get("email").setValue(this.externalEmails?.length > 0);
  }

  onRemoveExternalEmailClick(email: string) {
    this.externalEmails = this.externalEmails.filter(e => e !== email);
    this.objectiveForm.get("email").setValue(this.externalEmails?.length > 0);
  }
}

export enum UserType {
  CONSULTANT,
  HR,
  MANAGER
}

@Injectable({ providedIn: "root" })
export class DoNotDisturbValidator implements AsyncValidator {
  constructor(private externalService: ExternalService) {
  }

  async validate(control: AbstractControl): Promise<ValidationErrors | null> {

    if (control.hasError("email") || control.hasError("pattern") || control.hasError("required") || control.hasError("outsideDomain")) {
      return null;
    }

    const externalRespondent = await this.externalService.getExternalRespondentByEmail(control.value, ExternalRespondentStatus.DO_NOT_DISTURB);
    return new Promise<ValidationErrors | null>((resolve, reject) => {
      if (externalRespondent) {
        resolve({ doNotDisturb: true });
      } else {
        resolve(null);
      }
    });
  }
}


export function outsideDomainValidator(domains: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.hasError("email") || control.hasError("pattern") || control.hasError("required")) {
      return null;
    }
    const forbidden = domains.includes(control.value.split("@")[1]);
    return forbidden ? { outsideDomain: true } : null;
  };
}
