<h1 mat-dialog-title>{{addMode ? 'Add' : 'Edit'}} {{UserDefinedObjectiveDomainTypes.ORG_DEFINED == objective.domain.key ? 'Organization Defined' : ''}} Objective</h1>
<form class="add-org-defined-objective" (ngSubmit)="onSaveClick(objectiveForm.value, submitBtn)" [formGroup]="objectiveForm" autocomplete="off" novalidate>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Objective Title</mat-label>
      <input matInput formControlName="objectiveTitle">
      <mat-error *ngIf="hasError('objectiveTitle', 'required')">Title is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Objective Definition</mat-label>
      <textarea formControlName="objectiveDefinition" matInput maxlength="500" rows="5"></textarea>
      <mat-error *ngIf="hasError('objectiveDefinition', 'required')">Definition is required</mat-error>
    </mat-form-field>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-subtitle>Observables</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngFor="let observable of objective.observables; let i = index">
          <mat-form-field>
            <mat-label>Objective Observable {{i + 1}}</mat-label>
            <textarea matInput [(ngModel)]="objective.observables[i]" [ngModelOptions]="{standalone: true, updateOn: 'blur'}"></textarea>
          </mat-form-field>
        </ng-container>
      </mat-card-content>
      <mat-card-actions>
        <a (click)="objective.observables.push('')" aria-label="Add New Observable" mat-icon-button
           title="Add New Observable">
          <mat-icon>add</mat-icon>
        </a>
      </mat-card-actions>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-subtitle>Manager Hints</mat-card-subtitle>
      </mat-card-header>
      <ng-container *ngFor="let hint of objective.hints; let i = index">
        <mat-card-content>
          <mat-form-field>
            <mat-label>Objective Hint {{i + 1}}</mat-label>
            <textarea matInput [(ngModel)]="objective.hints[i]" [ngModelOptions]="{standalone: true, updateOn: 'blur'}"></textarea>
          </mat-form-field>
        </mat-card-content>
      </ng-container>
      <mat-card-actions>
        <a (click)="objective.hints.push('')" aria-label="Add New Hint" mat-icon-button title="Add New Hint">
          <mat-icon>add</mat-icon>
        </a>
      </mat-card-actions>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
    <button #submitBtn [disabled]="!objectiveForm.valid" color="primary" mat-raised-button type="submit">Save</button>
  </div>
</form>
