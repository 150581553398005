import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { EmployeeService, OrganizationService, SurveyService } from "@inthraction/services";
import { Employee, Survey } from "@inthraction/data-models";
import { OrganizationConfigurationCodes, SurveyStatus } from "@inthraction/codes";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-organization-survey-statistics",
  templateUrl: "./organization-survey-statistics.component.html",
  styleUrls: ["./organization-survey-statistics.component.scss"]
})
export class OrganizationSurveyStatisticsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  tableData: MatTableDataSource<TableRow> = new MatTableDataSource<TableRow>([]);
  readonly displayedColumns: string[] = ["organizationName", "activeEmployees", "inthractionCountConfig", "eventSurveyConfig", "inthractionExpirationConfig", "objectiveExpirationConfig", "intHRactionSurveysSent30", "intHRactionEmployeesSurveyed30", "intHRactionSurveyParticipants30", "intHRactionSurveysMissed30", "objectiveSurveysSent30", "objectiveEmployeesSurveyed30", "objectiveSurveyParticipants30", "objectiveSurveysMissed30"];

  constructor(
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    private surveyService: SurveyService
  ) {
  }

  ngAfterViewInit() {
    if (this.tableData) {
      this.tableData.sort = this.sort;
    }
  }

  async ngOnInit() {
    this.tableData.data = await this.initilizeTableData();
  }

  private async initilizeTableData(): Promise<TableRow[]> {

    const organizations = await this.organizationService.getOrganizations();
    const inthractionCountConfigurations = await this.organizationService.getConfigurationForAllOrganizations(OrganizationConfigurationCodes.OBJECTIVE_INTHRACTION_COUNT);
    const eventSurveyConfigurations = await this.organizationService.getConfigurationForAllOrganizations(OrganizationConfigurationCodes.EVENT_SURVEY_DISABLED);
    const eventSurveyMissedConfigurations = await this.organizationService.getConfigurationForAllOrganizations(OrganizationConfigurationCodes.EVENT_MISSED_DAYS);
    const objectiveSurveyMissedConfigurations = await this.organizationService.getConfigurationForAllOrganizations(OrganizationConfigurationCodes.OBJECTIVE_MISSED_DAYS);

    const eventSurveys = await this.surveyService.getAllEventSurveysForRange();
    const objectiveSurveys = await this.surveyService.getAllObjectiveSurveysForRange();


    const tableData = [];
    for (const organization of organizations) {
      const inthractionCountConfiguration = inthractionCountConfigurations.find(c => c.organizationID === organization.id);
      const eventSurveyConfiguration = eventSurveyConfigurations.find(c => c.organizationID === organization.id);
      const eventSurveyMissedConfiguration = eventSurveyMissedConfigurations.find(c => c.organizationID === organization.id);
      const objectiveSurveyMissedConfiguration = objectiveSurveyMissedConfigurations.find(c => c.organizationID === organization.id);
      const employees = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:false, organizationID:organization.id, memoize:true});

      let eventSurveyStats: { missedCount: number, totalSurveys: number, uniqueEmployeesSurveyed: number, uniqueParticipants: number };
      if (eventSurveyConfiguration ? !eventSurveyConfiguration.configBooleanValue : true) {
        eventSurveyStats = await this.processEventSurveysForOrganization(organization.id, employees, eventSurveys);
      }

      let objectiveSurveyStats: { missedCount: number, totalSurveys: number, uniqueEmployeesSurveyed: number, uniqueParticipants: number };
      objectiveSurveyStats = await this.processObjectiveSurveysForOrganization(organization.id, employees, objectiveSurveys);

      tableData.push(
        {
          organizationName: organization.orgName,
          inthractionCountConfig: inthractionCountConfiguration ? inthractionCountConfiguration?.configIntValue : 10,
          eventSurveyConfig: eventSurveyConfiguration ? !eventSurveyConfiguration.configBooleanValue : true,
          inthractionExpirationConfig: eventSurveyMissedConfiguration ? eventSurveyMissedConfiguration?.configIntValue : 2,
          objectiveExpirationConfig: objectiveSurveyMissedConfiguration ? objectiveSurveyMissedConfiguration?.configIntValue : 2,
          intHRactionSurveysSent30: eventSurveyStats ? eventSurveyStats?.totalSurveys : 0,
          intHRactionSurveysMissed30: eventSurveyStats ? eventSurveyStats?.missedCount : 0,
          intHRactionEmployeesSurveyed30: eventSurveyStats ? eventSurveyStats?.uniqueEmployeesSurveyed : 0,
          intHRactionSurveyParticipants30: eventSurveyStats ? eventSurveyStats?.uniqueParticipants : 0,
          activeEmployees: employees.length,
          objectiveSurveysSent30: objectiveSurveyStats ? objectiveSurveyStats?.totalSurveys : 0,
          objectiveSurveysMissed30: objectiveSurveyStats ? objectiveSurveyStats?.missedCount : 0,
          objectiveEmployeesSurveyed30: objectiveSurveyStats ? objectiveSurveyStats?.uniqueEmployeesSurveyed : 0,
          objectiveSurveyParticipants30: objectiveSurveyStats ? objectiveSurveyStats?.uniqueParticipants : 0
        } as TableRow
      );
    }
    return tableData;
  }

  private async processEventSurveysForOrganization(id: string, employees: Employee[], eventSurveys: Survey[]) {

    const emailList = employees.map(e => e.email);
    const orgSurveys = eventSurveys.filter(s => emailList.includes(s.respondentEmail) && s.status !== SurveyStatus.DELETED);
    const missedSurveys = orgSurveys.filter(s => s.status === SurveyStatus.MISSED);

    const respondentList = orgSurveys.map(s => s.respondentEmail);
    const respondentSet = new Set(respondentList);

    const participantList = orgSurveys.map(s => s.participantEmail);
    const participantSet = new Set(participantList);

    return {
      missedCount: missedSurveys.length,
      totalSurveys: orgSurveys.length,
      uniqueEmployeesSurveyed: respondentSet.size,
      uniqueParticipants: participantSet.size
    };
  }

  private async processObjectiveSurveysForOrganization(id: string, employees: Employee[], objectiveSurveys: Survey[]) {

    const emailList = employees.map(e => e.email);
    const orgSurveys = objectiveSurveys.filter(s => emailList.includes(s.respondentEmail) && s.status !== SurveyStatus.DELETED);

    //TODO filter for Objective Surveys based on intHRactions (not cadence based)

    const missedSurveys = orgSurveys.filter(s => s.status === SurveyStatus.MISSED);

    const respondentList = orgSurveys.map(s => s.respondentEmail);
    const respondentSet = new Set(respondentList);


    const participantList = orgSurveys.map(s => s.participantEmail);
    const participantSet = new Set(participantList);

    return {
      missedCount: missedSurveys.length,
      totalSurveys: orgSurveys.length,
      uniqueEmployeesSurveyed: respondentSet.size,
      uniqueParticipants: participantSet.size
    };
  }
}

interface TableRow {
  organizationName: string;
  inthractionCountConfig: number;
  inthractionExpirationConfig: number;
  objectiveExpirationConfig: number;
  eventSurveyConfig: boolean;
  activeEmployees: number;
  intHRactionSurveysSent30: number;
  intHRactionSurveysMissed30: number;
  intHRactionEmployeesSurveyed30: number;
  intHRactionSurveyParticipants30: number;
  objectiveSurveysSent30: number;
  objectiveSurveysMissed30: number;
  objectiveSurveyParticipants30: number;
}
