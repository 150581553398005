import { Component, OnInit } from "@angular/core";
import { InspHRationTypeLabels } from "@inthraction/labels";
import { InspHRationCounterTypeCodes } from "@inthraction/codes";
import { Employee, EmployeeImageInterface, OrganizationShoutOutType } from "@inthraction/data-models";
import { EmployeeService, InspHRationCounterType, ShoutOutService } from "@inthraction/services";
import { MatDialog } from "@angular/material/dialog";
import {
  InsphrationResultListDialogComponent,
  InspHRationResultsListData
} from "../../../components/shared/dialogs/insphration-result-list-dialog/insphration-result-list-dialog.component";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
  selector: "inthraction-insphration-leader-board",
  templateUrl: "./insphration-leader-board.component.html",
  styleUrls: ["./insphration-leader-board.component.scss"]
})
export class InsphrationLeaderBoardComponent implements OnInit {

  insphrations: OrganizationShoutOutType[];
  employees: Employee[];
  employeeMap: Map<string, Employee> = new Map<string, Employee>();
  employeeImageMap: Map<string, EmployeeImageInterface> = new Map<string, EmployeeImageInterface>();
  employeeIDs: string[];
  readonly tabOptionLabels = InspHRationTypeLabels;
  readonly tabOptions = Object.keys(InspHRationCounterTypeCodes).reverse();
  private scoreMap: Map<InspHRationCounterType, Map<string, number>> = new Map<InspHRationCounterType, Map<string, number>>();
  private scoreTotalMap: Map<InspHRationCounterType, Map<string, number>> = new Map<InspHRationCounterType, Map<string, number>>();
  selectedType = InspHRationCounterType.YEAR;
  indexMap: Map<string, number> = new Map<string, number>();

  constructor(
    private shoutOutService: ShoutOutService,
    private employeeService: EmployeeService,
    public dialog: MatDialog
  ) {
    let i = 0;
    for (const type of (Object.keys(InspHRationCounterType).reverse())) {
      this.indexMap.set(type, i);
      i++;
    }
  }

  async ngOnInit() {
    this.insphrations = await this.shoutOutService.getActiveOrganizationShoutOuts();
    this.employees = await this.employeeService.getEmployeesForOrganizationByOrganization({memoize:true});
    const employeeImages:EmployeeImageInterface[] = await this.employeeService.getEmployeeImagesByOrganizationMemoize(this.employees[0]?.orgId);
    for (const employee of this.employees) {
      this.employeeMap.set(employee.id, employee);
      this.employeeImageMap.set(employee.id,  employeeImages.find( i => i.employeeID == employee.id ));
    }
    await this.getEmployeeScores(this.selectedType);
  }

  async openInspHRationResponseList(employeeId: string, insphration: OrganizationShoutOutType, type: InspHRationCounterType) {
    const employee = await this.employeeService.getEmployeeByIDMemoize(employeeId);

    this.dialog.open(InsphrationResultListDialogComponent, {
      width: "600px",
      data: {
        employee,
        type,
        orgInspHRation: insphration
      } as InspHRationResultsListData
    });

  }

  async changeTab($event: MatTabChangeEvent) {
    this.employeeIDs = undefined;
    this.selectedType = InspHRationCounterType[this.tabOptions[$event.index]];
    await this.getEmployeeScores(this.selectedType);
  }

  public getScoreTotal(employeeID: string) {
    if (this.scoreTotalMap.has(this.selectedType)) {
      return this.scoreTotalMap.get(this.selectedType).get(employeeID);
    }
  }

  public getScore(key: string) {
    if (this.scoreMap.has(this.selectedType)) {
      return this.scoreMap.get(this.selectedType).get(key);
    }
  }

  private async getEmployeeScores(type: InspHRationCounterType) {
    if (!this.scoreMap.has(type)) {
      await this.loadScoreMap();
    }
    const employeeIDs: string[] = Array.from(this.employeeMap.keys());
    employeeIDs.sort((b, a) =>
      this.scoreTotalMap.get(type).get(a) > this.scoreTotalMap.get(type).get(b) ? 1 : (this.scoreTotalMap.get(type).get(a) < this.scoreTotalMap.get(type).get(b) ? -1 : (`${this.employeeMap.get(b).lastName},${this.employeeMap.get(b).firstName}`.localeCompare(`${this.employeeMap.get(a).lastName},${this.employeeMap.get(a).firstName}`)))
    );
    this.employeeIDs = employeeIDs;
  }

  private async loadScoreMap() {
    const organizationInspHRationCounts = await this.shoutOutService.getInspHRationCountsForOrganization();
    for (const typeKey of Object.keys(InspHRationCounterType)) {
      const type = InspHRationCounterType[typeKey];
      this.scoreMap.set(type, new Map<string, number>());
      this.scoreTotalMap.set(type, new Map<string, number>());

      for (const employee of this.employees) {
        let total = 0;
        const employeeInspHRationCounts = organizationInspHRationCounts.filter(c => c.employeeID == employee.id && c.type == type)

        for (const inspHRationCount of employeeInspHRationCounts) {
          this.scoreMap.get(type).set(`${inspHRationCount.employeeID}_${inspHRationCount.organizationShoutOutTypeID}`, inspHRationCount.count);
          total = total + inspHRationCount.count;
        }
        this.scoreTotalMap.get(type).set(employee.id, total);
      }
    }
  }
}
