import { Employee, ObjectiveAssignment, OrganizationObjective } from "@inthraction/data-models";
import * as moment from "moment";
import { Moment } from "moment";
import { DistributionDetails } from "./DistributionDetails";
import { OBJECTIVE_SURVEY_RESPONSE_VALUES, OrganizationConfigurationCodes } from "@inthraction/codes";
import { EmployeeService, ObjectiveService, OrganizationService, SurveyService } from "@inthraction/services";
import { ObjectiveSurveyResponseOptionsDisplay } from "@inthraction/labels";
import { saveAs } from "file-saver";
import { EventEmitter } from "@angular/core";

export abstract class ObjectiveDistributionCsvExportAbstractComponent {

  private today = new Date();
  private currentYear: number = this.today.getFullYear();
  private currentMonth: number = this.today.getMonth();
  private thisYear: Moment = moment((new Date("1/1/" + this.currentYear)).valueOf());
  private rollingYearStart: Moment = moment((new Date((this.currentMonth + 1) + "/1/" + (this.currentYear - 1))).valueOf());
  assignmentTotal: number;

  protected constructor(
    protected objectiveService: ObjectiveService,
    protected employeeService: EmployeeService,
    protected organizationService: OrganizationService
  ) {
  }

  download(detailsMap: Map<string, DistributionDetails>, fileName: string) {
    const csvData = [];
    const csvHeaders = [
      "Department",
      "Manager",
      "Name",
      "Domain",
      "Objective",
      "Objective Type",
      "Focus",
      "Priority",
      "Score",
      "Rank",
      "Start Date",
      "End Date"
    ];
    csvData.push(csvHeaders);
    for (const detailEntry of detailsMap.entries()) {
      if (detailEntry[1]?.tableData?.length) {
        for (const row of detailEntry[1].tableData) {
          const rowData = [
            row.department || "",
            row.manager || "",
            row.name || "",
            row.domain || "",
            row.objectiveTitle || "",
            row.objectiveType || "",
            row.focus || "",
            row.timeframe || "",
            `${row.score || ""}` || "",
            `${ObjectiveSurveyResponseOptionsDisplay[row.rank] || ""}` || "",
            row.startDate?.format("l") || "",
            row.endDate?.format("l") || ""
          ];
          csvData.push(rowData);
        }
      }
    }
    let csvArray = csvData.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, `${fileName}.csv`.replace(" ", "_"));
  }

  async gatherStats(employeeList: Employee[], orgObjective: OrganizationObjective, startDate?: Moment, endDate?: Moment, assignmentTotalEvent?: EventEmitter<number>): Promise<Map<string, DistributionDetails>> {
    this.assignmentTotal = undefined;
    let assignmentTotal = 0;
    const _detailsMap: Map<string, DistributionDetails> = new Map<string, DistributionDetails>();
    for (const distributionType of ["CAPACITY", "PROFICIENCY", "DELIVERY"]) {
      _detailsMap.set(distributionType, new DistributionDetails(distributionType, orgObjective.id));
    }
    const assignmentSet = new Set<string>();
    if (employeeList) {
      for (const employee of employeeList) {
        let assigned = false;
        const assignments: ObjectiveAssignment[] = (await this.objectiveService.getEmployeeObjectiveAssignmentsMemoize(employee.id, true))?.filter(a => a?.orgObjective?.id === orgObjective.id);

        if (endDate && assignments) {
          assignments.filter(a => !a.startDate || (moment(a.startDate).isSameOrBefore(endDate)));
        }
        if (startDate && assignments) {
          assignments.filter(a => !a.endDate || (moment(a.endDate).isSameOrAfter(startDate)));
        }

        if (assignments && assignments.length) {
          for (const assignment of assignments) {
            if (assignmentSet.has(assignment.id)) {
              throw new Error("Duplicate assignment found");
            }
            assignmentSet.add(assignment.id);

            //TODO Do we need to check if it's active?
            const detail = _detailsMap.get(assignment.focus.key);
            const score = await this.getScore(orgObjective.organizationID, employee.id, assignment.id, startDate?.toISOString(), endDate?.toISOString());
            let rank;
            if (score) {
              if (!assigned) {
                assigned = true;
              }
              rank = this.getRank(score);
            }
            detail.addAssignment(employee, assignment, score, rank, employee.managerID ? await this.employeeService.getEmployeeByIDMemoize(employee.managerID) : null);
            _detailsMap.set(assignment.focus.key, detail);
          }
          if (assigned) {
            assignmentTotal++;
          }
        }
      }
    }
    this.assignmentTotal = assignmentTotal;
    if (assignmentTotalEvent) {
      assignmentTotalEvent.next(this.assignmentTotal);
    }
    return _detailsMap;
  }

  private getRank(score: number): number {
    let rank: number = OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT;
    if (score >= 2 && score < 4.5) {
      rank = OBJECTIVE_SURVEY_RESPONSE_VALUES.LOW_MEETS;
    } else if (score >= 4.5 && score < 6.0) {
      rank = OBJECTIVE_SURVEY_RESPONSE_VALUES.MEETS;
    } else if (score >= 6.0 && score < 8.5) {
      rank = OBJECTIVE_SURVEY_RESPONSE_VALUES.HIGH_MEETS;
    } else if (score >= 8.5) {
      rank = OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS;
    }
    return rank;
  }

  private async getScore(organizationID: string, employeeID: string, objectiveAssignmentID: string, startDate?: string, endDate?: string): Promise<number | null> {
    let scoreStart = (await this.getScoreStartMoment(organizationID)).toISOString().replace(".000Z", "Z");
    if (startDate && scoreStart > startDate) {
      scoreStart = startDate;
    }
    const scores = await this.employeeService.getEmployeeObjectiveScoresMemoize(employeeID, scoreStart, objectiveAssignmentID, endDate);
    if (scores.length > 0) {
      scores.sort((a, b) => (a.scoreStart > b.scoreStart) ? 1 : ((b.scoreStart > a.scoreStart) ? -1 : 0));
      let count = 0;
      let sum = 0;
      for (const employeeScore of scores) {
        count++;
        if (employeeScore.employeeObjectiveScoreDetails) {
          sum += employeeScore.employeeObjectiveScoreDetails.score;
        } else {
          sum += employeeScore.score;
        }
      }
      if (count) {
        return SurveyService.round(sum / count, 2);
      } else {
        return null;
      }
    }
    return null;
  }

  protected async getScoreStartMoment(orgID: string): Promise<Moment> {
    let startingPoint: Moment;
    const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION, orgID);
    if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
      startingPoint = this.rollingYearStart;
    } else {
      startingPoint = this.thisYear;
    }
    return startingPoint;
  }

}
