import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { EmployeeService, ObjectiveService, OrganizationService } from "@inthraction/services";
import {
  CadenceObjectiveAssignment,
  Employee,
  ObjectiveAssignment,
  OrganizationConfiguration,
  TotalEmployeeObjectiveScore
} from "@inthraction/data-models";
import { EChartsOption } from "echarts";
import { ObjectiveSurveyResponseOptionsDisplay } from "@inthraction/labels";
import { OrganizationConfigurationCodes, round } from "@inthraction/codes";
import { ObjectiveResponseDistributionMode } from "../objective-response-distribution.component";
import {
  ObjectiveResponseDistributionDialogComponent
} from "../objective-response-distribution-dialog/objective-response-distribution-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "inthraction-objective-response-distribution-details",
  templateUrl: "./objective-response-distribution-details.component.html",
  styleUrls: ["./objective-response-distribution-details.component.scss"]
})
export class ObjectiveResponseDistributionDetailsComponent implements OnInit, OnChanges {

  private currentEmployee: Employee;

  @Input() mode: ObjectiveResponseDistributionMode;
  @Input() distributionBy: ObjectiveResponseDistributionMode;
  @Input() employee?: Employee;
  @Input() filterFutureObjectives?: boolean = true;

  distributions: DomainScore[];
  private calculationTypeConfig: OrganizationConfiguration;

  constructor(
    private objectiveService: ObjectiveService,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService,
    public dialog: MatDialog
  ) {
  }

  get totalResponses(): number {
    if (this.distributions) {
      return this.distributions.reduce((total, distribution) => total + distribution.responseCount, 0);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.distributionBy && this.mode && this.currentEmployee) {
      this.distributions = await this.loadDetails(this.distributionBy, this.mode, this.employee);
    }
  }

  async ngOnInit() {
    this.currentEmployee = await this.employeeService.getCurrentEmployee();
    this.calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION, this.currentEmployee.orgId);
    this.distributions = await this.loadDetails(this.distributionBy, this.mode, this.employee);
  }

  private async loadDetails(distributionBy: ObjectiveResponseDistributionMode, mode: ObjectiveResponseDistributionMode, employee?: Employee): Promise<DomainScore[]> {

    let totalScores: TotalEmployeeObjectiveScore[];
    if (this.calculationTypeConfig && "ROLLING" === this.calculationTypeConfig.configStringValue) {
      if (employee) {
        totalScores = await this.employeeService.getMTDTotalEmployeeObjectiveScoresByEmployee(employee?.id);
      } else {
        totalScores = await this.employeeService.getMTDTotalEmployeeObjectiveScoresByOrganization(this.currentEmployee.orgId);
      }
    } else {
      if (employee) {
        totalScores = await this.employeeService.getYTDTotalEmployeeObjectiveScoresByEmployee(employee?.id);
      } else {
        totalScores = await this.employeeService.getYTDTotalEmployeeObjectiveScoresByOrganization(this.currentEmployee.orgId);
      }
    }

    let distributions: DomainScore[] = [];
    for (const totalScore of totalScores) {
      const assignmentEmployee = await this.employeeService.getEmployeeByIDMemoize(totalScore.employeeID);
      if (mode == ObjectiveResponseDistributionMode.MANAGER && assignmentEmployee.managerID != this.currentEmployee.id) {
        continue;
      }

      for (const detail of totalScore.employeeTotalObjectiveScoreDetails.responses) {
        const assignment = await this.objectiveService.getObjectiveAssignmentMemoize(detail.objectiveAssignmentID);

        if (!assignment) {  //TODO Need to be logically deleting Objective Assignments
          console.warn("Assignment not found for id: " + detail.objectiveAssignmentID);
          continue;
        }


        //TODO: External Respondents
        if (assignment.objectiveType == "DEFINED_EXTERNAL") {
          continue;
        }


        const domain = assignment.orgObjective?.objective.domain;
        let scoreEmployee: Employee;
        if (employee) {
          scoreEmployee = await this.employeeService.getEmployeeByIDMemoize(detail.respondentID);
        } else {
          scoreEmployee = assignmentEmployee;
        }

        if (distributions.find(d => d.domainID === domain?.id)) {
          const distribution = distributions.find(d => d.domainID === domain.id);
          if (distribution.objectiveScores.find(o => o.objectiveID === assignment.orgObjective.objective.id)) {
            const objectiveScore = distribution.objectiveScores.find(o => o.objectiveID === assignment.orgObjective.objective.id);
            if (objectiveScore.groupScores.find(g => g.groupID === (distributionBy === ObjectiveResponseDistributionMode.DEPARTMENT ? scoreEmployee.department : scoreEmployee.id))) {
              const group = objectiveScore.groupScores.find(g => g.groupID === (distributionBy === ObjectiveResponseDistributionMode.DEPARTMENT ? scoreEmployee.department : scoreEmployee.id));
              group.responseStratification.series[0].data = this.calculateScores(detail.score, group.responseStratification.series[0].data);
              group.responseScores.push(detail.score);
            } else { // new group
              objectiveScore.groupScores.push(this.createNewGroupScore(distributionBy, scoreEmployee, detail.score, this.calculateScores(detail.score)));
            }
          } else { // New Objective
            distribution.objectiveScores.push(this.createNewObjectiveScore(assignment, distributionBy, scoreEmployee, detail.score));
          }
        } else { // new domain
          if (domain) { // TODO What to do with Quantifiable Objectives?
            distributions.push(new DomainScore(domain.id, domain.display, [
              this.createNewObjectiveScore(assignment, distributionBy, scoreEmployee, detail.score)
            ]));
          }
        }
      }
    }

    return distributions || [];
  }

  private createNewGroupScore(distributionBy: ObjectiveResponseDistributionMode, scoreEmployee: Employee, groupScore: number, data: number[]): GroupScore {
    return new GroupScore(
      distributionBy === ObjectiveResponseDistributionMode.DEPARTMENT ? scoreEmployee.department : scoreEmployee.id,
      distributionBy === ObjectiveResponseDistributionMode.DEPARTMENT ? scoreEmployee.department : `${scoreEmployee.firstName} ${scoreEmployee.lastName}`,
      [groupScore],
      {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: ObjectiveSurveyResponseOptionsDisplay.filter(d => d !== "0"),
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "Responses",
            type: "bar",
            barWidth: "60%",
            data: data
          }
        ]
      },
      ObjectiveResponseDistributionMode.PEER ? scoreEmployee.department : null
    );
  }

  private createNewObjectiveScore(assignment: ObjectiveAssignment | CadenceObjectiveAssignment, distributionBy: ObjectiveResponseDistributionMode, scoreEmployee: Employee, score: number) {
    return new ObjectiveScore(assignment.orgObjective.objective.id, assignment.orgObjective.objective.display, [
      this.createNewGroupScore(distributionBy, scoreEmployee, score, this.calculateScores(score))
    ]);
  }

  private calculateScores(score: number, data: number[] = [0, 0, 0, 0, 0]): number[] {
    switch (EmployeeService.getScoreColor(score, 5)) {
      case "red" : {
        data[0] += 1;
        break;
      }
      case "orange" : {
        data[1] += 1;
        break;
      }
      case "yellow" : {
        data[2] += 1;
        break;
      }
      case "green" : {
        data[3] += 1;
        break;
      }
      case "blue" : {
        data[4] += 1;
        break;
      }
    }
    return data;
  }

  async cardClickEvent(group: GroupScore) {
    if (this.distributionBy === ObjectiveResponseDistributionMode.PEER && !this.employee) {
      const employee = await this.employeeService.getEmployeeByIDMemoize(group.groupID);
      this.dialog.open(ObjectiveResponseDistributionDialogComponent, {
        width: "100%",
        height: "90%",
        data: { employee, mode: this.mode }
      });
    }
  }
}

export class DomainScore {
  domainID: string;
  domainName: string;
  objectiveScores: ObjectiveScore[];

  get responseScores(): number[] {
    if (this.objectiveScores && this.objectiveScores.length > 0) {
      return this.objectiveScores.map(g => g.responseScores).reduce((acc, cur) => acc.concat(cur), []);
    }
  }

  get score(): number {
    if (this.responseScores && this.responseScores.length > 0) {
      return round(this.responseScores.reduce((acc, cur) => acc + cur, 0) / this.responseScores.length, 2);
    }
  }

  get responseCount(): number {
    if (this.objectiveScores && this.objectiveScores.length > 0) {
      return this.objectiveScores.map(g => g.responseCount).reduce((acc, cur) => acc + cur, 0);
    }
  }

  constructor(domainID: string, domainName: string, objectiveScores: ObjectiveScore[]) {
    this.domainID = domainID;
    this.domainName = domainName;
    this.objectiveScores = objectiveScores;
  }

}

export class ObjectiveScore {
  objectiveID: string;
  objectiveName: string;
  groupScores: GroupScore[];

  get responseScores(): number[] {
    if (this.groupScores && this.groupScores.length > 0) {
      return this.groupScores.map(g => g.responseScores).reduce((acc, cur) => acc.concat(cur), []);
    }
  }

  get score(): number {
    if (this.responseScores && this.responseScores.length > 0) {
      return round(this.responseScores.reduce((acc, cur) => acc + cur, 0) / this.responseScores.length, 2);
    }
  }

  get responseCount(): number {
    if (this.groupScores && this.groupScores.length > 0) {
      return this.groupScores.map(g => g.responseCount).reduce((acc, cur) => acc + cur, 0);
    }
  }

  constructor(objectiveID: string, objectiveName: string, groupScores: GroupScore[]) {
    this.objectiveID = objectiveID;
    this.objectiveName = objectiveName;
    this.groupScores = groupScores;
  }

}

export class GroupScore {
  groupID: string;
  groupName: string;
  subGroupName: string;
  responseScores: number[];
  responseStratification: EChartsOption;

  get responseCount(): number {
    return this.responseScores?.length;
  }

  get groupScore(): number {
    if (this.responseScores && this.responseScores.length > 0) {
      return round(this.responseScores.reduce((acc, cur) => acc + cur, 0) / this.responseScores.length, 2);
    }
  }

  constructor(groupID: string, groupName: string, responseScores: number[], responseStratification: EChartsOption, subGroupName?: string) {
    this.groupID = groupID;
    this.groupName = groupName;
    this.responseScores = responseScores;
    this.responseStratification = responseStratification;
    this.subGroupName = subGroupName;
  }

}
