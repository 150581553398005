import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EditEmployeeComponent } from "../edit-employee/edit-employee.component";
import { AddEmployeeComponent } from "../add-employee/add-employee.component";
import { EmployeeImageInterface, IEmployeeImpl } from "@inthraction/data-models";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { OrganizationConfigurationCodes } from "@inthraction/codes";

@Component({
  selector: "inthraction-oc-employee",
  templateUrl: "./employee.component.html",
  styleUrls: ["./employee.component.scss"]
})
export class EmployeeComponent implements OnInit {

  isCollapsed = false;
  @Input() employee: IEmployeeImpl;
  @Input() hasManager = false;
  @Input() showActions = true;
  @Input() collapseSubordinatesFunction: any;

  @Output() addSubordinateEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() collapseEvent = new EventEmitter();

  scoreColor: string;
  employeeImage: EmployeeImageInterface;

  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService
  ) {
  }


  async ngOnInit(): Promise<void> {
    if (this.employee) {
      const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION, this.employee.orgId);
      if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
        this.scoreColor = EmployeeService.getScoreColor(await this.employeeService.getEmployeeMTDScoreMemoize(this.employee.id), 6);
      } else {
        this.scoreColor = EmployeeService.getScoreColor(await this.employeeService.getEmployeeYTDScoreMemoize(this.employee.id), 6);
      }
      const employeeImage = await this.employeeService.getEmployeeImageMemoize(this.employee.id, this.employee.orgId);
      if (employeeImage) {
        this.employeeImage = employeeImage;
      }
    }
  }

  openEdit(): void {
    const editDialog = this.dialog.open(EditEmployeeComponent, {
      width: "620px",
      data: { employee: this.employee }
    });

    editDialog.afterClosed().subscribe(result => {
      if (result) {
        this.employee = result.employee;
        if (result.photoUpdate) {
          this.employeeImage = result.employeeImage;
        }
        if (result.managerChanged) {
          this.addSubordinateEvent.emit({ employee: result.manager, addExisting: true });
        }
        this.editEvent.emit(this.employee);
      } else if (this.employee.disabled) {
        this.editEvent.emit(this.employee);
      }
    });

  }

  openAdd(): void {
    const addDialog = this.dialog.open(AddEmployeeComponent, {
      width: "680px",
      data: { manager: this.employee }
    });

    addDialog.afterClosed().subscribe(result => {
      if (result) {
        this.employee = result.employee;
        this.addSubordinateEvent.emit({ employee: this.employee, addExisting: result.addExisting });
      }
    });
  }

  collapseSubordinates(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapseEvent.emit(this.isCollapsed);
  }

}
