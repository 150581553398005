import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  CadenceObjectiveAssignment,
  Employee,
  EventSurvey,
  Note,
  ObjectiveAssignment,
  ObjectiveAssignmentProgressCheck,
  QuantifiableObjectiveAssignment,
  Survey
} from "@inthraction/data-models";
import { ProgressCheckDialogComponent } from "../progress-check-dialog/progress-check-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { EmployeeSettingsComponent } from "../../employee-settings/employee-settings.component";
import { Subscription } from "rxjs";
import {
  MANAGER_NOTES_HELP,
  OBJECTIVE_PROGRESS_HELP,
  ProgressStatusLabels,
  ReviewTypeLabels
} from "@inthraction/labels";
import { EmployeeService, NoteService, ObjectiveService, SurveyService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import { InthractionComponent } from "../inthraction/inthraction.component";
import { NoteTypes, ObjectiveTypeCodes, ReviewType, ReviewTypeKeys, ReviewTypes } from "@inthraction/codes";
import * as moment from "moment";
import { Moment } from "moment";
import { SurveyCommentsDialogComponent } from "../dialogs/survey-comments-dialog/survey-comments-dialog.component";
import { MatButton } from "@angular/material/button";


@Component({
  selector: "inthraction-one-on-one",
  templateUrl: "./one-on-one.component.html",
  styleUrls: ["./one-on-one.component.scss"]
})
export class OneOnOneComponent implements OnInit, OnDestroy {
  static NOTE_ID_PARAMETER = "noteId";
  static STANDUP_ID_PARAMETER = "standUpId";

  readonly MANAGER_NOTES_HELP = MANAGER_NOTES_HELP;
  readonly OBJECTIVE_PROGRESS_HELP = OBJECTIVE_PROGRESS_HELP;

  formEditMode: boolean;
  objectives: (ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment)[] = [];
  notes = "";
  progressCheckText = "";
  employee: Employee;
  oneOnOneNote: Note;
  surveyResponseOptionsDisplay = EventSurvey.SURVEY_RESPONSE_OPTIONS_DISPLAY;
  highResponseName: string;
  highResponse: Survey;
  lowResponseName: string;
  lowResponse: Survey;
  startDate: Moment;
  endDate: Moment;
  startingScore: number;
  endingScore: number;
  objectiveProgressCheckMap: Map<string, ObjectiveAssignmentProgressCheck> = new Map<string, ObjectiveAssignmentProgressCheck>();
  reviewType: ReviewType = ReviewTypeKeys.ONE;
  readonly reviewTypes = ReviewTypes;
  highResponses: Survey[];
  lowResponses: Survey[];
  viewOnly: boolean;
  employeeNameMap: Map<string, string> = new Map<string, string>();
  private routeParamMapSubscription: Subscription;
  private subscriptions: Subscription[] = [];
  private standUpId: string;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private noteService: NoteService,
    private toastr: ToastrService,
    private surveyService: SurveyService,
    private objectiveService: ObjectiveService
  ) {
  }

  get pageTitle(): string {
    if (this.mode === PageMode.MANAGER) {
      return ReviewTypeLabels[this.reviewType];
    }
    if (this.mode === PageMode.STANDUP) {
      return "Stand-Up Communication";
    } else {
      return "Communication";
    }
  }

  get mode(): PageMode {
    return PageMode.MANAGER;
  }

  async ngOnInit() {

    this.subscriptions.push(this.route.url.subscribe(
      async url => {
        for (const segment of url) {
          switch (segment.path) {
            case "view-communication" : {
              this.viewOnly = true;
            }
            // eslint-disable-next-line no-fallthrough
            case "communication" : {
              this.reviewType = ReviewTypeKeys.COMMUNICATION;
              break;
            }
            case "view-one-on-one" : {
              this.viewOnly = true;
            }
            // eslint-disable-next-line no-fallthrough
            case "one-on-one" : {
              this.reviewType = ReviewTypeKeys.ONE;
              break;
            }
            case "view-quarterly-review" : {
              this.viewOnly = true;
            }
            // eslint-disable-next-line no-fallthrough
            case "quarterly-review" : {
              this.reviewType = ReviewTypeKeys.QUARTER;
              break;
            }
            case "view-annual-review" : {
              this.viewOnly = true;
            }
            // eslint-disable-next-line no-fallthrough
            case "annual-review" : {
              this.reviewType = ReviewTypeKeys.ANNUAL;
              break;
            }
          }
        }
      }
    ));

    this.routeParamMapSubscription = this.route.paramMap.subscribe(async queryParams => {
      this.oneOnOneNote = null;
      this.formEditMode = false;

      if (queryParams.has(EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER)) {
        this.employee = await this.loadEmployee(queryParams.get(EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER));
      }

      if (queryParams.has(OneOnOneComponent.STANDUP_ID_PARAMETER)) {
        this.standUpId = queryParams.get(OneOnOneComponent.STANDUP_ID_PARAMETER);
      }

      if (queryParams.has(OneOnOneComponent.NOTE_ID_PARAMETER)) {
        this.oneOnOneNote = await this.noteService.getNoteByID(queryParams.get(OneOnOneComponent.NOTE_ID_PARAMETER));
        if (this.oneOnOneNote) {
          this.formEditMode = true;
          this.notes = this.oneOnOneNote.text;
          if (this.oneOnOneNote.options.version && this.oneOnOneNote.options.version === 1) {

            if (this.oneOnOneNote.options.objectiveProgressCheck && this.oneOnOneNote.options.objectiveProgressCheck.length > 0) {
              for (const check of this.oneOnOneNote.options.objectiveProgressCheck as ObjectiveAssignmentProgressCheck[]) {
                this.objectiveProgressCheckMap.set(check.objectiveAssignmentId, check);
              }
            }
            this.notes = this.oneOnOneNote.options.reviewNote;
            if (this.oneOnOneNote.options.startDate) {
              this.startDate = moment(this.oneOnOneNote.options.startDate);
            } else {
              this.startDate = moment(this.oneOnOneNote.createdAt).subtract(1, "month");
            }
            if (this.oneOnOneNote.options.endDate) {
              this.endDate = moment(this.oneOnOneNote.options.endDate);
            } else {
              this.endDate = moment(this.oneOnOneNote.createdAt);
            }
            if (this.oneOnOneNote.options.reviewType) {
              this.reviewType = this.oneOnOneNote.options.reviewType;
            }
          } else {
            this.startDate = moment(this.oneOnOneNote.createdAt).subtract(1, "month");
            this.endDate = moment(this.oneOnOneNote.createdAt);
          }

          this.employeeNameMap.set(this.employee.id, `${this.employee.firstName} ${this.employee.lastName}`);
          if (this.oneOnOneNote.comments) {
            for (const comment of this.oneOnOneNote.comments) {
              if (!this.employeeNameMap.has(comment.createdBy)) {
                const employee = await this.employeeService.getEmployeeByIDMemoize(comment.createdBy);
                this.employeeNameMap.set(comment.createdBy, `${employee.firstName} ${employee.lastName}`);
              }
            }
          }
        }
      } else {
        this.endDate = moment();

        switch (this.reviewType) {
          case ReviewTypeKeys.COMMUNICATION: {
            this.startDate = moment();
            break;
          }
          case ReviewTypeKeys.ONE: {
            if (this.mode === PageMode.MANAGER && this.employee.lastOneOnOne) {
              this.startDate = moment(this.employee.lastOneOnOne).add(1, "day");
            } else {
              this.startDate = moment().subtract(1, "month");
            }
            break;
          }
          case ReviewTypeKeys.QUARTER: {
            this.startDate = moment().subtract(1, "quarter");
            break;
          }
          case ReviewTypeKeys.ANNUAL: {
            this.startDate = moment().subtract(1, "year");
            break;
          }
        }

      }

      //TODO In review mode this may need to bring back expired objectives.
      const objectives = await this.objectiveService.getEmployeeObjectiveAssignmentsMemoize(this.employee.id);
      if (this.employee && objectives && objectives.length > 0) {
        this.objectives = objectives;
      }
      if (this.formEditMode) {
        this.buildProgressCheckText();
      }
      await this.findRecentIntHRactionComments(this.startDate?.toISOString(), this.endDate?.toISOString());

    });
  }

  async changeStartDate(startDate: Moment) {
    await this.findRecentIntHRactionComments(startDate?.toISOString(), this.endDate?.toISOString());
  }

  async changeEndDate(endDate: Moment) {
    await this.findRecentIntHRactionComments(this.startDate?.toISOString(), endDate?.toISOString());
  }

  openProgressCheckDialog() {

    const dialog = this.dialog.open(ProgressCheckDialogComponent, {
      width: "300px",
      data: {
        // objectives: this.objectives.filter(objective => !this.progressCheck.map(p => p.objectiveAssignmentId).includes(objective.id)),
        objectives: this.objectives,
        progressChecks: this.objectiveProgressCheckMap
      }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(
        result => {
          if (result && result.statusCode) {
            const opc: ObjectiveAssignmentProgressCheck = result;
            opc.updatedAt = moment().utc().toISOString();
            this.objectiveProgressCheckMap.set(opc.objectiveAssignmentId, opc);
            this.buildProgressCheckText();
          }
        }
      ));
  }

  buildProgressCheckText() {
    this.progressCheckText = "";
    for (const pc of this.objectiveProgressCheckMap.values()) {
      const objective = this.objectives.find(elm => elm.id === pc.objectiveAssignmentId);
      const statusText = ProgressStatusLabels[pc.statusCode];
      this.progressCheckText += "\n";
      if (objective.objectiveType === ObjectiveTypeCodes.QUANTIFIABLE) {
        this.progressCheckText += `\nOBJECTIVE: ${(objective as QuantifiableObjectiveAssignment).title}\tSTATUS: ${statusText}\n`;
      } else {
        this.progressCheckText += `\nOBJECTIVE: ${objective.orgObjective.objective.display}\tSTATUS: ${statusText}\n`;
      }
      if (pc.note) {
        this.progressCheckText += `\n${pc.note}`;
      }
    }
  }

  onCancelClick(): void {
    this.toastr.info("Canceled");
    switch (this.mode) {
      case PageMode.CONSULTANT:
        this.router.navigate([`/consultant/${this.employee.orgId}/workforce/${this.employee.id}/edit`]);
        break;
      case PageMode.HR:
        this.router.navigate([`/hr/workforce/${this.employee.id}/edit`]);
        break;
      case PageMode.MANAGER:
        this.router.navigate([`/manager/${this.employee.id}/workforce`]);
        break;
      case PageMode.STANDUP:
        if (this.route.routeConfig.path.includes("stand-up-history")) {
          this.router.navigate([`/manager/stand-up-history`]);
        } else {
          this.router.navigate([`/manager`]);
        }
        break;
    }
  }

  onCloseClick(): void {
    switch (this.mode) {
      case PageMode.CONSULTANT:
        this.router.navigate([`/consultant/${this.employee.orgId}/workforce/${this.employee.id}/edit`]);
        break;
      case PageMode.HR:
        this.router.navigate([`/hr/workforce/${this.employee.id}/edit`]);
        break;
      case PageMode.MANAGER:
        this.router.navigate([`/manager/${this.employee.id}/workforce`]);
        break;
      case PageMode.STANDUP:
        if (this.route.routeConfig.path.includes("stand-up-history")) {
          this.router.navigate([`/manager/stand-up-history`]);
        } else {
          this.router.navigate([`/manager`]);
        }
        break;
    }
  }

  async onSaveClick(value: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    try {
      this.buildProgressCheckText();
      if (this.formEditMode) {
        this.oneOnOneNote.text = value + this.progressCheckText;
        this.oneOnOneNote.options = {
          objectiveProgressCheck: Array.from(this.objectiveProgressCheckMap.values()),
          reviewNote: value,
          startDate: this.startDate.toISOString(),
          endDate: this.endDate.toISOString(),
          reviewType: this.reviewType as ReviewType,
          version: 1
        };
        await this.noteService.updateNote(this.oneOnOneNote);
      } else {
        const note = new Note();
        note.organizationID = this.employee.orgId;
        if (this.mode == PageMode.STANDUP) {
          note.objectID = this.standUpId;
        } else {
          note.objectID = this.employee.id;
        }
        note.text = value + this.progressCheckText;
        note.options = {
          objectiveProgressCheck: Array.from(this.objectiveProgressCheckMap.values()),
          reviewNote: value,
          startDate: this.startDate.toISOString(),
          endDate: this.endDate.toISOString(),
          reviewType: this.reviewType as ReviewType,
          version: 1
        };
        switch (this.mode) {
          case PageMode.CONSULTANT:
            note.noteType = NoteTypes.CONSULTANT_ONE_ON_ONE;
            break;
          case PageMode.HR:
            note.noteType = NoteTypes.HR_ONE_ON_ONE;
            break;
          case PageMode.MANAGER:
            note.noteType = NoteTypes.ONE_ON_ONE;
            break;
          case PageMode.STANDUP:
            note.noteType = NoteTypes.STANDUP;
            break;
        }
        const createdNote = await this.noteService.createNote(note);
        if (this.mode === PageMode.MANAGER && this.reviewType !== ReviewTypeKeys.COMMUNICATION) {
          await this.employeeService.updateLastOneOnOne(this.employee.id, moment(createdNote.updatedAt).format("YYYY-MM-DD"));
        }
        this.oneOnOneNote = createdNote;
        this.formEditMode = true;
      }

      this.noteService.clearOneOnOneNotesByObjectID(this.employee.id);
      this.toastr.success("Notes Saved");
      switch (this.mode) {
        case PageMode.CONSULTANT:
          this.router.navigate([`/consultant/${this.employee.orgId}/workforce/${this.employee.id}/edit`]);
          break;
        case PageMode.HR:
          this.router.navigate([`/hr/workforce/${this.employee.id}/edit`]);
          break;
        case PageMode.MANAGER:
          this.router.navigate([`/manager/${this.employee.id}/workforce`]);
          break;

        case PageMode.STANDUP:
          if (this.route.routeConfig.path.includes("stand-up-history")) {
            this.router.navigate([`/manager/stand-up-history`]);
          } else {
            this.router.navigate([`/manager`]);
          }
          break;
      }
    } catch (err) {
      submitBtn.disabled = false;
      this.toastr.error("Failed to save notes");
      throw err;
    }
  }

  ngOnDestroy(): void {
    if (this.routeParamMapSubscription) {
      this.routeParamMapSubscription.unsubscribe();
    }
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async findRecentIntHRactionComments(startDate: string, endDate: string) {
    const surveyList = await this.surveyService.getEventSurveysWithFeedback(this.employee.email, startDate, endDate);
    const orderedList = surveyList.sort((a, b) => a.surveyDate > b.surveyDate ? -1 : a.surveyDate < b.surveyDate ? 1 : 0);

    this.highResponses = [];
    this.lowResponses = [];
    for (const survey of orderedList) {
      if (survey.feedback && survey.feedback.length > 0 && survey.surveyResponse > 5) { // Contributed = 5 Lead = 6 Champ = 7
        this.highResponses.push(survey);
      }
      if (survey.feedback && survey.feedback.length > 0 && survey.surveyResponse < 3 && survey.surveyResponse > 0) { // Problem = 1, Disrupted = 2 Distracted = 3
        this.lowResponses.push(survey);
      }
    }

    this.highResponse = null;
    this.lowResponse = null;
    if (this.highResponses.length) {
      this.highResponse = this.highResponses[0];
      this.highResponseName = await this.getEmployeeName(this.highResponse.respondentEmail);
    }
    if (this.lowResponses.length) {
      this.lowResponse = this.lowResponses[0];
      this.lowResponseName = await this.getEmployeeName(this.lowResponse.respondentEmail);
    }

  }

  async openRecentIntHRaction(eventID: string, employeeID?: string): Promise<void> {
    this.dialog.open(InthractionComponent, {
      width: "600px",
      data: {
        event: eventID,
        employee: employeeID ? await this.employeeService.getEmployeeByIDMemoize(employeeID) : this.employee
      }
    });
  }

  async getEmployeeName(respondentEmail: string): Promise<string> {
    const employee = await this.employeeService.getEmployeeByEmailMemoize(respondentEmail);
    return `${employee.firstName} ${employee.lastName}`;
  }

  updateScores(startingScore?: number, endingScore?: number) {
    this.startingScore = startingScore;
    this.endingScore = endingScore;
  }

  showSurveyComments(surveys: Survey[]) {
    this.dialog.open(SurveyCommentsDialogComponent, {
      width: "600px",
      maxHeight: "800px",
      data: surveys
    });
  }

  get showDateRange(): boolean {
    return this.mode != PageMode.STANDUP;
  }

  get showProgressCheckButton(): boolean {
    return this.mode != PageMode.STANDUP;
  }

  private loadEmployee(employeeID: string): Promise<Employee> {
    return this.employeeService.getEmployeeByIDMemoize(employeeID);
  }
}

export enum PageMode {
  CONSULTANT,
  HR,
  MANAGER,
  STANDUP
}
